import { Outlet } from "react-router-dom";
import Invite from "./Invite";
import { PractitionerEdit } from "./Practitioners";

const PractitionersRoutes = {
    path: "practitioners",
    element: <Outlet />,
    children: [
        {
            path: "invite",
            element: <Invite />
        },
        {
            path: ":id/edit",
            element: <PractitionerEdit />
        }
    ]
};

export default PractitionersRoutes;