import { useContext, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { CaseContext } from '../cases/Cases.js';
import * as Messages from '../../shared/messages/Messages.js';
import * as Data from './Data.js';
import { GetCases } from '../cases/Data.js';
import { BuildConnection } from '../../shared/messages/Realtime.js';
import { AuthContext } from '../auth/Auth.js';
import SecureImage from '../../shared/auth/SecureImage.js';
import './messages.scss';
import SearchBar from '../../shared/SearchBar.js';
import BackButton from '../../shared/navigation.js';
import { Loader } from '../../shared/layout/Layout.js';
import { NotificationContext } from '../notifications/Notifications.js';
import { ItemArea } from '../../shared/Events.js';
import * as Icon from '../../shared/Icons.js';
import Scrollbars from 'react-custom-scrollbars-2';
import { nameFormatter } from '../child/Common.js';

function CreateConversation() {
    let caseContext = useContext(CaseContext)
    let hasCase = caseContext.case != null;

    const navigate = useNavigate();
    const auth = useContext(AuthContext);

    const [connection] = useState(BuildConnection(auth));

    const [children, setChildren] = useState(undefined);
    const [childIdSelect, setChildIdSelect] = useState("");
    const [childNameSelect, setChildNameSelect] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);

    useEffect(() => {
        if (connection.state !== 'Disconnected') return;
        connection.start();
    }, [connection])

    useEffect(() => {
        GetCases().then((
            data => {
                var object = {};
                data.forEach(element => {
                    object[element.childId] = element;
                });
                setChildren(object);
            }))
    }, []);

    const childId = hasCase ? caseContext.case.childId : childIdSelect;
    const childName = hasCase ? " with " + caseContext.case.childName.first : childNameSelect;

    const changeSubject = (e) => {
        setSubject(e.target.value);
    }

    const changeMessage = (e) => {
        setMessage(e.target.value);
    }

    const onSubmit = (values) => {
        if(error) return;
        const complete = (r) => {
            navigate("../" + r.topic.id);
        }

        if(childId === "") {setError(true); return;};

        if (connection.state !== 'Disconnected') {
            connection.invoke("SendConversation", { targetId: childId, subject: values.subject, message: values.message})
                .then(complete);
        } else {
            Data.CreateConversation(childId, {subject: values.subject, message: values.message}).then(complete);
        }
    }

    const selectChild = (e) => {
        let childId = e.target.value;
        setChildNameSelect("with " + children[childId].childName.first);
        setChildIdSelect(children[childId].childId);
        setError(false);
    }

    const SelectChildren = (c) => {
        return (
            <option key={c.childId} value={c.childId}>{c.childName.first} {c.childName.last}</option>
        )
    }

    const selectList = [];
    for (const child in children) {
        selectList.push(SelectChildren(children[child]));
    }

    const options = (
        <select onChange={selectChild} defaultValue="children">
            <option value="children" disabled> Select a Child </option>
            {selectList}
        </select>
    )

    const selectError = () => { if(error) {return "error dropdown"} return "dropdown" };
    const errorMessage = error ?  "Select a child" : "";

    const className = hasCase ? "content-case" : "content";

    if (!hasCase) {
        return (
            <div className={"messages " + className}>
                <BackButton to="/messages" />
                <h1>New conversation {childName}</h1>
                <div className="messages messages-create">
                    <label className={selectError()}>
                        Child
                        <br />
                        {children === undefined ? <Loader /> : options}
                        <br/>
                        <span className='error-message'>{errorMessage}</span>
                    </label>
                </div>
                <Messages.AddConversation onSubmit={onSubmit} changeSubject={changeSubject} subject={subject} changeMessage={changeMessage} message={message} />
            </div>
        )
    }

    else {
        return (
            <div className={"messages " + className}>
                <BackButton to="/messages" />
                <h1>New conversation {childName}</h1>
                <Messages.AddConversation onSubmit={onSubmit} changeSubject={changeSubject} subject={subject} changeMessage={changeMessage} message={message} />
            </div>
        )
    }
}

function ListCaseConversations(props) {
    const scrollRef = useRef(null);

    const auth = useContext(AuthContext);
    const [connection] = useState(BuildConnection(auth));
    const [conversations, setConversations] = useState([]);
    const [filteredMessages, setFilteredMessages] = useState([]);
    const [search, setSearch] = useState("");
    const caseItem = props.caseItem;

    const name = nameFormatter(caseItem.childName.first);

    const [loading, setLoading] = useState(true);
    const count = 5;

    useEffect(() => {
        setLoading(true);
        Data.GetConversationsForChild(caseItem.childId, 10, 0).then(c => {
            setConversations(c);
            setLoading(false);

            if (connection.state !== 'Disconnected') return;
            connection.start();

            connection.on("ReceiveConversation", (conversation) => {
                if (conversation.topic.childId === caseItem.childId) {
                    setConversations((cs) => {
                        conversation.unread = true;
                        return [conversation, ...cs];
                    });
                }
            });

            connection.on("ReceiveMessage", (message) => {
                setConversations((cs) => {
                    let convoIndex = cs.findIndex(c => c.topic.id === message.topicId);
                    let convo = cs[convoIndex];

                    if (convo === undefined)
                        return cs;

                    convo.messages = [message];
                    convo.unread = true;
                    convo.lastMessage = message.sent;

                    let update = [...cs];
                    update.splice(convoIndex, 1);
                    return [convo, ...update];
                });
            });
        });
    }, [caseItem.childId, connection])

    useEffect(() => {
        if (search !== "" && connection.state === 'Connected') {
            connection.invoke("SearchConversation", search, caseItem.childId).then(m => {
                setFilteredMessages(m);
            })
        }
    }, [caseItem, connection, search])

    const displayMsgList = conversations.map(m => ListItem(m, true));
    const displayMsg = displayMsgList.length === 0 ? Messages.NoMessage() : displayMsgList;

    const displayFilteredMsgList = filteredMessages.map(m => ListItem(m, true));
    const displayFilteredMsg = displayFilteredMsgList.length === 0 ? Messages.NoResults() : displayFilteredMsgList;

    const configureDisplay = search ? displayFilteredMsg : displayMsg;

    const scroll = (convos) => {
        if (convos.length === 0) return;
        setConversations((existingConversations) => {
            const temp = [...existingConversations];
            temp.push(...convos);
            return temp;
        })
    }

    const onScroll = () => {
        if (scrollRef.current !== null) {
            const scrollBottom = scrollRef.current.getScrollHeight() - scrollRef.current.getClientHeight() - scrollRef.current.getScrollTop();
            if (Math.abs(scrollBottom) <= 1) {
                if (connection.state !== 'Connected') Data.GetConversationsForChild(caseItem.childId, count, conversations.length).then(scroll);
                else connection.invoke("GetConversations", caseItem.childId, count, conversations.length).then(scroll);
            }
        }
    }

    if (loading) {
        return (
            <div className='content-case conversation-list-page-case'>
                <h1>{name} Messages</h1>
                <SearchBar setSearch={setSearch} />
                <Messages.ListConversation name={caseItem.childName.first}>
                    <ul className="conversation-list case">
                        <Loader />
                    </ul>
                </Messages.ListConversation>
            </div>
        )

    }
    return (
        <div className='content-case conversation-list-page-case'>
            <BackButton />
            <h1>{name} Messages</h1>
            <SearchBar setSearch={setSearch} />
            <Messages.ListConversation name={caseItem.childName.first}>
                <Scrollbars className="conversation-list case" ref={scrollRef} onScroll={onScroll}>
                    <ul>
                        {configureDisplay}
                    </ul>
                </Scrollbars>
            </Messages.ListConversation>
        </div>
    )
}

function ListAllConversations() {
    const scrollRef = useRef(null); 

    const [cases, setCases] = useState([]);
    const [conversations, setConversations] = useState([]);
    const [filteredTopics, setFilteredTopics] = useState([]);
    const [search, setSearch] = useState("");

    const auth = useContext(AuthContext);
    const [connection] = useState(BuildConnection(auth));

    const [loading, setLoading] = useState(true);
    const count = 5;

    useEffect(() => {
        setLoading(true);
        GetCases().then(c => setCases(c));
        Data.GetConversations(10, 0).then(c => {
            setConversations(c);
            setLoading(false);

            if (connection.state !== 'Disconnected') return;
            connection.start();

            connection.on("ReceiveConversation", (conversation) => {
                setConversations((cs) => {
                    conversation.unread = true;
                    return [conversation, ...cs];
                });
            });

            connection.on("ReceiveMessage", (message) => {
                setConversations((cs) => {
                    let convoIndex = cs.findIndex(c => c.topic.id === message.topicId);
                    let convo = cs[convoIndex];
                    
                    if (convo === undefined)
                        return cs;

                    convo.messages = [message];
                    convo.unread = true;
                    convo.lastMessage = message.sent;

                    let update = [...cs];
                    update.splice(convoIndex, 1);
                    return [convo, ...update];
                });
            });
        });
    }, [connection])

    useEffect(() => {
        if (search !== "" && connection.state === 'Connected') {
            connection.invoke("SearchConversation", search, undefined).then(t => {
                setFilteredTopics(t);
            })
        }
    })

    const mapTopics = (m) => {
        let caseItem = cases.find(c => c.childId === m.topic.childId)
        if (caseItem !== undefined)
            return ListItem(m, true, caseItem.childName)
        return ListItem(m);
    }

    const displayTopics = conversations.length === 0 ? Messages.NoMessage() : conversations.map((m) => {
        return mapTopics(m);
    });

    const displayFilteredTopics = filteredTopics.length === 0 ? Messages.NoResults() : filteredTopics.map((m) => {
        return mapTopics(m);
    });

    const configureDisplay = search ? displayFilteredTopics : displayTopics;

    const scroll = (convos) => {
        if (convos.length === 0) return;
        setConversations((existingConversations) => {
            const temp = [...existingConversations];
            temp.push(...convos);
            return temp;
        })
    }

    const onScroll = () => {
        if (scrollRef.current !== null) {
            const scrollBottom = scrollRef.current.getScrollHeight() - scrollRef.current.getClientHeight() - scrollRef.current.getScrollTop();
            if (Math.abs(scrollBottom) <= 1) {
                if (connection.state !== 'Connected') Data.GetConversations(count, conversations.length).then(scroll);
                else connection.invoke("GetConversations", null, count, conversations.length).then(scroll);
            }
        }
    }

    const display = loading
        ? <Loader />
        : <Scrollbars className='conversation-list' ref={scrollRef} onScroll={onScroll}>
            <ul>
                {configureDisplay}
            </ul>
        </Scrollbars>;

    return (
        <div className='messages content-minimal'>
            <BackButton />
            <h1>Messages</h1>
            <SearchBar setSearch={setSearch} />
            <Link to="create" className='button'>Message a child</Link> 
            <div className='messages'>
                {display}
            </div>
        </div>
    )
}

function ListConversationPage(props) {
    let caseContext = useContext(CaseContext)
    let hasCase = caseContext.case != null;

    if (hasCase) {
        // return ListCaseConversations(caseContext.case);
        return <ListCaseConversations caseItem={caseContext.case}/>;
    }

    else {
        return <ListAllConversations />;
    }
}

function ListItem(convo, assigned, childName) {
    const unread = convo.unread ? "unread" : "";
    const old = assigned ? "" : " old";
    const padlock = assigned ? <></> : <div className='padlock-container'><Icon.Padlock /></div>

    let nameSection = null;
    if (childName !== undefined) {
        nameSection = <><span className='childname'>{childName.first}</span><br /></>;
    }

    const profileUrl = "media/profile-picture/" + convo.topic.childId

    return (
        <li key={convo.topic.id} className={"conversation-list-item clickable " + unread + old}>
            <Link to={convo.topic.id}>
                <SecureImage className="portrait" url={profileUrl} alt="profile picture" />
                <span className="info">
                    {nameSection}
                    <div className='subject-body'>
                        <b>{convo.topic.subject} {padlock}</b>
                        <span className='preview'>
                            {convo.messages[0].content}
                        </span>
                    </div>
                    <div className='arrow-container'>
                        <Icon.FrontArrow alt="go to message" />
                    </div>
                    <span className='timestamp'>{Messages.BuildTimeStamp(convo.lastMessage)}</span>
                </span>
            </Link>
        </li>
    )
}

function ConversationPage() {
    const auth = useContext(AuthContext);
    const [connection] = useState(BuildConnection(auth));
    const notificationContext = useContext(NotificationContext); 

    let caseContext = useContext(CaseContext)
    let hasCase = caseContext.case != null;

    const params = useParams();
    const id = params.id;

    const userId = auth.user().sub;

    useEffect(() => {
        notificationContext.readNotification(ItemArea.Messages, id);
    }, [id, notificationContext]);

    const getConversation = (count, offset) => {
        return Data.GetConversation(id, count, offset);
    }

    const addMessage = (topicId, content) => {
        return Data.AddMessage(topicId, content);
    }

    return (
        <Messages.Conversation id={id} connection={connection} getConversation={getConversation} addMessage={addMessage} userId={userId} hasCase={hasCase} />
    )

}

export { ListConversationPage, ConversationPage, CreateConversation }