import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../auth/Auth';
import * as Icon from '../../../shared/Icons';
import { Settings } from '../settings/Settings';
import './navigation.scss';
import { GetPathFragment } from '../../../shared/layout/Layout';

function Cases(props) {
    return (
        <Link to="/cases" className="item" aria-label="Cases">
            <Icon.Briefcase alt="cases button" />
            <span className={props.navClass}>Cases</span>
        </Link>
    )
}

function Practitioners(props) {
    return (
        <Link to="/" className="item" aria-label="Practitioners">
                <Icon.Appointments />
            <span className={props.navClass}>Practitioners</span>
        </Link>
    )
}

function Superusers(props) {
    return (
        <Link to="superusers" className="item" aria-label="Superusers">
                <Icon.User />
            <span className={props.navClass}>Superusers</span>
        </Link>
    )
}

function NavMenu(props) {
    let pathFragment = GetPathFragment();
    let styles = { cases: "text", practitioners: "text", superusers: "text" };

    if (pathFragment === "") styles["practitioners"] += " focus";
    else styles[pathFragment] += " focus";

    const superUsers = props.user === "SuperAdmin" ? <Superusers navClass={styles.superusers}/> : <></>;

    return (
        <nav className="nav-menu">
            <Cases navClass={styles.cases} />
            <Practitioners navClass={styles.practitioners}/>
            {superUsers}
        </nav>
    )
}

function NavigationHeader(props) {
    let pathFragment = GetPathFragment();
    let styles = { settings: "text" };
    
    styles[pathFragment] += " focus";

    return (
        <div className="header">
            <Settings navClass={styles.settings}/>
        </div>
    )

}

const Navigation = function (props) {
    const auth = useContext(AuthContext);
    const user = auth.user();

    return (
        <>
            <NavigationHeader />
            <NavMenu user={user["user-type"]}/>
        </>
    )
}

export { Navigation, NavigationHeader };