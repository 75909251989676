import { Outlet } from "react-router-dom";
import { Notifications } from "./Notifications";

const NotificationsRoutes = {
    path: "notifications",
    element: <Outlet />,
    children: [
        {
            path: "",
            element: <Notifications />
        }
    ]
};

export default NotificationsRoutes;
