import { createContext, useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Data from './Data';
import './cases.scss';
import { LoaderButton, LoaderPage, NickNameGenerator, showError } from '../../shared/layout/Layout';
import BackButton from '../../shared/navigation';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../auth/Auth';
import { BuildConnection } from '../../shared/notifications/Realtime';
import UsersDisplay from '../../shared/UsersDisplay';
import { ModalContext } from '../../shared/Modals';


const CaseContext = createContext({
    case: null,
    setCase: (caseItem) => {},
    clearCase: () => {}
});

const CaseNotifications = (props) => {
    const [notif, setNotif] = useState(undefined);
    let notificationCount = notif;
    let notifClass = "notification";

    useEffect(() => {
        setNotif(props.caseNotification);
    }, [props.caseNotification])

    if (notif === undefined) return;

    if (notif > 9) {
        notificationCount = "9⁺";
        notifClass = "notification notification-greater";
    }

    return (
        <span className={notifClass}>{notificationCount}</span>
    )
}

const MakeRowItem = function (caseItem, caseContext, navigate, setCase, caseNotification) {
    const setContext = (e) => {
        setCase(caseItem.caseId, caseContext, navigate);
    }

    return (
        <div key={caseItem.caseId} className="box-row">
            <div className="name">
                <span className='clickable' onClick={setContext}>{caseItem.childName.first} {NickNameGenerator(caseItem.childName.nickname)} {caseItem.childName.last}</span>
            </div>
            <div className="controls">
                <Link to={"/cases/" + caseItem.caseId + "/edit"}>
                    Edit
                </Link>
            </div>
            <CaseNotifications childId={caseItem.childId} caseNotification={caseNotification}/>
        </div>
    );
}

const ResendButton = function(props){
    let caseId = props.caseId;
    const [sending, setSending] = useState(false);
    const [show, setShow] = useState(false);

    let resend = (e) => {
        if(sending) return;
        setSending(true);
        setShow(true);

        Data.ResendInvite(caseId).then((c) => {
            if (c === 500) {
                props.setErrorMessage("Could not resend invite, please try again.")
                setSending(false);
                setShow(false);
            }
            else {
                setSending(false);
                setShow(false);
                props.setErrorMessage("");
                props.modalContext.setShowTextNavigate("Invite sent");
            }
        })
    }

    if(props.disabled){
        return;
    }

    return (
        <div className='button-container'>
            <LoaderButton show={show} submit={resend} buttonStyle="button-tertiary button-max">Resend invite</LoaderButton>
        </div>
    );
}

const CaseEdit = function (props) {
    var caseId = useParams().id;
    const [details, setDetails] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState("");
    const [show, setShow] = useState(false);

    const modalContext = useContext(ModalContext);

    useEffect(() => {
        Data.GetCase(caseId).then(data => {
            setDetails(data);
        });
    }, [caseId]);

    if (details === undefined) {
        return (<LoaderPage/>)
    }

    return (
        <div className='case-edit content'>
            <BackButton to="/cases" />
            <h1>Edit {details.childName.first} {details.childName.last}</h1>
            <p>
                <label>
                    <input readOnly checked={details.accepted} type='checkbox' />
                    Accepted invite
                </label>
                <label>
                    <input readOnly checked={details.terms} type='checkbox' />
                    Accepted terms & conditions
                </label>
            </p>
            {/* <p>
                <label>
                    <input readOnly checked={details.complete} type='checkbox' />
                    Complete
                </label>
            </p> */}
            <Formik
            initialValues={{
                idNumber: details.idNumber ?? "",
                username: details.username  ?? "",
                firstName: details.childName.first  ?? "",
                lastName: details.childName.last  ?? "",
                nickname: details.childName.nickname  ?? "",
                contact: details.contactMethod.contact + ""  ?? "",
                mobile: details.contactMethod.mobile  ?? "",
                email: details.contactMethod.email ?? ""
            }}
                validationSchema={Yup.object({
                    idNumber: Yup.string().required('Enter their id number'),
                    firstName: Yup.string().required('Enter their first name'),
                    lastName: Yup.string().required('Enter their last name'),
                    contact: Yup.string().required('Choose a contact method'),
                    mobile: Yup.string().when("contact", {
                        is: '0',
                        then: Yup.string().required('Enter their mobile number')
                    }),
                    email: Yup.string().email('Enter a valid email').when("contact", {
                        is: '1',
                        then: Yup.string().required('Enter their email')
                    }),
                })}
                onSubmit={(values) => {
                    values.contact = Number(values.contact);
                    setShow(true);
                    Data.EditCase(caseId, values).then(c => {
                        if (c === 400) {
                            setErrorMessage("Enter a valid mobile number or email address.");
                            setShow(false);
                        }
                        else {
                            setErrorMessage("");
                            setShow(false);
                            modalContext.setShowTextNavigate("Case updated", "/cases");
                        }
                    })
                }}
            >
                {(data) => {
                    let error = showError.bind(this, data);
                    return(
                        <Form>
                            <label className={error("idNumber")}>
                                ID number
                                <Field name="idNumber" />
                                <br />
                                <span className='error-message'><ErrorMessage name="idNumber" /></span>
                            </label>
                            <label className={error("username")}>
                                Username
                                <Field name="username" readOnly />
                                <br />
                                <span className='error-message'><ErrorMessage name="username" /></span>
                            </label>
                            <label className={error("firstName")}>
                                First name
                                <Field name="firstName" />
                                <br />
                                <span className='error-message'><ErrorMessage name="firstName" /></span>
                            </label>
                            <label className={error("lastName")}>
                                Last name
                                <Field name="lastName" />
                                <br />
                                <span className='error-message'><ErrorMessage name="lastName" /></span>
                            </label>
                            <label className={error("nickname")}>
                                Nickname
                                <Field name="nickname" />
                                <br />
                                <span className='error-message'><ErrorMessage name="nickname" /></span>
                            </label>
                            <label className={error("contact")}>
                                Contact method
                            </label>
                            <label className={error("contact") + " no-padding"}>
                                <Field name="contact" type="radio" value="0" checked={data.values.contact.toString() === "0"}/>
                                Mobile phone
                            </label>
                                <br />
                            <label className={error("contact") + " no-padding"}>
                                <Field name="contact" type="radio" value="1" checked={data.values.contact.toString() === "1"}/>
                                Email
                                <br />
                                <span className='error-message'><ErrorMessage name="contact" /></span>
                            </label>
                            <br />
                            <label className={error("mobile")}>
                                Mobile number
                                <Field name="mobile" />
                                <br />
                                <span className='error-message'><ErrorMessage name="mobile" /></span>
                            </label>

                            <label className={error("email")}>
                                Email
                                <Field name="email" />
                                <br />
                                <span className='error-message'><ErrorMessage name="email" /></span>
                            </label>
                            <br />
                            <span className='error-message'>{errorMessage}</span>
                            <div className='button-container'>
                                <LoaderButton show={show} type="submit" buttonStyle="button-max">Update</LoaderButton>
                            </div>
                        </Form>
                    )}
                }
            </Formik>
            <ResendButton caseId={caseId} disabled={(details.accepted && details.terms)} modalContext={modalContext} setErrorMessage={setErrorMessage}/>
        </div>
    );
}

const SetContext = (itemId, caseContext, navigate) => {
    Data.GetCase(itemId).then(data => {
        caseContext.setCase(data);
        if (navigate) navigate("/child");
    });
}


const Cases = function (props) {
    const [caseNotifications, setCaseNotifications] = useState({});

    const auth = useContext(AuthContext);
    const [connection] = useState(BuildConnection(auth));

    var caseContext = useContext(CaseContext);
    useEffect(()=>{ caseContext.clearCase(); }, [caseContext])

    const navigate = useNavigate();

    useEffect(() => {
        Data.GetCaseTotals().then(c => {
            setCaseNotifications(c);
        })
    }, [])

    useEffect(() => {
        if (connection.state !== 'Disconnected') return;
        connection.start();

        connection.on("SendNotification", (notification) => {
            setCaseNotifications(old => {
                let update = {...old};
                isNaN(update[notification.creatorId]) ? update[notification.creatorId] = 1 : update[notification.creatorId] += 1;
                return update;
            });
        });
    }, [connection]);

    const mapItems = (item) => MakeRowItem(item, caseContext, navigate, SetContext, caseNotifications[item.childId]);

    const NoCases = () => (
        <p>
            You are not using the Connect app to manage any children yet.
            <br /><br />
            Click 'Add a child' to invite a child to use the app.
        </p>
    )

    const backButton = props.route === "invite" ? <BackButton /> : <></>;

    return (
        <div className="cases content">
            {backButton}
            <h1>Your children</h1>
            <UsersDisplay searchData={Data.SearchCases} row={mapItems} max={Data.GetMaxCases} data={Data.GetCases} empty={NoCases} route={props.route} count={5} user="child"/>
        </div>
    )
}

export { Cases, CaseEdit, CaseContext, SetContext };