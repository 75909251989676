import { Outlet } from "react-router-dom";
import { AboutTheApp, Accessibility, PrivacyAndCookies, TermsOfUse } from "./Disclaimers";
import { AlertPage, EditProfile } from "./Settings";

const SettingsRoutes = {
    path: "settings",
    element: <Outlet />,
    children: [
        {
            path: "profile",
            element: <EditProfile />
        },
        {
            path: "alerts",
            element: <AlertPage />
        },
        {
            path: "about",
            element: <AboutTheApp />
        },
        {
            path: "termsofuse",
            element: <TermsOfUse />
        },
        {
            path: "privacy",
            element: <PrivacyAndCookies />
        },
        {
            path: "accessibility",
            element: <Accessibility />
        }
    ]
};

export default SettingsRoutes;