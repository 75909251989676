const dayjs = require('dayjs');
const customParseFormat = require('dayjs/plugin/customParseFormat');
const utc = require('dayjs/plugin/utc');
const relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(relativeTime);

const Format = (date) => {
    let newDate = dayjs(date + "Z");

    return {
        day: newDate.format('dddd D MMMM'),
        time: newDate.format('h:mm a'),
        year: newDate.format('YYYY'),
        date: newDate.format('DD/MM/YYYY'),
        dayYear: newDate.format('dddd D MMMM YYYY'),
        dayYearTime: newDate.format('dddd D MMMM YYYY, h:mm a'),
        input: newDate.format('YYYY-MM-DD'),
        inputTime: newDate.format('HH:mm')
    };
}

const TimeAgo = (date) => {
    let formatted = dayjs(date + "Z");

    return formatted.fromNow();
}

const CreateDate = (date, time) => {
    let newDate;
    if (time == null) {
        newDate = dayjs(date, 'YYYY-MM-DD');
    }
    else {
        newDate = dayjs(date + ' ' + time, 'YYYY-MM-DD hh:mm');
    }

    return newDate.utc().toISOString();
}

export { Format, CreateDate, TimeAgo };