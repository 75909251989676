import { useContext, useEffect, useState } from "react";
import * as ChildSettings from "../../../child/settings/Settings";
import * as PractitionerSettings from "../../../practitioner/settings/Settings";
import * as Icon from "../../Icons";
import { UpdateModal } from "../../Modals";
import { AnimationModal, Loader, LoaderButton, LoaderPage, Paging, showError } from "../Layout";
import "./style.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import { CaseContext } from "../../../practitioner/cases/Cases";
import { StuffDataExport } from "../../../practitioner/child/mystuff/MyStuff";
import jwt_decode from 'jwt-decode';
import { GetCases } from "../../../practitioner/cases/Data";

function Headers() {
    return (
        <div className="headers">
            <h1>Header 1</h1>
            <h2>Header 2</h2>
            <h3>Header 3</h3>
            <b>Bold text</b>
            <p>Body text</p>
            <span>span text</span>
        </div>
    )
}

function Buttons() {
    const [show, setShow] = useState();
    return (
        <div className="button-grid">
            <Modal show={show} handleClose={() => setShow(false)}>
                <p>Modal</p>
                <p>Data</p>
            </Modal>
            <button className="button">Button Primary</button>
            <span className="button">Button Primary</span>
            <br></br>
            <button className="button button-secondary">Button Secondary</button>
            <span className="button button-secondary">Button Secondary</span>
            <br></br>
            <button className="button button-tertiary">Button Tertiary</button>
            <span className="button button-tertiary">Button Tertiary</span>
            <br></br>
            <div className="square">
                <button className="button button-square-primary">Button 7</button>
                <button className="button button-square-secondary" onClick={() => setShow(true)}>Open</button>
            </div>
        </div>
    )
}

function Backgrounds() {
    return (
        <div className="backgrounds">
            <div className="background1">Bg 1</div>
            <div className="background2">Bg 2</div>
            <div className="background3">Bg 3</div>
        </div>
    )
}

function Inputs(props) {
    const [error, setError] = useState(false);
    const errorClass = error ? "error" : "";

    const err = (text) => error ? text : "";

    return (
        <div className="inputs">
            <label className={errorClass}>
                regular input
                <input />
                <span className='error-message'>{err("error message")}</span>
            </label>
            <br />
            <label className={errorClass}>
                date input
                <input type="date" />
                <span className='error-message'>{err("error message")}</span>
            </label>
            <br />
            <label className={errorClass}>
                time input
                <input type="time" />
                <span className='error-message'>{err("error message")}</span>
            </label>
            <br />
            <label className={errorClass}>
                checkbox input
                <input type="checkbox" />
                <br />
                <span className='error-message'>{err("error message")}</span>
            </label>
            <br />
            <label className={errorClass}>
                range input
                <input type="range" />
                <span className='error-message'>{err("error message")}</span>
            </label>
            <br />
            <label className={errorClass}>
                radio group a
                <input type="radio" name="radio-group" />
                <span className='error-message'>{err("error message")}</span>
            </label>
            <br />
            <label className={errorClass}>
                radio group b
                <input type="radio" name="radio-group" />
                <span className='error-message'>{err("error message")}</span>
            </label>
            <br />
            <label className={errorClass}>
                select
                <br />
                <select defaultValue="0">
                    <option value="0" disabled="disabled">disabled</option>
                    <option>first</option>
                    <option>second</option>
                    <option>third</option>
                </select>
                <br />
                <span className='error-message'>{err("error message")}</span>
            </label>
            <br />
            <button onClick={() => {setError(!error)}}>Toggle error</button>
        </div>
    )
}

function Modal(props) {
    const showHideClass = props.show ? "block" : "none";
    return (
        <div className="modal" style={{display: showHideClass}}>
            <section className='modal-main'>
                {props.children}
                <button
                    onClick={props.handleClose}
                >
                    Close
                </button>
            </section>
        </div>
    );
};

function LoadArea(props) {
    return (
        <div style={{height: "20em"}}>
            <Loader />
        </div>

    )
}

function LoadPage(props) {
    return (
        <LoaderPage />
    )
}

function Animations() {
    return (
        <div>
            <img src="/animations/goalCompleted.gif" alt="goal complete"/>
            <img src="/animations/taskCompleted.gif" alt="task complete"/>
            <img src="/animations/taskGoalAdded.gif" alt="goal/task added"/>
        </div>
    )
}

function LoaderButtons() {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);

    const onSubmit = () => {
        setShow(true);
    }

    const onSubmit2 = () => {
        setShow2(true);
        setTimeout(() => setShow2(false), 2000);
    }

    return (
        <div>
            <LoaderButton show={show} submit={onSubmit} buttonStyle="button-max">Submit</LoaderButton>
            <LoaderButton show={show2} submit={onSubmit2} buttonStyle="button-max button-tertiary">Submit</LoaderButton>
        </div>
    )
}

function DataExport() {
    const [show, setShow] = useState(false);
    const [childId, setChildId] = useState("");
    const [cases, setCases] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        GetCases().then(cases => {
            setCases(cases);
        })
    }, [])

    const child = {
        childId: childId,
        childName: {
            first: "chris",
            last: "junior"
        }
    }

    const stuffDataExport = () => {
        if (childId !== "") StuffDataExport(setShow, child);
        else setError(true);
    }

    const optionSelect = (e) => {
        setChildId(e.target.value);
        setError(false);
    }

    let options = cases.map(function (item) {
        return (
            <option key={item.childId} value={item.childId}>{item.childName.first} {item.childName.last}</option>
        )
    })

    return (
        <div className="data-export button-container">
            <label>
                Child for PDF:
                <select onChange={optionSelect} defaultValue="children">
                    <option value="children" disabled> Select a Child </option>
                    {options}
                </select>
            </label>
            {error ? <span className="error-message">Please select a child</span> : <></>}
            <LoaderButton show={show} submit={stuffDataExport} buttonStyle="button-max">Download PDF</LoaderButton>
        </div>
    )
}

function StylePage() {
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const logPage = (p) => {};  //noop
    var caseContext = useContext(CaseContext);

    useEffect(()=>{ caseContext.clearCase(); }, [caseContext])

    var userType = jwt_decode(sessionStorage.getItem("jwt"))["user-type"];
    var theme = userType === "Practitioner" ? <PractitionerSettings.Theme /> : <ChildSettings.Theme />;
    var dataExport = userType === "Practitioner" ? <DataExport/> : <></>;

    return (
        <div className="content page">
            <div className="theme">
                {theme}
            </div>
            <div className="headers">
                <Headers />
            </div>
            <div className="buttons">
                <Buttons />
            </div>
            <div>
                <Inputs />
            </div>
            <Paging setPage={logPage} />
            <Paging setPage={logPage} maxPage="2"/>
            <Paging setPage={logPage} maxPage="10"/>
            <Backgrounds />
            <LoadArea />
            <AnimationModal animation="taskGoalAdded" clear="time" open={open}/>
            <button onClick={() => setOpen(true)}>Show animation</button>
            <Animations />
            <Icon.BackArrow/>
            <Icon.FrontArrow />
            <UpdateModal show={show} setShow={setShow} text="User has been added"/>
            <button onClick={() => setShow(true)}>Show update modal</button>
            <LoaderButtons />
            <Formik 
            initialValues={{
                datetime: ""
            }}
            validationSchema={Yup.object({
                datetime: Yup.string().required('Date required')
            })}
            onSubmit={(values) => {
                console.log(values);
            }}
            >
            {(data) => {
                let error = showError.bind(this, data);
                return (
                    <Form className="appointments">
                        <label className={error("datetime")}>
                            DateTime
                            <Field name="datetime" type="datetime-local" />
                            <br />
                            <span className='error-message'><ErrorMessage name="date" /></span>
                        </label>
                        <button type="submit" className='button button-max'>Submit</button>
                    </Form>
                )}
            }
            </Formik>
            {dataExport}
        </div>
    )
}

export { StylePage, LoadPage };