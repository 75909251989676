import * as Auth from './Auth';
import * as Invite from './Invites';

const ANONROUTES = [
    {
        path: "/",
        element: <Auth.Login />,
        children: []
    },
    {
        path: "/invite/sms",
        element: <Invite.SetPasswordSms/>,
        children: []
    },
    {
        path: "/invite/email",
        element: <Invite.SetPasswordEmail/>,
        children: []
    },
    {
        path: "/forgot/username",
        element: <Auth.ForgotUsername/>,
        children: []
    },
    {
        path: "/forgot/password",
        element: <Auth.ForgotPassword/>,
        children: []
    },
    {
        path: "/forgot/password/sms",
        element: <Auth.ResetPasswordSms />,
        children: []
    },
    {
        path: "/forgot/password/email",
        element: <Auth.ResetPasswordEmail />,
        children: []
    },
    {
        path: "*",
        element: <Auth.Login />,
        children:[]
    }
];

export default ANONROUTES;
