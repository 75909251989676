import Homepage from "./homepage/Homepage";
import NotFound from "./homepage/NotFound";
import AppointmentsRoutes from './appointments/Routes';
import SettingsRoutes from "./settings/Routes";
import CasesRoutes from "./cases/Routes";
import ChildRoutes from "./child/Routes";
import MessagesRoutes from "./messages/Routes";
import NotificationsRoutes from "./notifications/Routes";
import { StylePage } from "../shared/layout/style/Style";
import { Logout } from "./auth/Auth";

const ROUTES = [
    {
        path: "/",
        element: <Homepage />,
        children: []
    },
    {
        path: "/logout",
        element: <Logout />,
        children: []
    },
    SettingsRoutes,
    MessagesRoutes,
    AppointmentsRoutes,
    CasesRoutes,
    ChildRoutes,
    NotificationsRoutes,
    {
        path: "*",
        element: <NotFound/>,
        children:[]
    },
    {
        path: "/style",
        element: <StylePage />,
        children:[]
    }
];

export default ROUTES;
