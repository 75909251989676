import { useEffect, useState } from "react";
import api from "../api";
import config from '../config';

const StageImage = function(props){
    const alt = props.alt;
    const className = props.className;
    const [src, setSrc] = useState(null);

    useEffect(() => {
        api.image(config.host + props.url).then(r => {
            setSrc(r);
        });
    }, [props.url])

    if(src !== null && src !== 'data:')
        return <img className={className} src={src} alt={alt} />

    return <img className={className} src="/defaultProfilePic.png" alt={alt} />
}

const SecureImage = function(props){
    if(navigator.serviceWorker.controller === null)
        return <StageImage {...props} />

    return <img className={props.className} src={config.host + props.url} alt={props.alt} />
}

export default SecureImage;