import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { StuffGroup } from "../../shared/mystuff/Stuff";
import { mapComponents, mapControlComponents } from "../../shared/mystuff/MyStuff";

import { GetMyStuff, GetStuffList } from '../child/mystuff/Data';
import { GetAppointment, GetAppointmentStuff, UpdateAppointmentStuff } from './Data';
import { GetName } from '../child/Data';
import { nameFormatter } from '../child/Child';
import { Format } from '../../shared/dates';

import BackButton from '../../shared/navigation';

function AddStuff(props) {
    const [group, setGroup] = useState(0);
    const [stuff, setStuff] = useState([]);
    const [appointment, setAppointment] = useState(undefined);
    const [childId, setChildId] = useState(undefined);
    const [childName, setChildName] = useState(undefined);

    const [selected, setSelected] = useState([]);
    const scrollRef = useRef();
    const pageSize = 6;

    const navigate = useNavigate();
    const params = useParams();
    const id = params.id;

    useEffect(()=>{
        GetAppointment(id).then(a => {
            setAppointment(a);
            setChildId(a.childId);
            GetName(a.childId).then(n => setChildName(n));
        });
        
        GetAppointmentStuff(id).then(s => {
            setSelected(s);
        })
    },[id]);

    useEffect(() => {
        if (childId !== undefined && childId !== "") {
            GetMyStuff(childId, group, pageSize, 0).then(s => {
                setStuff(s);
                if(scrollRef.current !== null){
                    scrollRef.current.scrollTo(0, 0);
                }
            });
        }
    }, [childId, group]);

    function changeNavBar(e) {
        setGroup(e.target.value);
    }

    const onScroll = () => {
        if(scrollRef.current === null) return;
        let current = scrollRef.current;

        let bottom = current.scrollHeight - current.clientHeight - current.scrollTop;
        if(Math.abs(bottom) <= 1){
            GetMyStuff(childId, group, stuff.length + pageSize, stuff.length).then(
                r => setStuff(s => s.concat(r))
            );
        }
    }

    function includeStuff () {
        UpdateAppointmentStuff(id, selected).then(() => {
            navigate("/appointments/" + id);
        });
    }

    const controlCheck = (id, e) => {
        if(e.target.checked){
            setSelected(s => {
                return s.concat([id]);
            });
        }else{
            setSelected(s => {
                return s.filter(x => x !== id);
            });
        }
    }

    const stuffComponents = stuff.map(s => mapControlComponents(selected, controlCheck, s));

    let title = <h2>Include stuff</h2>
    if(childName !== undefined){
        title = <h2>Include stuff for {nameFormatter(childName.first)} appointment on {Format(appointment.occurs).date}</h2>
    }

    return (
        <div className='content mystuff'>
            <BackButton to={"/appointments/" + id}/>
            {title}
            <div className='bar'>
                <select onChange={changeNavBar} defaultValue={group}>
                    <option value={StuffGroup.WhoIAm}>Who I am</option>
                    <option value={StuffGroup.WantToBe}>Who I want to be</option>
                    <option value={StuffGroup.GetThere}>How I'll get there</option>
                </select>
                <button onClick={includeStuff} className='button'>Include</button>
            </div>
            <div className='appointments-select grid' ref={scrollRef} onScroll={onScroll}>
                <div className="grid-container">
                    {stuffComponents}
                </div>
            </div>
        </div>
    );
}

function ViewStuff(props) {
    const [group, setGroup] = useState(0);
    const [selected, setSelected] = useState([]);
    const [stuff, setStuff] = useState([]);
    const [disp, setDisp] = useState([]);

    const childId = props.childId;

    useEffect(() => {
        if (childId !== undefined && childId !== "") {
            GetAppointmentStuff(props.id).then(as => {
                GetStuffList(childId, as).then(stuff => {
                    setSelected(as);
                    setStuff(stuff);
                })
            });
        }
    }, [props.id, childId]);
    useEffect(() => {
        let temp = stuff.filter(c => selected.includes(c.id) && c.group === group);
        setDisp(temp);
    },[stuff, selected, group]);

    function changeNavBar(e) {
        setGroup(Number(e.target.value));
    }
    let link = null;
    if(props.showLink){
        link = <Link className='button' to={"/appointments/" + props.id + "/mystuff"}>Go to Stuff</Link>
    }

    return (
        <div>
            <h2>
                Stuff to discuss at this appointment
            </h2>
            <div className='mystuff'>
                <div className='bar'>
                    <select onChange={changeNavBar} defaultValue={group}>
                        <option value={StuffGroup.WhoIAm}>Who I am</option>
                        <option value={StuffGroup.WantToBe}>Who I want to be</option>
                        <option value={StuffGroup.GetThere}>How I'll get there</option>
                    </select>
                    {link}
                </div>
                <div className='appointments-display grid'>
                    <div className="grid-container">
                        {disp.map(mapComponents)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export { AddStuff, ViewStuff };