import { ItemAnimation } from "../Events";
import SecureImage from "../auth/SecureImage";
import { TimeAgo } from "../dates";

function Activities(t) {
    const profileUrl =  "media/profile-picture/" + t.creatorId;

    return (
        <li key={t.id}>
            <div className="timeline-item">
                <SecureImage className='profile' url={profileUrl} />
                <p className="text">{t.content}</p>
            </div>
            {ItemAnimation(t)}
            <span className="timestamp text">{TimeAgo(t.created)}</span>
        </li>
    )
}

function NoActivities() {
    return (
        <li>
            No timeline events
        </li>
    )
}

export { Activities, NoActivities };