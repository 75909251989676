import { Outlet } from "react-router-dom";
import { Appointments } from "./Appointments";
import { AppointmentsAdd } from "./AppointmentsAdd";
import { AppointmentsView } from "./AppointmentsView";
import { AddStuff } from "./AppointmentsStuff";

const AppointmentsRoutes = {
    path: "appointments",
    element: <Outlet />,
    children: [
        {
            path: "",
            element: <Appointments />
        },
        {
            path: "create",
            element: <AppointmentsAdd />
        },
        {
            path: ":id",
            element: <AppointmentsView />
        },
        {
            path: ":id/mystuff",
            element: <AddStuff />
        }
    ]
};

export default AppointmentsRoutes;