import api from '../../shared/api.js'

const GetNotifications = function () {
    return api.get("notifications");
}

const GetChildNotifications = function (childId) {
    return api.get("child/" + childId + "/notifications");
}
const GetRecentChildNotifications = function (childId) {
    return api.get("child/" + childId + "/notifications/recent");
}

const GetTotals = function () {
    return api.get("notifications/totals");
}

const GetChildTotals = function (childId) {
    return api.get("child/" + childId + "/notifications/totals");
}

const ReadNotification = function (itemArea, itemId) {
    return api.post("notifications/read", {
        itemArea,
        itemId
    });
}

const ReadAllNotifications = () => {
    return api.postOnly("notifications/read/all");
}

export {
    GetNotifications,
    GetChildNotifications,
    GetRecentChildNotifications,
    GetTotals,
    GetChildTotals,
    ReadNotification,
    ReadAllNotifications
};
