import api from "../../../shared/api";

const GetPractitioners = (count, offset) => {
    return api.getPaged("superuser/practitioners", count, offset);
}

const GetPractitioner = (id) => {
    return api.get("superuser/practitioners/" + id);
}

const GetMaxPractitioners = () => {
    return api.get("superuser/practitioners/max");
}

const SearchPractitioners = (search) => {
    return api.get("superuser/practitioners/search?name=" + search)
}

const InvitePractitioner = (invite) => {
    return api.post("superuser/practitioners/invite", invite);
}

const ResendInvite = (id) => {
    return api.post("superuser/practitioners/" + id + "/resend");
}

const EditPractitioner = (id, update) => {
    return api.put("superuser/practitioners/" + id + "/edit", update);
}

const SearchPractitionerUsernames = (search) => {
    return api.get("superuser/practitioners/username/search?username=" + search);
}

export { GetPractitioners, GetPractitioner, GetMaxPractitioners, SearchPractitioners, InvitePractitioner, ResendInvite, EditPractitioner, SearchPractitionerUsernames };