import { useState, useEffect } from "react";
import api from "../api";
import config from "../config";

const StageImage = (props) => {
    const [src, setSrc] = useState(null);
    const [style, setStyle] = useState(null);

    useEffect(() => {
        api.image(config.host + props.url).then(r => {
            return setSrc(r);
        });
        if (src !== null) {
            setStyle({ backgroundImage: "url(" + src + ")" });
        }
    }, [props.url, src])

    return (
        <div className={props.className} style={style} onClick={props.onClick}>
            {props.children}
        </div>
    );
}

const SecureBackgroundImage = function(props){
    if(navigator.serviceWorker.controller === null)
        return <StageImage {...props} />

    const style = { backgroundImage: "url(" + config.host + props.url + ")" }
    return (
        <div className={props.className} style={style} onClick={props.onClick}>
            {props.children}
        </div>
    );
}

export default SecureBackgroundImage;