import { useContext, useEffect, useState } from "react";
import './superusers.scss';
import { AuthContext } from "../../auth/Auth";
import { NotFound } from "../homepage/Homepage";
import UsersDisplay from "../../../shared/UsersDisplay";
import { Link, useParams } from "react-router-dom";
import * as Data from "./Data";
import { LoaderButton, LoaderPage, showError } from "../../../shared/layout/Layout";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import BackButton from "../../../shared/navigation";
import * as Icon from "../../../shared/Icons";
import { ModalContext } from "../../../shared/Modals";

const MakeRowItem = (superUserItem) => {
    return (
        <div key={superUserItem.userId} className="box-row">
            <span className="user">{superUserItem.username}</span>
            <div onClick={null}>
                <span className="name">
                    {superUserItem.name.first} {superUserItem.name.last}
                </span>
            </div>
            <div className="controls">
                <Link to={"/superusers/" + superUserItem.userId + "/edit"}>
                    Edit
                </Link>
            </div>
        </div>
    );
}

function SuperusersEdit() {
    var superUserId = useParams().id;
    const [details, setDetails] = useState(undefined);
    const [displayedPassword, setDisplayedPassword] = useState("");
    const [show, setShow] = useState(false);

    const modalContext = useContext(ModalContext);

    useEffect(() => {
        Data.GetSuperUser(superUserId).then(data => {
            setDetails(data);
        });
    }, [superUserId]);

    if (details === undefined) return <LoaderPage />;

    const CopyToClipboard = () => {      
        navigator.clipboard.writeText(displayedPassword);
    }

    const setPassword = displayedPassword === ""
        ? <></>
        :
        <div className="password-updated-container">
            <span>The password has been set to </span>
            <div className="clickable displayed-password-container" onClick={CopyToClipboard}>
                <span id="test">{displayedPassword}</span>
                <Icon.CopyToClipboard/>
            </div>
        </div>

    return (
        <div className="superuser content">
            <BackButton />
            <h1>Edit {details.superUserName.first} {details.superUserName.last}</h1>
            <Formik
                initialValues={{
                    username: details.username ?? "",
                }}
                onSubmit={() => {
                    setShow(true);
                    Data.EditSuperUser(superUserId).then(password => {
                        setShow(false);
                        setDisplayedPassword(password);
                        modalContext.setShowTextNavigate("Password updated");
                    })
                }}
            >
                {(data) => {
                    let error = showError.bind(this, data);
                    return (
                        <Form>
                            <label className={error("username")}>
                                Username
                                <Field name="username" readOnly />
                                <br />
                                <span className='error-message'><ErrorMessage name="username" /></span>
                            </label>
                            <div className="button-container">
                                <LoaderButton show={show} type="submit" buttonStyle="button-max">Update password</LoaderButton>
                            </div>
                        </Form>
                    )
                }
                }
            </Formik>
            {setPassword}
        </div>
    )
}

function Superusers() {
    const auth = useContext(AuthContext);
    const user = auth.user();

    const NoSuperUsers = () => (
        <div>No other SuperUsers added.</div>
    )

    if (user["user-type"] === "SuperAdmin") {
        return (
            <div className="superuser content">
                <div className="user-name">
                    <h1>
                        <b>{user.name.first} {user.name.last}</b>
                    </h1>
                </div>
                <h2>SuperUsers</h2>
                <div className="superusers-list">
                    <UsersDisplay searchData={Data.SearchSuperUsers} row={MakeRowItem} max={Data.GetMaxSuperUsers} data={Data.GetSuperUsers} empty={NoSuperUsers} route="invite"/>
                </div>
            </div>
        )
    }

    else {
        return (
            <NotFound />
        )
    }
}

export { Superusers, SuperusersEdit };