import { useContext, useState } from "react";
import { AuthContext } from "../../auth/Auth";
import { NotFound } from "../homepage/Homepage";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import BackButton from "../../../shared/navigation";
import { LoaderButton, showError } from "../../../shared/layout/Layout";
import { InviteSuperUser } from "./Data";
import * as Icon from "../../../shared/Icons";
import { ModalContext } from "../../../shared/Modals";

function Invite() {
    const auth = useContext(AuthContext);
    const user = auth.user();
    const modalContext = useContext(ModalContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [displayedPassword, setDisplayedPassword] = useState("");
    const [show, setShow] = useState(false);

    const CopyToClipboard = () => {      
        navigator.clipboard.writeText(displayedPassword);
    }

    const setPassword = displayedPassword === ""
        ? <></>
        :
        <div className="password-updated-container">
            <span>The password has been set to </span>
            <div className="clickable displayed-password-container" onClick={CopyToClipboard}>
                <span id="test">{displayedPassword}</span>
                <Icon.CopyToClipboard/>
            </div>
        </div>

    if (user["user-type"] === "SuperAdmin") {
        return (
            <div className="content superuser">
                <BackButton to="/" />
                <h1>
                    Add a superuser
                </h1>
                <Formik
                initialValues={{
                    username: "",
                    firstName: "",
                    lastName: ""
                }}
                    validationSchema={Yup.object({
                        username: Yup.string().required('Enter their username'),
                        firstName: Yup.string().required('Enter their first name'),
                        lastName: Yup.string().required('Enter their last name')
                    })}
                    onSubmit={(values) => {
                        const invite = {
                            username: values.username,
                            firstName: values.firstName,
                            lastName: values.lastName
                        }
                        setShow(true);
                        InviteSuperUser(invite).then(s => {
                            switch (s) {
                                case 500:
                                    setShow(false);
                                    setErrorMessage("Please choose another username. Another user already has the username '" + values.username + "'.");
                                    break;
                                default:
                                    setShow(false);
                                    setDisplayedPassword(s.password);
                                    modalContext.setShowTextNavigate("Super-user added");
                                    break;
                            }
                        })
                    }}
                >
                    {(data) => {
                        let error = showError.bind(this, data);
                        return(
                            <Form>
                                <label className={error("username")}>
                                    Username
                                    <Field name="username" />
                                    <br />
                                    <span className='error-message'><ErrorMessage name="username" /></span>
                                </label>
                                <label className={error("firstName")}>
                                    First name
                                    <Field name="firstName" />
                                    <br />
                                    <span className='error-message'><ErrorMessage name="firstName" /></span>
                                </label>
                                <label className={error("lastName")}>
                                    Last name
                                    <Field name="lastName" />
                                    <br />
                                    <span className='error-message'><ErrorMessage name="lastName" /></span>
                                </label>
                                <br />
                                <span className="error-message">{errorMessage}</span>
                                <div className="button-container">
                                    <LoaderButton show={show} type="submit" buttonStyle="button-max">Submit</LoaderButton>
                                </div>
                            </Form>
                        )}
                    }
                </Formik>
                <br />
                {setPassword}
            </div>
        )
    }

    else {
        return (
            <NotFound />
        )
    }
   
}

export default Invite;