import SecureImage from "./auth/SecureImage";

function ProfileHeader(props) {
    let backgroundImage = null;

    if (props.backgroundImage) {
        let backgroundUrl = "/backgrounds/" + props.backgroundImage + ".jpg";
        backgroundImage = " url(" + backgroundUrl + ")";
    }

    const style = { backgroundImage: backgroundImage };
    
    return (
        <>
        <div className="homepage-header">
            <svg className="svg">
                <clipPath id="homepage-header-path" clipPathUnits="objectBoundingBox"><path d="M0,0.776 C0.3,1,0.7,0,1,0.776 L1,0 L0,0"></path></clipPath>
            </svg>
            <div className="clipped" style={style}></div>
        </div>
        <div className='user-portrait'>
            <SecureImage className="portrait" url={props.profileUrl} alt="profile picture" />
        </div>
        </>
    )
}

export default ProfileHeader;