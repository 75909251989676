import { Outlet } from "react-router-dom";
import Timeline from "./Timeline";

const TimelineRoutes = {
    path: "timeline",
    element: <Outlet />,
    children: [
        {
            path: "",
            element: <Timeline />
        }
    ]
};

export default TimelineRoutes;
