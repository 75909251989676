import { createContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import { Logon, ResendUsername, SendPasswordToken, SetNewPassword, VerifyPasswordToken } from "./Data";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import { LoaderButton, showError } from '../../shared/layout/Layout';
import BackButton from "../../shared/navigation";

const AuthContext = createContext({
    user: () => {
        // return auth user
    },
    jwt: () => {
        // return just the jwt
    }
});

const BuildContext = function (jwt) {
    const authProvider = {
        user: () => {
            var token = jwt_decode(jwt);

            token.name = JSON.parse(token.name);
            token.accepted = token.accepted === "True";

            return token;
        },
        jwt: () => {
            return jwt;
        }
    };

    return authProvider;
}

const ForgotPassword = function (props) {
    const [submit, setSubmit] = useState(false);

    const form = (<>
        <p>
            Enter the username used to create your account.
            <br />
            We'll email or text you instructions on how to create a new password.
        </p>
        <Formik
            initialValues={{
                username: ""
            }}
            validationSchema={Yup.object({
                username: Yup.string().required('Enter your username')
            })}
            onSubmit={(values) => {
                SendPasswordToken(values.username);
                setSubmit(true);
            }}
        >
            {(data) => {
                let error = showError.bind(this, data);
                return (
                    <Form>
                        <label className={error('username')}>
                            Enter your username
                            <Field name="username" />
                            <span className='error-message'><ErrorMessage name="username" /></span>
                        </label>
                        <button type="submit">Request new password</button>
                    </Form>
                )
            }}
        </Formik>
    </>)

    const message = (
        <p>
            Thank you for the password reset request. If we have your details, a link
            will be sent using your preferred contact method.
            <br />
            You may need to check your junk folder.
        </p>
    )

    const display = submit ? message : form;

    return (
        <div className="content">
            <BackButton to="/" />
            <h1>Forgot your password</h1>
            {display}
        </div>
    )
}

const ResetPasswordSms = (props) => {
    const [token, setToken] = useState("");
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
    const [updated, setUpdated] = useState(false);

    if (updated) return (
        <div className="content">
            <BackButton to="/" />
            <h1>Reset password</h1>
            <p>Password has been updated!</p>
            <Link to="/" className="button">Login page</Link>
        </div>
    )

    if (validated) return (
        <div className="content">
            <BackButton to="/" />
            <h1>Reset password</h1>
            <Formik
                initialValues={{
                    password: "",
                    confirmPassword: ""
                }}
                validationSchema={Yup.object({
                    password: Yup
                        .string()
                        .min(8, 'Your password needs to be at least 8 characters long')
                        .matches(new RegExp('(?=.*[a-z])(?=.*[A-Z])'), 'Your password needs to include both lower and upper case characters')
                        .required('You must enter a password'),
                    confirmPassword: Yup
                        .string()
                        .required('You must enter a password')
                        .oneOf([Yup.ref('password'), null], 'Passwords must match')
                })}
                onSubmit={(values) => {
                    SetNewPassword(token, values.password).then(v => {
                        if (v === true) setUpdated(true);
                        else setPasswordErrorMessage("Error setting password, please try resetting password again.");
                    })
                }}
            >
                {(data) => {
                    let error = showError.bind(this, data);
                    return (
                        <Form>
                            <p>
                                Your new password needs to be at least 8 characters long
                                and contain both upper and lower case characters.
                            </p>
                            <label className={error('password')}>
                                New password
                                <Field name="password" type="password" minLength="8"/>
                                <br />
                                <span className='error-message'><ErrorMessage name="password" /></span>
                            </label>
                            <label className={error('confirmPassword')}>
                                Confirm new password
                                <Field name="confirmPassword" type="password" minLength="8" />
                                <br />
                                <span className='error-message'><ErrorMessage name="confirmPassword" /></span>
                                <span className="error-message">{passwordErrorMessage}</span>
                            </label>
                            <button type="submit">Confirm</button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )

    return (
        <div className="content">
            <BackButton to="/" />
            <h1>Reset password</h1>
            <p>
                Please enter the token that was sent to you
            </p>
            <Formik
                initialValues={{
                    token: "",
                }}
                validationSchema={Yup.object({
                    token: Yup.string()
                    .length(6, 'Your token needs to be exactly 6 digits')
                    .required('Token is required')
                })}
                onSubmit={(values) => {
                    setToken(values.token);
                    VerifyPasswordToken(values.token).then(v => {
                        if (v === true) {
                            setValidated(v);
                        }
                        else { setErrorMessage("Invalid token"); }
                    })
                }}
            >
                {(data) => {
                    let error = showError.bind(this, data);
                    return (
                        <Form>
                            <label className={error('token')}>
                                Enter your 6-digit code
                                <Field name="token" maxLength="6" />
                                <span className='error-message'><ErrorMessage name="token" /></span>
                                <br />
                                <span className="error-message">{errorMessage}</span>
                            </label>
                            <button type="submit">Verify</button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

const ResetPasswordEmail = (props) => {
    const [params] = useSearchParams();
    const [token, setToken] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [updated, setUpdated] = useState(false);
    const [setupError, setSetupError] = useState(false);

    useEffect(() => {
        let urlToken = params.get("token");

        if (urlToken === undefined || urlToken === "") {
            setSetupError(true)
        }
        else {
            let urlDecoded = decodeURI(urlToken);
            let base64Decoded = atob(urlDecoded);
            let userToken = JSON.parse(base64Decoded);

            VerifyPasswordToken(userToken).then(v => {
                if (v === true) {
                    setToken(userToken);
                }
                else { setSetupError(true) }
            })
        }
    }, [params])

    if (setupError) return (
        <div className="content">
            <BackButton to="/" />
            <h1>Reset password</h1>
            <p>
                    There was a problem with your email reset password link. Please click the link in your 
                    email invitation again.
                </p>
                <p>
                    If this does not work, please try resetting your password again.
                </p>
        </div>
    )

    if (updated) return (
        <div className="content">
            <BackButton to="/" />
            <h1>Reset password</h1>
            <p>Password has been updated!</p>
            <Link to="/" className="button">Login page</Link>
        </div>
    )

    return (
        <div className="content">
            <BackButton to="/" />
            <h1>Reset password</h1>
            <Formik
                initialValues={{
                    password: "",
                    confirmPassword: ""
                }}
                validationSchema={Yup.object({
                    password: Yup
                        .string()
                        .min(8, 'Your password needs to be at least 8 characters long')
                        .matches(new RegExp('(?=.*[a-z])(?=.*[A-Z])'), 'Your password needs to include both lower and upper case characters')
                        .required('You must enter a password'),
                    confirmPassword: Yup
                        .string()
                        .required('You must enter a password')
                        .oneOf([Yup.ref('password'), null], 'Passwords must match')
                })}
                onSubmit={(values) => {
                    SetNewPassword(token, values.password).then(v => {
                        if (v === true) setUpdated(true);
                        else setErrorMessage("Error setting password, please try resetting password again.");
                    })
                }}
            >
                {(data) => {
                    let error = showError.bind(this, data);
                    return (
                        <Form>
                            <p>
                                Your new password needs to be at least 8 characters long
                                and contain both upper and lower case characters.
                            </p>
                            <label className={error('password')}>
                                New password
                                <Field name="password" type="password" minLength="8"/>
                                <br />
                                <span className='error-message'><ErrorMessage name="password" /></span>
                            </label>
                            <label className={error('confirmPassword')}>
                                Confirm new password
                                <Field name="confirmPassword" type="password" minLength="8" />
                                <br />
                                <span className='error-message'><ErrorMessage name="confirmPassword" /></span>
                                <span className="error-message">{errorMessage}</span>
                            </label>
                            <button type="submit">Confirm</button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

const ForgotUsername = function (props) {
    const [submit, setSubmit] = useState(false);

    const form = (
        <>
            <p>
                Enter the contact details used to create your account.
                We'll text or email your username.
                <br />
                You may need to check your junk folder.
            </p>
            <Formik
                initialValues={{
                    contactDetails: ""
                }}
                validationSchema={Yup.object({
                    contactDetails: Yup.string().required('Enter the mobile number or email linked to your account')
                })}
                onSubmit={(values) => {
                    ResendUsername(values.contactDetails);
                    setSubmit(true);
                }}
            >
                {(data) => {
                    let error = showError.bind(this, data);
                    return (
                        <Form>
                            <label className={error('contactDetails')}>
                                Enter your contact details
                                <Field name="contactDetails" minLength="6" />
                                <span className='error-message'><ErrorMessage name="contactDetails" /></span>
                            </label>
                            <button type="submit">Send me my username</button>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )

    const message = (
        <p>
            Thank you for the username request. If we have your details, your username
            will be sent using your preferred contact method.
            <br />
            You may need to check your junk folder.
        </p>
    )

    let display = submit ? message : form;

    return (
        <div className="content">
            <BackButton to="/" />
            <h1>Forgot your username</h1>
            {display}
        </div>
    )
}

const Login = function (props) {
    const [errorMessage, setErrorMessage] = useState("");
    const [setup, setSetup] = useState(true);
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    const logo = localStorage.getItem("theme") === "dark" ? "logo_dark" : "logo";
    const logoPath = "/icons/" + logo + "512.webp";

    const targetApp = process.env.TARGET_APP;
    const host = process.env.HOST;

    let stagingWarning = host === "https://api.connectapptest.medway.gov.uk/"
    ? <span className="staging-warning">Staging environment</span>
    : <></>;

    useEffect(() => {
        let swReady = navigator.serviceWorker.controller == null || navigator.serviceWorker.controller.state !== 'activated';
        let check = () => {
            swReady = navigator.serviceWorker.controller == null || navigator.serviceWorker.controller.state !== 'activated';
            setSetup(swReady);
            if (swReady) { timeId = setTimeout(check, 100); }
            return timeId;
        }

        let timeId = 0;
        if (swReady) {
            timeId = setTimeout(check, 100);
        } else {
            setSetup(false);
        }

        return () => clearTimeout(timeId);
    }, []);


    const onSubmit = ({ username, password }, resetForm) => {
        Logon(username, password, targetApp).then(r => {
            if (r === false) {
                resetForm();
                setErrorMessage("Your username or password is incorrect. Please try again.");
                setShow(false);
            } else {
                // set the auth context
                sessionStorage.setItem("jwt", r);
                if (navigator.serviceWorker.controller !== null) {
                    navigator.serviceWorker.controller.postMessage({
                        type: 'STORE-TOKEN',
                        token: r
                    });
                }
                navigate(0);
            }
        });
    }

    const loginErrors = errorMessage === "" 
    ? <span className='error-message'><ErrorMessage name="password" /></span> 
    : <span className='error-message'>{errorMessage}</span>;

    if (setup) {
        return (
            <div>
                Setting up service worker...
            </div>
        )
    }

    return (
        <div className="content-minimal login">
            <div className="logo-container">
                <img fetchpriority="high" src={logoPath} alt="app logo"/>
            </div>
            {stagingWarning}
            <Formik
                initialValues={{ username: "", password: "" }}
                validationSchema={Yup.object({
                    username: Yup.string().required('Enter your username'),
                    password: Yup.string().required('Enter your password')
                })}
                onSubmit={(values, { resetForm }) => {
                    setShow(true);
                    onSubmit(values, resetForm);
                }}
            >
                {(data) => {
                    let error = showError.bind(this, data);
                    return (
                        <Form>
                            <label className={error("username")}>
                                Username
                                <Field name="username" autoFocus={true} />
                                <br />
                                <span className='error-message'><ErrorMessage name="username" /></span>
                            </label>
                            <label className={error("password")}>
                                Password
                                <Field name="password" type="password" />
                                <br />
                                {loginErrors}
                            </label>
                            <br />
                            <div className="button-container">
                                <LoaderButton show={show} type="submit" buttonStyle="button-max">Log in</LoaderButton>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
            <div className="forgot-container">
                <Link to="/forgot/username">Forgot your username?</Link>
                <Link to="/forgot/password">Forgot your password?</Link>
            </div>
        </div>
    );
}

const Logout = function (props) {
    const navigate = useNavigate();
    sessionStorage.removeItem("jwt");
    if (navigator.serviceWorker.controller !== null) {
        navigator.serviceWorker.controller.postMessage({
            type: 'CLEAR-TOKEN'
        });
    }

    useEffect(() => {
        navigate("/");
    }, [navigate])
}

export { AuthContext, BuildContext, Login, ForgotPassword, ResetPasswordSms, ResetPasswordEmail, ForgotUsername, Logout };