import { createContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Icon from "./Icons";

const ModalContext = createContext({
    setShowTextNavigate: (text, route) => {}
});

function Modal(props) {
    const showHideClass = props.show ? "block" : "none";

    let confirm = props.confirm ? props.confirm : "Yes";
    let cancel = props.cancel ? props.cancel : "No";
    let title = props.text ? <h2>{props.text}</h2> : null;

    let buttons = props.hideButtons === true ? null :
    (<div className="button-container">
        <button className="button" onClick={props.submit}>{confirm}</button>
        <button className="button button-secondary" onClick={props.handleClose} >{cancel}</button>
    </div>)

    return (
        <div className="modal" style={{ display: showHideClass }}>
            <section className='modal-main'>
                {title}
                {props.children}
                {buttons}
            </section>
        </div>
    );
};

function UpdateModal(props) {
    const navigate = useNavigate();
    const showHideClass = props.show ? "flex" : "none";
    const delay = 3 * 1000;

    const closeModal = () => {
        props.setShow(false);
        if (props.navigate) {
            navigate(props.navigate);
        }
    }

    useEffect(() => {
        let timeId = 0
        if (props.show) {
            timeId = setTimeout(() => {
                props.setShow(false);
                if (props.navigate) {
                    navigate(props.navigate);
                }
            }, delay);
        }

        return () => { clearTimeout(timeId); }
    }, [props, delay, navigate]);

    const exitButton = (
        <span className="clickable" onClick={() => closeModal()}>
            <Icon.Close />
        </span>
    )

    return (
        <div className="update-modal-container clickable" style={{ display: showHideClass }} onClick={() => closeModal()}>
            <div className="update-modal">
                {exitButton}
                <p><b>{props.text}</b></p>
            </div>
        </div>
    )
}

export { ModalContext, Modal, UpdateModal }