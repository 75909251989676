const StuffType = {
    Base: 0,
    Link: 1,
    Text: 2,
    Image: 3,
    Emoji: 4
}

const StuffGroup = {
    WhoIAm: 0,
    WantToBe: 1,
    GetThere: 2
}

Object.freeze(StuffType);
Object.freeze(StuffGroup);

class Base {
    constructor(id) {
        this.id = id;
        this.Type = StuffType.Base;
        this.Group = StuffGroup.WhoIAm;
    }
}

class Link extends Base {
    constructor(id, group, title, url) {
        super(id);

        this.Type = StuffType.Link;
        this.Group = group;

        this.Title = title;
        this.URL = url;
    }
}

class Text extends Base {
    constructor(id, group, text){
        super(id);

        this.Type = StuffType.Text;
        this.Group = group;

        this.Value = text;
    }
}

class Emoji extends Base {
    constructor(id, group, emoji){
        super(id);

        this.Type = StuffType.Emoji;
        this.Group = group;

        this.Value = emoji;
    }
}


class Image extends Base {
    constructor(id, group, title, imageId){
        super(id);

        this.Type = StuffType.Image;
        this.Group = group;

        this.Title = title;
        this.ImageId = imageId;
    }
}


export { StuffType, StuffGroup, Link, Text, Emoji, Image };