import { useContext } from "react";
import { AuthContext } from "../../auth/Auth";
import { Practitioners } from "../practitioners/Practitioners";

export function Homepage(props){
    const auth = useContext(AuthContext);
    const user = auth.user();

    return (
        <Practitioners user={user} />
    )
};

export const NotFound = (props) => {
    return(
        <div className="content">
            <h1>Not Found</h1>
        </div>
    )
}
