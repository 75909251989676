import React from "react";
import { Outlet } from "react-router-dom";
import { Goals, AddPractitionerGoal, EditPractitionerGoal } from "./Goals";
import TasksRoutes from "./tasks/Routes";

const GoalsRoutes = {
    path: "goals",
    element: <Outlet />,
    children: [
        {
            path: "",
            element: <Goals />
        },
        {
            path: "add",
            element: <AddPractitionerGoal/>
        },
        {
            path: ":id",
            element: <EditPractitionerGoal/>
        },
        TasksRoutes
    ]
};

export default GoalsRoutes;
