import { Outlet } from "react-router-dom";
import Invite from "./Invite";
import { Superusers, SuperusersEdit } from "./Superusers";

const SuperUsersRoutes = {
    path: "superusers",
    element: <Outlet />,
    children: [
        {
            path: "",
            element: <Superusers />
        },
        {
            path: "invite",
            element: <Invite />
        },
        {
            path: ":id/edit",
            element: <SuperusersEdit />
        }
    ]
};

export default SuperUsersRoutes;