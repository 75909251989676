import { useState, useContext } from 'react';
import { GetMaxTimelineChildId, GetTimelineChildId } from './Data';
import { CaseContext } from '../../cases/Cases';
import { Pager } from '../../../shared/layout/Layout';
import { Activities, NoActivities } from '../../../shared/timeline/Timeline';
import { nameFormatter } from "../Common";
import './timeline.scss';
import BackButton from '../../../shared/navigation';

const Timeline = function (props) {
    const [filterId, setFilterId] = useState(null);
    var caseContext = useContext(CaseContext);
    const caseItem = caseContext.case;

    if(caseContext.case === undefined){
        return (<div><h1>Case required</h1></div>);
    }

    let changeFilter = (e) => {
        switch (e.target.value) {
            case "0":
                setFilterId(null);
                break;
            case "1":
                setFilterId(caseItem.childId);
                break;
            default:
                setFilterId(null);
                break;
        }
    }

    let getTimeline;
    let getMaxTimeline;

    if (filterId) {
        getTimeline = (count, page) => GetTimelineChildId(caseItem.childId, count, page, filterId);
        getMaxTimeline = () => GetMaxTimelineChildId(caseItem.childId, filterId);
    }
    else {
        getTimeline = (count, page) => GetTimelineChildId(caseItem.childId, count, page);
        getMaxTimeline = () => GetMaxTimelineChildId(caseItem.childId);
    }

    const childName = nameFormatter(caseItem.childName.first)

    return (
        <div className="content-case timeline">
            <BackButton />
            <h1>{childName} timeline</h1>
            <select onChange={changeFilter}>
                <option value="0">All activity</option>
                <option value="1">{childName} Activity</option>
            </select>
            <Pager maxFunction={getMaxTimeline} data={getTimeline} count={5} empty={NoActivities} mapItems={Activities} className="events-list" />
        </div>
    )
}

export default Timeline;