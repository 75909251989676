import api from '../../shared/api.js'

const GetName = function () {
    return api.get("practitioner/name");
}

const SetName = function (name) {
    return api.post("practitioner/name", name);
}

const GetBio = function () {
    return api.get("practitioner/bio");
}

const UpdateBio = function (bio) {
    return api.put("practitioner/bio", bio);
}

const GetTheme = function () {
    return api.get("practitioner/theme").then(data => data.theme);
}

const UpdateTheme = function (theme) {
    return api.post("practitioner/theme", { theme: theme });
}

const UploadProfilePicture = function (form) {
    return api.form("practitioner/profile-picture/upload", form);
}

const DeleteProfilePicture = function () {
    return api.delete("practitioner/profile-picture/");
}
const GetContactDetails = function(){
    return api.get("practitioner/contact-details");
}
const UpdateContactDetails = function(details){
    return api.post("practitioner/contact-details", details);
}

const AcceptTerms = function(){
    return api.postOnly("practitioner/accept-terms");
}

export { GetName, SetName, GetBio, UpdateBio, GetTheme, UpdateTheme, UploadProfilePicture, DeleteProfilePicture, GetContactDetails, UpdateContactDetails, AcceptTerms };
