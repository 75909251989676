import { Outlet } from "react-router-dom";
import { CaseEdit, Cases } from "./Cases";

const CasesRoutes = {
    path: "cases",
    element: <Outlet />,
    children: [
        {
            path: "",
            element: <Cases />
        },
        {
            path: ":id/assign",
            element: <CaseEdit />
        }
    ]
};

export default CasesRoutes;