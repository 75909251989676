import api from '../../shared/api.js'

const GetAppointments = function (count, offset) {
    if(offset === undefined)
        return api.get("appointments");

    return api.getPaged("appointments", count, offset);
}

const GetMaxAppointments = function(){
    return api.get("appointments/max");
}

const GetAppointmentsChildId = function (count, offset, childId) {
    return api.getPaged("appointments?childId=" + childId, count, offset);
}

const GetMaxAppointmentsChildId = function (childId){
    return api.get("appointments/max?childId=" + childId);
}

const GetPastAppointments = function (count, offset) {
    return api.getPaged("appointments/past", count, offset);
}

const GetMaxPastAppointments = function(){
    return api.get("appointments/past/max");
}

const GetPastAppointmentsChildId = function (count, offset, childId) {
    return api.getPaged("appointments/past?childId=" + childId, count, offset);
}

const GetMaxPastAppointmentsChildId = function (childId){
    return api.get("appointments/past/max?childId=" + childId);
}

const GetAppointment = function (id) {
    return api.get("appointments/" + id);
}

const GetNextAppointment = function () {
    return api.get("appointments/next");
}

const GetNextAppointmentChildId = function (childId) {
    return api.get("appointments/next?childId=" + childId);
}

const CreateAppointment = function (request) {
    return api.post("appointments", request);
}

const UpdateAppointment = function (id, update) {
    return api.put("appointments/" + id, update);
}

const DeleteAppointment = function (id) {
    return api.delete("appointments/" + id);
}

const GetAppointmentStuff = function (id){
    return api.get("appointments/" + id + "/mystuff");
}

const UpdateAppointmentStuff = function(id, stuff){
    return api.post("appointments/" + id + "/mystuff", stuff);
}

const SetAppointmentAttendance = function(id, attended){
    return api.post("appointments/" + id + "/attendance?attended=" + attended, {});
}

const GetAppointmentNotes = function (id){
    return api.get("appointments/" + id + "/notes");
}

const SetAppointmentNotes = function (id, notes){
    return api.post("appointments/" + id + "/notes", notes);
}

export {
    GetAppointments,
    GetMaxAppointments,
    GetAppointmentsChildId,
    GetMaxAppointmentsChildId,
    GetPastAppointments,
    GetMaxPastAppointments,
    GetPastAppointmentsChildId,
    GetMaxPastAppointmentsChildId,
    GetAppointment,
    GetNextAppointment,
    GetNextAppointmentChildId,
    CreateAppointment,
    UpdateAppointment,
    DeleteAppointment,
    GetAppointmentStuff,
    UpdateAppointmentStuff,
    SetAppointmentAttendance,
    GetAppointmentNotes,
    SetAppointmentNotes
};