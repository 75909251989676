import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { LoaderButton, showError } from '../../shared/layout/Layout';

function AppointmentsForm(props) {
    let initialValues = {datetime: "", location: "", notes: ""};
    if(props.datetime !== undefined){
        initialValues = { 
            datetime: props.datetime, 
            location: props.location, 
            notes: props.notes
        };
    }

    const readOnly = props.readOnly === undefined ? false : props.readOnly;

    let text = null;
    let notes = null;

    if(props.submit === undefined){
        text = "Add appointment"
    }else{
        text = props.submit;
        notes = (<><h2>Notes</h2>
            <Field name="notes" className="notes" as="textarea" /></>)
    }

    return (
        <Formik 
            initialValues={initialValues}
            validationSchema={Yup.object({
                datetime: Yup.string().test("", "You cannot add appointments in the past", newDateTime => Date.parse(newDateTime) > Date.parse(new Date())).required('Enter the date and time'),
                location: Yup.string().max(128, 'Must be fewer than 128 characters').required('Enter the location'),
                notes: Yup.string()
            })}
            onSubmit={(values) => {
                let formattedValues = {
                    date: values.datetime.substring(0, 10),
                    time: values.datetime.substring(11),
                    location: values.location,
                    notes: values.notes
                }
                props.onSubmit(formattedValues);
            }}
            >
            {(data) => {
                let error = showError.bind(this, data);
                return (
                    <Form className="appointments">
                        <label className={error("datetime")}>
                            Date and time
                            <br />
                            <Field readOnly={readOnly} name="datetime" type="datetime-local" placeholder={Date.parse(new Date())} />
                            <br />
                            <span className='error-message'><ErrorMessage name="datetime" /></span>
                        </label>
                        <label className={error("location")}>
                            Location
                            <Field readOnly={readOnly} name="location" />
                            <br />
                            <span className='error-message'><ErrorMessage name="location" /></span>
                        </label>
                        {notes}
                        <LoaderButton show={props.show} type="submit" buttonStyle="button-max">{text}</LoaderButton>
                    </Form>
                )}
            }
        </Formik>
    );
}

export default AppointmentsForm;