import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import UsersDisplay from "../../../shared/UsersDisplay";
import { AuthContext } from "../../auth/Auth";
import * as Data from "./Data";
import './cases.scss';
import { Loader, LoaderButton, LoaderPage, NickNameGenerator } from "../../../shared/layout/Layout";
import BackButton from "../../../shared/navigation";
import { Modal, ModalContext } from "../../../shared/Modals";

function CaseEdit() {
    const caseId = useParams().id;
    const [caseItem, setCaseItem] = useState(undefined);
    const [practitioners, setPractitioners] = useState([]);
    const [currentPractitioner, setCurrentPractitioner] = useState(undefined);
    const [newPractitioner, setNewPractitioner] = useState(undefined);
    const [errorMessage, showErrorMessage] = useState(false);
    const [show, setShow] = useState(false);
    const [loaderShow, setLoaderShow] = useState(false);

    const modalContext = useContext(ModalContext);

    useEffect(() => {
        Data.GetCase(caseId).then(c => {
            setCaseItem(c);

            Data.GetPractitioners().then((
                p => {
                    var object = {};
                    p.forEach(practitioner => { 
                        if (practitioner.userId === c.practitionerId) {
                            setCurrentPractitioner(practitioner.name);
                        }
                        else object[practitioner.userId] = practitioner;
                    });
                    setPractitioners(object);
                }))
        })
        
    }, [caseId])

    if (caseItem === undefined) {
        return (<LoaderPage/>)
    }

    const onSubmit = () => {
        setShow(false);
        setLoaderShow(true);
        if (newPractitioner === undefined || newPractitioner.userId === "") {
            setLoaderShow(false);
            showErrorMessage(true);
        }
        else {
            Data.UpdateCasePractitioner(caseId, newPractitioner.userId).then(() => {
                setLoaderShow(false);
                modalContext.setShowTextNavigate("Assigned practitioner updated", "/cases");
            });
        }
    }

    const modalShow = () => {
        if (newPractitioner === undefined || newPractitioner.userId === "") showErrorMessage(true);
        else {
            setShow(true);
        }
    }

    const selectPractitioner = (e) => {
        setNewPractitioner(practitioners[e.target.value]);
        showErrorMessage(false);
    }

    const SelectPractitioners = (p) => {
        return (
            <option key={p.userId} value={p.userId}>{p.name.first} {p.name.last}</option>
        )
    }

    const selectList = [];
    for (const practitioner in practitioners) {
        selectList.push(SelectPractitioners(practitioners[practitioner]));
    }

    const options = (
        <select onChange={selectPractitioner} defaultValue="practitioners">
            <option value="practitioners" disabled> Select a practitioner </option>
            {selectList}
        </select>
    )

    const practitionerHeader = newPractitioner === undefined 
    ? <h1>Assign practitioner to {caseItem.childName.first}</h1> 
    : <h1>Assign {newPractitioner.name.first} to {caseItem.childName.first}</h1>

    const currentPractitionerHeader = currentPractitioner === undefined 
    ? <h2>This child currently doesn't have an active practitioner</h2>
    : <h2>Current practitioner is {currentPractitioner.first} {currentPractitioner.last}.</h2>

    const error = errorMessage === false ? <></> : <span className="error-message">Please select a practitioner.</span>;

    return (
        <div className="content assign-child">
            <BackButton />
            {practitionerHeader}
            {currentPractitionerHeader}
            <label>
                New practitioner
                <br />
                {practitioners === undefined ? <Loader /> : options}
            </label>
            {error}
            <br />
            <Modal show={show} handleClose={() => setShow(false)} submit={onSubmit} text="Are you sure you want to update assigned practitioner?"/>
            <div className="button-container assign-button-container">
                <LoaderButton show={loaderShow} submit={modalShow} buttonStyle="button-max">Update</LoaderButton>
            </div>
        </div>
    )
}

function Cases() {
    const auth = useContext(AuthContext);
    const user = auth.user();

    const MakeRowItem = function (caseItem) {
        return (
            <div key={caseItem.caseId} className="box-row">
                <div className="name">
                    <span className='clickable' onClick={null}>{caseItem.childName.first} {NickNameGenerator(caseItem.childName.nickname)} {caseItem.childName.last}</span>
                </div>
                <div className="controls">
                    <Link to={"/cases/" + caseItem.caseId + "/assign"}>
                        Assign
                    </Link>
                </div>
            </div>
        );
    }

    const NoCases = () => (
        <div>No Cases added.</div>
    )

    return (
        <div className="superuser content">
            <div className="user-name">
                <h1>
                    <b>{user.name.first} {user.name.last}</b>
                </h1>
            </div>
            <h2>Cases</h2>
            <div className="cases-list">
                <UsersDisplay searchData={Data.SearchCases} row={MakeRowItem} max={Data.GetMaxCases} data={Data.GetCases} empty={NoCases} route="invite" button={false}/>
            </div>
        </div>
    )

}

export { Cases, CaseEdit };