import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ViewStuff } from './AppointmentsStuff';
import AppointmentsForm from './AppointmentsForm';

import * as Data from './Data';
import { GetName } from '../child/Data';

import { Modal, ModalContext } from '../../shared/Modals';
import BackButton from '../../shared/navigation';
import { LoaderButton, LoaderPage } from '../../shared/layout/Layout'
import { Format, CreateDate } from '../../shared/dates';

import './Appointments.scss';
import '../child/mystuff/mystuff.scss';
import { CaseContext } from '../cases/Cases';

function AttendanceSection(props) {
    const id = props.id;
    const [show, setShow] = useState(false);

    let confirm = (val) => {
        props.update(id, val);
        setShow(false);
    }

    let attendanceSection = null;

    if (props.attended === undefined || props.attended === null) {
        attendanceSection = (<>
            <span>You have not yet marked attendance</span>
            <button className='button-max' onClick={() => { setShow(!show) }}>Mark attendance</button>
        </>)
    }
    else {
        attendanceSection = props.attended ? <div>{props.childName} attended this appointment</div> : <div>{props.childName} did not attend this appointment</div>
    }

    return (
        <div>
            <h2>Attendance</h2>
            {attendanceSection}
            <Modal show={show} handleClose={() => confirm(false)} submit={() => confirm(true)} text={"Confirm attendance"}>
                <p>Did {props.childName} attend this appointment?</p>
            </Modal>
        </div>
    );
}

function AppointmentsView(props) {
    const params = useParams();
    const id = params.id;
    
    let caseContext = useContext(CaseContext);
    let modalContext = useContext(ModalContext);
    let hasCase = caseContext.case != null;
    
    const [appointment, setAppointment] = useState(undefined);
    const [childId, setChildId] = useState(undefined);
    const [childName, setChildName] = useState("");
    const [notes, setNotes] = useState("");
    const [attended, setAttended] = useState();
    const [showDelete, setShowDelete] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        async function getData() {
            let response = await Data.GetAppointment(id);
            let r = await Data.GetAppointmentNotes(id);
            if(r !== undefined){
                setNotes(r.notes);
            }

            const occurs = Format(response.occurs);
            let app = {...response, date: occurs.input, time: occurs.inputTime};
            setAppointment(app);

            let name = await GetName(response.childId)
            setChildName(name.first);
            setChildId(response.childId);
            setAttended(response.attended);
        }
        getData();
    }, [id]);

    let onSaveSubmit = (data) => {
        setShow(true);
        const occurs = CreateDate(data.date, data.time);
        const update = {
            "location": data.location,
            "occurs": occurs
        }
        
        if(data.notes !== notes){
            Data.SetAppointmentNotes(id, data.notes).then(() => {
                setNotes(data.notes);
                setShow(false);
                modalContext.setShowTextNavigate("Appointment updated", "/appointments");
            });
        }
        if( update.location !== appointment.location 
            || data.date !== appointment.date
            || data.time !== appointment.time ) {
            Data.UpdateAppointment(id, update).then(() => {
                setShow(false);
                modalContext.setShowTextNavigate("Appointment updated", "/appointments");
            });
        }
        else {
            setShow(false);
        }
    }

    let onDeleteSubmit = (e) => {
        e.preventDefault();
        Data.DeleteAppointment(id).then(() => {
            setShowDelete(false);
            modalContext.setShowTextNavigate("Appointment deleted", "/appointments");
        });
    }

    let updateAttendance = (id, val)=>{
        Data.SetAppointmentAttendance(id, val).then(()=>setAttended(val));
    }

    if(appointment === undefined){
        return (<div className='appointments content'><LoaderPage /></div>)
    }
    
    let now = new Date();
    let appointmentFuture = new Date(appointment.date) > now;

    let attendanceArea = null;
    if(appointmentFuture === false){
        attendanceArea = <AttendanceSection id={id} update={updateAttendance} attended={attended} childName={childName}/>
    }

    let title = <h1>Appointment</h1>
    if(childName !== ""){
        title = <h1>Appointment with {childName}</h1>
    }

    const className = hasCase ? "content-case" : "content";

    const datetime = appointment.date + "T" + appointment.time;

    return (
        <div className={'appointments ' + className}>
            <BackButton to="/appointments" />
            {title}
            <AppointmentsForm readOnly={!appointmentFuture} onSubmit={onSaveSubmit} datetime={datetime} location={appointment.location} notes={notes} submit="Update appointment" show={show}/>
            <button className='button button-max button-delete' onClick={() => setShowDelete(true)}>Delete appointment</button>
            {attendanceArea}
            <ViewStuff childId={childId} id={id} showLink={appointmentFuture} />
            <div>
                <Modal show={showDelete} handleClose={() => setShowDelete(false)} submit={onDeleteSubmit} text="Are you sure you want to delete this appointment?" />
            </div>
        </div>
    );
}

export { AppointmentsView };