import api from '../../../../shared/api'

const GetTasksPractitioner = (childId, goalId) => {
    return api.get("child/" + childId + "/goals/" + goalId + "/tasks");
}

const GetTaskPractitioner = (childId, goalId, id) => {
    return api.get("child/" + childId + "/goals/" + goalId + "/tasks/" + id);
}

const CreateTaskPractitioner = (childId, goalId, task) => {
    return api.post("child/" + childId + "/goals/" + goalId + "/tasks", task);
}

const EditTaskPractitioner = (childId, goalId, id, update) => {
    return api.put("child/" + childId + "/goals/" + goalId + "/tasks/" + id, update)
}

const ChangeTaskOrderPractitioner = (childId, goalId, tasksDict) => {
    return api.put("child/" + childId + "/goals/" + goalId + "/tasks/order", tasksDict);
}

const ChangeTaskStatusPractitioner = (childId, goalId, id, status) => {
    return api.put("child/" + childId + "/goals/" + goalId + "/tasks/" + id + "/status?status=" + status);
}

const DeleteTaskPractitioner = (childId, goalId, id) => {
    return api.delete("child/" + childId + "/goals/" + goalId + "/tasks/" + id)
}

export { GetTasksPractitioner, GetTaskPractitioner, CreateTaskPractitioner, EditTaskPractitioner, DeleteTaskPractitioner, ChangeTaskOrderPractitioner, ChangeTaskStatusPractitioner };