import api from "../../../shared/api.js"

const GetCases = (count, offset) => {
    return api.getPaged("cases", count, offset);
}

const GetMaxCases = () => {
    return api.get("cases/max");
}

const SearchCases = (search) => {
    return api.get("cases/search?name=" + search);
}

const GetCase = (id) => {
    return api.get("cases/" + id);
}

const GetPractitioners = (count, offset) => {
    return api.getPaged("superuser/practitioners", count, offset);
}

const UpdateCasePractitioner = (id, practitionerId) => {
    return api.put("cases/" + id + "/update/" + practitionerId);
}

export { GetCases, GetMaxCases, SearchCases, GetCase, GetPractitioners, UpdateCasePractitioner };