import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CaseContext } from "../cases/Cases";
import './child.scss';
import ProfileHeader from "../../shared/ProfileHeader";
import { GetChildBackground } from "./Data";
import { NotificationContext, NotificationItem } from '../notifications/Notifications';
import { GetRecentChildNotifications } from "../notifications/Data";
import { nameFormatter } from "./Common";
import { NickNameGenerator } from "../../shared/layout/Layout";
import { ItemArea } from "../../shared/Events";
import BackButton from "../../shared/navigation";

const ChildHeader = function(props){
    const [background, setBackground] = useState();
    let caseContext = useContext(CaseContext);
    let caseItem = caseContext.case;

    useEffect(() => {
        GetChildBackground(caseItem.childId).then((b) => {
            if (b.background !== null) setBackground(b.background.toUpperCase());
        })
    }, [caseItem.childId])

    if (caseContext.case === undefined) {
        return (<></>)
    }

    const profileUrl = "media/profile-picture/" + caseItem.childId

    return (
        <ProfileHeader backgroundImage={background} profileUrl={profileUrl} />
    );
}

const Child = function (props) {
    const caseContext = useContext(CaseContext);
    const [childNotifications, setChildNotifications] = useState([]);
    const caseItem = caseContext.case;

    const notificationContext = useContext(NotificationContext);

    useEffect(()=>{
        if(caseItem === undefined) return;
        GetRecentChildNotifications(caseItem.childId).then(c => {
            setChildNotifications(c);
        });
    },[caseItem]);

    useEffect(() => {
        if(caseItem === undefined) return;
        notificationContext.readNotification(ItemArea.Cases, caseItem.id);
    }, [notificationContext, caseItem]);
    
    if (caseContext.case === undefined) {
        return (<div>
            No child has been selected.
        </div>)
    }

    const mapNotification = (n) => {
        return  <NotificationItem key={n.id} item={n}/>
    }

    const notes = childNotifications.map(mapNotification);
    const contact = caseItem.contactMethod.contact === 1
        ? caseItem.contactMethod.mobile 
        : caseItem.contactMethod.email; 
    
    const childName = nameFormatter(caseItem.childName.first);

    return (
        <div className="child">
            <ChildHeader />
            <div className="content">
                <div className="user-name">
                    <span className="info">
                        <b>{caseItem.childName.first} {NickNameGenerator(caseItem.childName.nickname)} {caseItem.childName.last}</b>
                        <span>{contact}</span>
                    </span>
                </div>
                <BackButton />
                <h2>{childName} recent activity</h2>
                <ul className="events-list">
                    {notes}
                </ul>
                <h2>Manage case</h2>
                <div className="case-list">
                    <Link to="/messages" className="button button-secondary">
                        {childName} messages
                    </Link>
                    <Link to="/appointments" className="button button-secondary">
                        {childName} appointments
                    </Link>
                    <Link to="/child/mystuff" className="button button-secondary">
                        {childName} stuff
                    </Link>
                    <Link to={"/child/goals"} className="button button-secondary">
                        {childName} goals
                    </Link>
                    <Link to="/child/timeline" className="button button-secondary">
                        {childName} timeline
                    </Link>
                </div>
            </div>
        </div>
    )
};

export { Child, ChildHeader, nameFormatter };