import { Link } from "react-router-dom";
import { IconButton, Pager } from "./layout/Layout";
import * as Icon from "./Icons";
import { useEffect, useState } from "react";
import SearchBar from "./SearchBar";

function UsersDisplay(props) {
    const [items, setItems] = useState([]);
    const [search, setSearch] = useState("");

    const searchData = props.searchData;

    useEffect(() => {
        if (search.trim() !== '') {
            searchData(search).then(data => setItems(data));
        }
    }, [search, searchData]);

    const mapItems = (item) => props.row(item);

    const NoResult = () => (
        <div>No result</div>
    )

    let display = <Pager maxFunction={props.max} data={props.data} count={5} empty={props.empty} mapItems={mapItems} className="box" />

    if (search.trim() !== '') {
        if (items.length === 0) {
            display = NoResult();
        } else {
            display = (<ul className='box'>{items.map(mapItems)}</ul>);
        }
    }

    const user = props.user ? props.user : "user";
    const button = props.button === false ? <></> : <Link to={props.route}><IconButton icon={<Icon.Add />}>Add a {user}</IconButton></Link>;

    return (
        <div>
            <div className="search-add-container">
                <SearchBar setSearch={setSearch} />
                {button}
            </div>
            {display}
        </div>
    )
}

export default UsersDisplay;