import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { StuffGroup } from './Stuff';
import { mapTitle } from './MyStuff';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/src/ReactCrop.scss'
import data from '@emoji-mart/data/sets/14/twitter.json'
import Picker from '@emoji-mart/react'
import { init } from 'emoji-mart'
import { Modal } from '../Modals';
import BackButton from '../navigation';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Loader, showError } from '../layout/Layout';
import * as Icon from '../Icons';

const AddStuff = function () {
    return (
        <div className='stuff-add'>
            <ul>
                <li>
                    <Link to="../whoiam/add">
                        {mapTitle(StuffGroup.WhoIAm)}
                    </Link>
                </li>
                <li>
                    <Link to="../whoiwanttobe/add">
                        {mapTitle(StuffGroup.WantToBe)}
                    </Link>
                </li>
                <li>
                    <Link to="../howillgetthere/add">
                        {mapTitle(StuffGroup.GetThere)}
                    </Link>
                </li>
            </ul>
        </div>
    )
}

const AddGroupStuff = function (props) {
    const [group] = useState(Number(props.group));
    const useQuery = props.useQuery;

    let query = "";
    if (useQuery !== null || useQuery !== undefined) {
        query = "?group=" + group;
    }

    return (
        <ul className='stuff-add stuff-add-type'>
            <li>
                <Link to={"image" + query} group={group}>Upload photo</Link>
            </li>
            <li>
                <Link to={"text" + query} group={group}>Text</Link>
            </li>
            <li>
                <Link to={"link" + query} group={group}>Link</Link>
            </li>
            <li>
                <Link to={"emoji" + query} group={group}>Emoji</Link>
            </li>
        </ul>
    );
}

const AddLink = function (props) {
    const navigate = useNavigate(); 

    let helpText = null;
    if (props.hasCase) helpText = <p>Add a link to something you want to explore with {props.case.childName.first} when you see them.</p>
    else {
        switch (Number(props.group)) {
            case StuffGroup.WhoIAm:
                helpText = (
                    <p>
                        Add a link to something that represents your identity. 
                        <br />
                        <br />
                        For example, your likes and dislikes, how you're feeling, who or what's important to you.
                    </p>
                );
                break;
            case StuffGroup.WantToBe:
                helpText = (
                    <p>
                        Add a link to something that represents the type of person you would like to be in the future.
                        <br />
                        <br />
                        Maybe a job you would like to do, someone you look up to or how you want others to see you.
                    </p>
                );
                break;
            case StuffGroup.GetThere:
                helpText = (
                    <p>
                        Add a link to something that represents the steps needed to reach your goals and become the best you!
                    </p>
                );
                break;
            default:
                break;
        }
    }

    const style = props.hasCase ? "content-case" : "content";

    return (
        <div className={style + " link-add"}>
            <BackButton to={props.return}/>
            <h1>Add a link</h1>
            <Formik
                initialValues={{link: "", title: ""}}
                validationSchema={Yup.object({
                    link: Yup.string().required("Write or paste a link to a web page"),
                    title: Yup.string().required("Choose a title for your link")
                })}
                onSubmit={(values) => {
                    props.submit({
                        group: Number(props.group),
                        ref: values.link,
                        title: values.title
                    }).then(() => navigate(props.return))
                }}
            >
                {(data) => {
                        let error = showError.bind(this, data);
                    return(
                        <Form>
                            {helpText}
                            <label className={error("link")}>
                                Write or paste a link
                                <br />
                                <Field name="link" />
                                <span className='error-message'><ErrorMessage name="link" /></span>
                            </label>
                            <br />
                            <label className={error("title")}>
                                Choose a title for your link
                                <br />
                                <Field name="title" />
                                <span className='error-message'><ErrorMessage name="title" /></span>
                            </label>
                            <br />
                            <button className='button button-max' type='submit'>Save link</button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    );
}

const AddEmoji = function (props) {
    const navigate = useNavigate();
    const theme = localStorage.getItem("theme");
    
    const [error, setError] = useState("");
    const [chosenEmoji, setChosenEmoji] = useState([]);

    const text = props.hasCase ? <p>Add emojis you want to explore with {props.case.childName.first} when you see them.</p> : <></>;

    useEffect(() => {
        init({data});
    }, [])

    const onEmojiClick = (e) => {
        if(chosenEmoji.length < 5) {
            setError("");
            setChosenEmoji([...chosenEmoji, e.shortcodes]);
        }
    };

    const submit = () => {
        if(chosenEmoji.length === 0){
            setError("Choose at least one emoji");
            return;
        }
        setError("");
        props.submit({
            group: Number(props.group),
            value: chosenEmoji
        }).then(() => {
            setChosenEmoji([]);
            navigate(props.return);
        });
    }

    const sheetUrl = () => {
        return "/emoji/64.png"
    }

    const deleteEmoji = (position) => {
        let array = [...chosenEmoji];
        array.splice(position, 1);
        setChosenEmoji(array);
    }

    let showEmoji = [];
    for (let x = 0; x < chosenEmoji.length; x++) {
        const emoji = chosenEmoji[x];
        showEmoji.push(
            <li key={x} onClick={() => deleteEmoji(x)} >
                <Icon.Delete />
                <em-emoji set="twitter" shortcodes={emoji} />
            </li>
        );
    }

    const errorClass = error === "" ? "" : "error";
    const style = props.hasCase ? "content-case" : "content";

    return (
        <div className={style + ' emoji-add'}>
            <BackButton to={props.return}/>
            <h1>Add emojis</h1>
            {text}
            <h2>I'm feeling...</h2>
            <div className="input-container">
                <span className={"list-background " + errorClass}>
                    <ul className='emoji-list'>
                        {showEmoji}
                    </ul>
                </span>
                <button className="button-minimum" onClick={submit}>Save</button>
                <span className='error-message'>{error}</span>
            </div>
            <div className='emoji-picker'>
                <Picker set="twitter" getSpritesheetURL={sheetUrl} onEmojiSelect={onEmojiClick} theme={theme} previewPosition="none" skinTonePosition="none" />
            </div>
        </div>
    );
}
const AddText = function (props) {
    const navigate = useNavigate();

    const style = props.hasCase ? "content-case" : "content";
    const text = props.hasCase ? <p>Add text about something you want to explore with {props.case.childName.first} when you see them.</p> : <></>;
    const fieldText = props.hasCase ? "Text" : "What's on your mind?";

    return (
        <div className={style + ' text'}>
            <BackButton to={props.return}/>
            <h1>
                Add text
            </h1>
            {text}
            <Formik
                initialValues={{text: ""}}
                validationSchema={Yup.object({
                    text: Yup.string().required("You need to add some text")
                })}
                onSubmit={(values) => {
                    props.submit({
                        group: Number(props.group),
                        text: values.text
                    }).then(() => navigate(props.return))
                }}
            >
                {(data) => {
                        let error = showError.bind(this, data);
                    return(
                        <Form>
                            <label className={error("text")}>
                                {fieldText}
                                <br />
                                <Field name="text" as="textarea" />
                                <span className='error-message'><ErrorMessage name="text" /></span>
                            </label>
                            <br />
                            <button className='button button-max' type='submit'>Save text</button>
                        </Form>
                    )
                }}
            </Formik>

        </div>
    );
}


function ImageCrop(props) {
    const [crop, setCrop] = useState(undefined);
    const [disp, setDisp] = useState(undefined);
    const [error, setError] = useState("");

    const imgLoad = (e) => {
        setDisp({ width: e.target.width, height: e.target.height });
    }

    const pick = () => {
        if(crop === undefined || crop.width === 0 || crop.height === 0){
            setError("You must choose a crop");
            return;
        }
        props.upload(crop, disp);
        setCrop(undefined);
    }
    return (
        <Modal show={props.show} handleClose={props.cancel} submit={pick} confirm="Crop" cancel="Cancel">
            <ReactCrop crop={crop} aspect={props.aspectRatio} onChange={(c) => setCrop(c)}>
                <img src={props.src} alt="New stuff" onLoad={imgLoad} className='picture-preview'/>
            </ReactCrop>
            <span>
                Tap & drag to crop your photo.
                <br /> 
                The cropped image will be shown in the 'My stuff' list.
                When you click it, you'll see the original image.
            </span>
            <br/>
            <span className="error-message">{error}</span>
            <br/>
        </Modal>
    );
}



const AddImage = (props) => {
    const [title, setTitle] = useState("");
    const [preview, setPreview] = useState(undefined);
    const [previewCrop, setPreviewCrop] = useState(undefined);
    const [image, setImage] = useState(undefined);
    const [modal, setModal] = useState(false);
    const [error, setError] = useState("");
    const [uploading, setUploading] = useState(false);
    let text;

    const canvasRef = useRef(null);
    const aspectRatio = 0.65;
    const navigate = useNavigate();

    useEffect(() => {
        if (!image) {
            setPreview(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(image);
        setPreview(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
    }, [image]);

    const onSelectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setImage(undefined);
            return;
        }

        setImage(e.target.files[0]);
        setModal(true);
        setError("");
    }

    const cropImage = (crop, disp) => {
        const imgElement = document.createElement('img');
        imgElement.src = preview;
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext('2d');
        let ratio = imgElement.naturalWidth / disp.width;
        let imgSize = 300;

        canvas.width = imgSize * aspectRatio;
        canvas.height = imgSize;

        ctx.drawImage(imgElement,
            crop.x * ratio,
            crop.y * ratio,
            crop.width * ratio,
            crop.height * ratio,
            0, 0,
            imgSize * aspectRatio,
            imgSize);

        const drawctx = canvasRef.current.getContext("2d");
        canvasRef.current.width = imgElement.naturalWidth;
        canvasRef.current.height = imgElement.naturalHeight;

        let dwidth = canvasRef.current.width;
        let dheight = canvasRef.current.height;

        drawctx.drawImage(imgElement, 0, 0, dwidth, dheight);

        drawctx.fillStyle = "rgba(0, 0, 0, 0.5)";
        drawctx.fillRect(0, 0, dwidth, dheight);

        drawctx.drawImage(imgElement,
            crop.x * ratio,
            crop.y * ratio,
            crop.width * ratio,
            crop.height * ratio,
            crop.x * ratio,
            crop.y * ratio,
            crop.width * ratio,
            crop.height * ratio);

        canvas.toBlob((blob) => { setPreviewCrop(blob) });
        setModal(false);
    }

    const submit = () => {
        // this should no longer happen
        if (image === undefined || image.length === 0) {
            setError("Choose an image");
            return;
        }

        if(title === ""){
            setError("Choose a title for your photo");
            return;
        }
        setUploading(true);
        //resize image
        const imgElement = document.createElement('img');
        imgElement.src = preview;
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext('2d');
        const imgSize = 2048;

        let width = imgSize;
        let height = imgSize;

        if (imgElement.naturalWidth > imgElement.naturalHeight) {
            canvas.width = imgSize;
            canvas.height = imgSize * (imgElement.naturalHeight / imgElement.naturalWidth);
            height = canvas.height;
        } else {
            canvas.width = imgSize * (imgElement.naturalWidth / imgElement.naturalHeight);
            canvas.height = imgSize;
            width = canvas.width;
        }

        ctx.drawImage(imgElement, 0, 0, imgElement.naturalWidth, imgElement.naturalHeight, 0, 0, width, height);

        canvas.toBlob((imgData) => {
            const data = new FormData();
            data.append('formFiles', imgData);
            data.append('formFiles', previewCrop);

            props.submit({
                group: Number(props.group),
                title: title,
                value: data
            }).then((result) => {
                setTitle("");
                    setImage();
                    setPreview();
                if (result !== undefined) navigate(props.return);
                else setError(true);
            });
        }, "image/jpeg");
    }

    const imgPreview = (
        <div className='preview'>
            <canvas className='preview-full' ref={canvasRef}>
                <img src={preview} alt="preview" />
            </canvas>
        </div>
    )

    const errorClass = error === "" ? "upload" : "upload error";
    const errorText = error === true 
    ? <p className='error-message'>Error uploading photo, please try again.</p>
    : <></>;

    if (props.hasCase) text = <p>Add a photo of something you want to explore with {props.case.childName.first} when you see them.</p>;
    
    else {
        switch (Number(props.group)) {
            case StuffGroup.WhoIAm:
                text =
                    <p>
                        Add a photo that represents your identity.<br /><br />
                        For example, your likes and dislikes, how you're feeling, who or what's important to you.
                    </p>;
                break;
            case StuffGroup.GetThere:
                text =
                    <p>
                        Add a photo that represents the steps needed to reach your goals and become the best you!
                    </p>;
                break;
            case StuffGroup.WantToBe:
                text =
                    <p>
                        Add a photo that represents the type of person you would like to be in the future.<br /><br />
                        Maybe a job you would like to do, someone you look up to or how you want others to see you.
                    </p>;
                break;
            default:
                text = <p>Add a photo to My Stuff.</p>
                break;
        }
    }
    
    const style = props.hasCase ? "content-case" : "content";

    if(image === undefined){
        return (
            <div className={style}>
                <BackButton to={props.return} />
                <h1>Upload photo</h1>
                <div className={errorClass}>
                    {text}
                    {errorText}
                    <div className="button-container">
                        <label className="button button-upload button-square-secondary">
                            Upload a new photo
                            <input type="file" accept="image/*" onChange={onSelectFile} />
                        </label>
                    </div>
                </div>
            </div>
        )
    }

    if(uploading){
        return (
            <div className={style + " upload"}>
                <BackButton to={props.return}/>
                <h1>Upload photo</h1>
                <Loader/>
            </div>
        )
    }

    return (
        <div className={style}>
            <BackButton to={props.return}/>
            <h1>Upload photo</h1>
            <div className={errorClass}>
                {image && imgPreview}
                <label>
                    Choose a title for your photo
                    <input type="textbox" value={title} onChange={(e) => setTitle(e.target.value)} />
                    <span className='error-message'>{error}</span>
                </label>
                <br />
                <div className='buttons'>
                    <button className="button" onClick={submit}>Save photo</button>
                    <button className="button button-secondary" onClick={() => setImage(undefined)}>Cancel</button>
                </div>
                <ImageCrop show={modal} src={preview} aspectRatio={aspectRatio} upload={cropImage} cancel={() => {setImage(undefined); setModal(false);}} />
            </div>
        </div>
    );
}

export { AddStuff, AddGroupStuff, AddText, AddLink, AddEmoji, AddImage }