import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Format } from '../../shared/dates'
import { Pager } from '../../shared/layout/Layout';
import * as Data from './Data';
import { CaseContext } from '../cases/Cases';
import './Appointments.scss'
import { nameFormatter } from '../child/Common';
import BackButton from '../../shared/navigation';

function NextAppointment(next, caseContext) {
    const formattedDate = Format(next.occurs);
    const childName = next.childName;

    let nextTitle = (caseContext.case == null)
        ? "Your next appointment is with"
        : (nameFormatter(caseContext.case.childName.first) + " next appointment is");
    
    let header = (caseContext.case == null)
        ? (
            <>
                <h2>
                    {childName.first} {childName.last} <br />
                </h2>
                <h2 className='next-date'>{formattedDate.day}</h2>
            </>
        )
        : <h2 className='next-date'>{formattedDate.day}</h2>;

        return (
        <div className='next'>
            <p>{nextTitle}</p>
            <Link to={next.id}>
                {header}
                <p>
                    {formattedDate.time} {(next.location)}
                </p>
            </Link>
        </div>
    )
}

function UpcomingAppointments(appointments) {
    const formattedDate = Format(appointments.occurs);
    const childName = appointments.childName;

    return (
        <li key={appointments.id}>
            <Link to={appointments.id}>
            <span className='child-name'>{childName.first} {childName.last}</span>
            <br />
            {formattedDate.day}, {formattedDate.time}
            <br />
            {appointments.location}
            </Link>
        </li>
    )
}

function PastAppointments(appointments) {
    const formattedDate = Format(appointments.occurs);
    const childName = appointments.childName;

    return (
        <li key={appointments.id}>
            <Link to={appointments.id}>
            <span className='child-name'>{childName.first} {childName.last}</span>
            <br />
            {formattedDate.day}, {formattedDate.time}
            <br />
            {appointments.location}
            </Link>
        </li>
    )
}

function CaseUpcomingAppointments(appointments) {
    const formattedDate = Format(appointments.occurs);

    return (
        <li key={appointments.id}>
            <Link to={appointments.id}>
            {formattedDate.day}, {formattedDate.time}
            <br />
            {appointments.location}
            </Link>
        </li>
    )
}

function CasePastAppointments(appointments) {
    const formattedDate = Format(appointments.occurs);

    return (
        <li key={appointments.id}>
            <Link to={appointments.id}>
            {formattedDate.day}, {formattedDate.time}
            <br />
            {appointments.location}
            </Link>
        </li>
    )
}

function None() {
    return (
        <p>You have no appointments</p>
    )
}

function NoneCase(caseContext) {
    return (
        <p>You have no appointments with {caseContext.case.childName.first}.</p>
    )
}

function NoUpcoming() {
    return (
        <li>
            No appointments
        </li>
    )
}

function Appointments() {
    let caseContext = useContext(CaseContext);
    let hasCase = caseContext.case != null;

    const [nextAppointment, setNextAppointment] = useState(undefined);

    useEffect(() => {
        if(hasCase){
            Data.GetNextAppointmentChildId(caseContext.case.childId).then(data => setNextAppointment(data));
        }else{
            Data.GetNextAppointment().then(data => setNextAppointment(data));
        }
    }, [caseContext, hasCase]);

    let getAppointments;
    let getMaxAppointments;

    let getPastAppointments;
    let getPastMaxAppointments;

    if (hasCase){
        getAppointments = (count, page) => Data.GetAppointmentsChildId(count, page, caseContext.case.childId);
        getMaxAppointments = () => Data.GetMaxAppointmentsChildId(caseContext.case.childId);

        getPastAppointments = (count, page) => Data.GetPastAppointmentsChildId(count, page, caseContext.case.childId);
        getPastMaxAppointments = () => Data.GetMaxPastAppointmentsChildId(caseContext.case.childId);
    }else{
        getAppointments = (count, page) => Data.GetAppointments(count, page);
        getMaxAppointments = () => Data.GetMaxAppointments();

        getPastAppointments = (count, page) => Data.GetPastAppointments(count, page);
        getPastMaxAppointments = () => Data.GetMaxPastAppointments();
    }

    let title = hasCase
        ? (nameFormatter(caseContext.case.childName.first) + " appointments")
        : "Appointments";


    let appointmentsClass = hasCase ? "appointments appointments-case content-case" : "appointments content";
    let upcomingClass = hasCase ? "upcoming upcoming-case" : "upcoming";
    let pastClass = hasCase ? "past past-case" : "past";

    let NextComponent = nextAppointment === undefined 
    ? hasCase ? () => NoneCase(caseContext): None : NextAppointment;

    let upcomingAppointments = hasCase ? CaseUpcomingAppointments : UpcomingAppointments;
    let pastAppointments = hasCase ? CasePastAppointments : PastAppointments;

    return (
        <div className={appointmentsClass}>
            <BackButton />
            <h1>{title}</h1>
            <Link to="create" className="button">Add appointment</Link>
            {NextComponent(nextAppointment, caseContext)}
            <hr />
            <h2>Upcoming appointments</h2>
            <Pager maxFunction={getMaxAppointments} data={getAppointments} count={5} empty={NoUpcoming} mapItems={upcomingAppointments} className={upcomingClass}/>
            <h2>Past appointments</h2>
            <Pager maxFunction={getPastMaxAppointments} data={getPastAppointments} count={5} empty={NoUpcoming} mapItems={pastAppointments} className={pastClass}/>
        </div>
    )
}

export { Appointments };
