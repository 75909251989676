import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SecureImage from '../auth/SecureImage';
import { LoaderPage, showError } from '../layout/Layout';
import BackButton from '../navigation';
import './messages.scss';
import Scrollbars from 'react-custom-scrollbars-2';
import { ConversationCaseCheck } from './Data';

const ProfileUrl = (item) => {
    return "media/profile-picture/" + item.senderId;
}

const BuildTimeStamp = (date) => {
    const msgDate = new Date(date);
    const dateNow = new Date();

    const formattedDate = msgDate.toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' });
    const time = msgDate.toLocaleTimeString('en-GB', { timeStyle: 'short', hourCycle: 'h12'});

    if (msgDate.toLocaleDateString() === dateNow.toLocaleDateString()) return time;
    return formattedDate;
}

function AddConversation(props) {
    return (
        <Formik
            initialValues={{subject: "", message: ""}}
            validationSchema={Yup.object({
                subject: Yup.string().max(50, "Must be fewer than 50").required("Subject required"),
                message: Yup.string().required("Message required")
            })}
            onSubmit={(values) => {
                props.onSubmit(values);
            }}
            >
                {(data) => {
                    let error = showError.bind(this, data);
                    return (
                        <Form className='messages messages-create'>
                            <label className={error("subject")}>
                                Subject
                                <Field name="subject" placeholder='Type your subject here' />
                                <br/>
                                <span className='error-message'><ErrorMessage name="subject" /></span>
                            </label>
                            <label className={error("message")}>
                                Message
                                <Field name="message" as="textarea" placeholder='Type your message here' />
                                <br/>
                                <span className='error-message'><ErrorMessage name="message" /></span>
                            </label>
                            <div className='send'>
                                <button type="submit" className="button-square">Send</button>
                            </div>
                        </Form>
                    )
                }}
        </Formik>
    )
}

function NoMessage() {
    return <li>No messages have been sent or received.</li>
}

function NoResults() {
    return <li className='conversation-list-item'>No results </li>
}

function AddMessage(props) {
    const connection = props.connection;
    const messageTimeout = 1 * 1000; // Milliseconds

    const input = props.inputDisabled 
    ? 
        <>
            <input readOnly placeholder='Type your reply here'/>
        </>
    :
        <>
            <Field name="message" placeholder='Type your reply here' />
            <span className='error-message'><ErrorMessage name="message" /></span>
        </>;

    return (
        <Formik
            initialValues={{message: ""}}
            validationSchema={Yup.object({
                message: Yup.string().required("Enter a reply")
            })}
            onSubmit={(values, { resetForm }) => {
                if (props.disabled === true) return;
                props.setDisabled(true);
                if (connection.state !== 'Disconnected') {
                    connection.invoke("SendMessage", props.topicId, values.message);
                    // rely on being sent the message back from the server
                    // to display it in the list here
                    // this is where we can set pending status etc.
                } else {
                    props.addMessage(props.topicId, values.message).then(r => {
                        props.append(r);
                    });
                }
                resetForm();
                setTimeout(() => props.setDisabled(false), messageTimeout);
            }}
        >
            {(data) => {
                let error = showError.bind(this, data);
                return (
                    <Form className="messages-add">
                        <label className={error("message")}>
                            {input}
                        </label>
                        <button type="submit" disabled={props.disabled} className="button-square button-minimum">Send</button>
                    </Form>
                )
            }}
        </Formik>

    )
}

function ListConversation(props) {
    return (
        <div className='messages'>
            <div>
                <Link to="create" className='button'>Message {props.name}</Link>
            </div>
            {props.children}
        </div>
    )
}

function ListItem(convo) {
    const unread = convo.unread ? "unread" : "";
    return (
        <li key={convo.topic.id} className={"conversation-list-item clickable " + unread}>
            <Link to={convo.topic.id}>
                <span className="info">
                    <b>{convo.topic.subject}</b>
                    <br />
                    <span className='preview'>
                        {convo.messages[0].content}
                    </span>
                    <span className='arrow'>(&gt;)</span>
                    <span className='timestamp'>{BuildTimeStamp(convo.lastMessage)}</span>
                </span>
            </Link>
        </li>
    )
}

function ConvoClosedItem(item) {
    return (
        <li key="old-message-alert" className='item closed'>
            <div className='text'>
                {item.content}
            </div>
        </li>
    )
}

function OwnItem(item, target, reference) {
    const profileUrl = ProfileUrl(item);
    let ref = target ? reference : undefined;

    return (
        <li key={item.messageId} className='item mine' ref={ref}>
            <div className='text'>
                {item.content}
            </div>
            <SecureImage className='profile' url={profileUrl} alt="my profile picture"/>
            <span>{BuildTimeStamp(item.sent)}</span>
        </li>
    )
}

function TargetItem(item, target, reference) {
    const profileUrl = ProfileUrl(item);
    let ref = target ? reference : undefined;

    return (
        <li key={item.messageId} className='item target' ref={ref}>
            <SecureImage className='profile' url={profileUrl} alt="target profile picture"/>
            <div className='text'>
                {item.content}
            </div>
            <span>{BuildTimeStamp(item.sent)}</span>
        </li>
    )
}

function Conversation(props) {
    const scrollRef = useRef(null);
    const scrollTopRef = useRef(null);
    const scrollMidRef = useRef(null);

    const [topic, setTopic] = useState(undefined);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);

    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [inputDisabled, setInputDisabled] = useState(false);
    const [oldMessageShow, setOldMessageShow] = useState(false);

    const count = 5;
    const [scrollDownCounter, setScrollDownCounter] = useState(0);
    const [scrollMidCounter, setScrollMidCounter] = useState(0);

    const connection = props.connection;
    const userId = props.userId;

    const listClassname = !props.hasCase? "messages-list" : "messages-list messages-list-case";

    const appendMessage = (msg) => {
        setMessages([...messages, msg]);
    }

    useEffect(() => {
        setLoading(true);
        props.getConversation(15, 0).then(conv => {
            setTopic(conv.topic)
            setMessages(conv.messages);
            setLoading(false);
            setScrollDownCounter(val => val + 1);

            ConversationCaseCheck(conv.topic.childId, conv.topic.practitionerId).then(bool => {
                setButtonDisabled(!bool);
                setInputDisabled(!bool);

                if (bool === true) {
                    if (connection.state !== 'Disconnected') return;
                    connection.start();

                    connection.on("ReceiveMessage", (newMsg) => {
                        if (newMsg.topicId === conv.topic.id) {
                            setMessages((msgs) => {
                                setScrollDownCounter(val => val + 1);
                                return [...msgs, newMsg];
                            })
                        }
                    });
                }
            })
        })
    }, [props, connection, count]);

    useEffect(() => {
        if (scrollRef !== null && scrollRef.current !== null)
            scrollRef.current.scrollIntoView();
    }, [scrollDownCounter])

    useEffect(() => {
        if (scrollMidRef !== null && scrollMidRef.current !== null) {
            scrollMidRef.current.scrollIntoView();
        }
    }, [scrollMidCounter])

    useEffect(() => {
        if (buttonDisabled && inputDisabled && !oldMessageShow) {
            setMessages([...messages, {content: "You cannot reply to conversations with this user because you are no longer assigned to them.", old: true}]);
            setOldMessageShow(true);
        }
    }, [messages, buttonDisabled, inputDisabled, oldMessageShow])

    const data = messages.map((m, i) => {
        if (m.old) {
            return ConvoClosedItem(m);
        } 
        if (m.senderId.toLowerCase() === userId.toLowerCase()) {
            return OwnItem(m, i === count, scrollMidRef);
        } else {
            return TargetItem(m, i === count, scrollMidRef);
        }
    })

    const scroll = (conv) => {
        if (conv.messages.length === 0) return;
        setMessages((msgs) => {
            const temp = [...conv.messages];
            temp.push(...msgs);
            setScrollMidCounter(scrollMidCounter + 1);
            return temp;
        })
    }

    const onScroll = () => {
        if (scrollTopRef.current !== null && scrollTopRef.current.getScrollTop() === 0) {
            if (connection.state !== 'Connected') data.getConversation(count, messages.length).then(scroll);
            else connection.invoke("GetConversation", topic.id, count, messages.length).then(scroll);
        }
    }

    const addMessage = () => {
        props.AddMessage(...arguments);
        setScrollDownCounter(scrollDownCounter + 1);
    }

    const className = props.hasCase ? "content-case" : "content-minimal";

    if (loading) {
        return (
            <LoaderPage />
        )
    }

    return (
        <div className={'messages ' + className}>
            <div className="messages-header">
                <BackButton to="/messages" />
                <h1>{topic.subject}</h1>
            </div>
            <Scrollbars className={listClassname} ref={scrollTopRef} onScroll={onScroll}>
                <ul>
                    {data}
                    <li className='scroll-target' ref={scrollRef}></li>
                </ul>
            </Scrollbars>
            <AddMessage topicId={topic.id} connection={connection} append={appendMessage} addMessage={addMessage} disabled={buttonDisabled} setDisabled={setButtonDisabled} inputDisabled={inputDisabled}/>
        </div>
    )
}

export { Conversation, NoMessage, BuildTimeStamp, AddConversation, ListConversation, ListItem, NoResults }