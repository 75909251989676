const NotFound = function(){
    return(
    <div className="content">
        <h1>Not Found</h1>
        <p>
            The requested page was not found.
        </p>
    </div>)
}

export default NotFound;