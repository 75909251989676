import api from '../../shared/api.js'

const GetCases = (count, offset) => {
    return api.getPaged("cases", count, offset);
}

const GetMaxCases = () => {
    return api.get("cases/max");
}

const GetCase = (id) => {
    return api.get("cases/" + id)
}

const GetCasePractitioners = (id) => {
    return api.get("cases/" + id + "/practitioners")

}

const SearchCases = (search) => {
    return api.get("cases/search?name=" + search);
}

const InviteCase = (invite) => {
    return api.post("cases/invite", invite);
}

const ResendInvite = (id) => {
    return api.post("cases/" + id + "/resend")
}

const GetCaseTotals = () => {
    return api.get("notifications/case/totals");
}

const EditCase = (id, update) => {
    return api.put("cases/" + id + "/edit", update);
}

const SearchChildUsernames = (search) => {
    return api.get("cases/username/search?username=" + search);
}

export { GetCases, GetMaxCases, GetCase, GetCasePractitioners, SearchCases, InviteCase, ResendInvite, GetCaseTotals, EditCase, SearchChildUsernames };