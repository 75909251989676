import { useState } from 'react';
import { Logout } from '../auth/Auth';
import { AcceptTerms } from './Data'
import { TermsContent } from './Disclaimers';
import jwt_decode from 'jwt-decode';
import { Resendtoken } from '../../shared/auth/Data';
import SetAuthContext from '../../shared/auth/Auth';

const Terms = function (props) {
    const [logout, setLogout] = useState(false);

    const jwt = sessionStorage.getItem("jwt");
    var token = jwt_decode(jwt);
    
    const accept = () => {
        AcceptTerms().then(a => {
            props.setTerms(true);
            Resendtoken(token.username).then((r) => {
                SetAuthContext(r);
            })
        })
    }
    const declineClick = () => {
        setLogout(true);
    }

    if(logout){
        return <Logout />
    }

    return (
        <div className="content">
            <h1>Terms of use</h1>
            <p>
                You'll need to accept these terms of use before you can start to use the Connect app.
                Read them carefully, then click 'Accept' at the end of this page.
            </p>
            <TermsContent />
            <br />
            <button className='button-max' onClick={accept}>Accept</button>
            <button className='button-secondary button-max' onClick={declineClick}>Decline</button>
        </div>)
}

export default Terms;