import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import * as Data from './Data';
import { CaseContext } from '../../../cases/Cases';
import { CompleteGoalButton, GoalContext } from '../Goals'
import { GoalType } from '../../../../shared/goals/Goals';
import * as SharedTasks from '../../../../shared/goals/tasks/Tasks';
import './tasks.scss'
import BackButton from '../../../../shared/navigation';
import TaskCard from './TaskCard';
import DnD from '../../../../shared/DnD';
import { LoaderPage } from '../../../../shared/layout/Layout';
import { GetChildGoal } from '../Data';
import { NotificationContext } from '../../../notifications/Notifications';
import { ItemArea } from '../../../../shared/Events';
import { Format } from '../../../../shared/dates';
import { ModalContext } from '../../../../shared/Modals';

const AddPractitionerTask = () => {
    let goalId = useContext(GoalContext);
    let caseContext = useContext(CaseContext);
    let modalContext = useContext(ModalContext);
    const hasCase = caseContext.case != null;
    
    let childId = hasCase ? caseContext.case.childId : "";
    const navigate = "/child/goals/" + goalId + "/tasks";

    const addTask = (task) => {
        return Data.CreateTaskPractitioner(childId, goalId, task);
    }

    return <SharedTasks.Add addTask={addTask} navigate={navigate} text="Task added" header="Add a new task" hasCase={hasCase} modalContext={modalContext} />
}

const EditPractitionerTask = () => {
    let goalId = useContext(GoalContext);
    let caseContext = useContext(CaseContext);
    let modalContext = useContext(ModalContext);
    const hasCase = caseContext.case != null;
    let childId = hasCase ? caseContext.case.childId : "";
    const navigate = "/child/goals/" + goalId + "/tasks";

    const getTask = (id) => {
        return Data.GetTaskPractitioner(childId, goalId, id);
    }
    const deleteTask = (id) => {
        Data.DeleteTaskPractitioner(childId, goalId, id);
    }
    const editTask = (id, update) => {
        return Data.EditTaskPractitioner(childId, goalId, id, update);
    }

    if (!hasCase) return <></>

    return (
        <>
            <SharedTasks.Edit editTask={editTask} deleteTask={deleteTask} getTask={getTask} title="Edit task" modalContext={modalContext} navigate={navigate} />
        </>
    )
}

function Tasks() {
    const caseContext = useContext(CaseContext);
    const modalContext = useContext(ModalContext);
    const notificationContext = useContext(NotificationContext);

    const hasCase = caseContext.case != null;
    const childId = hasCase ? caseContext.case.childId : "";
    const goalId = useContext(GoalContext);

    const [goal, setGoal] = useState(undefined);
    const [pendingTasks, setPendingTasks] = useState([]);
    const [activeTasks, setactiveTasks] = useState([]);
    const [rejectedTasks, setRejectedTasks] = useState([]);

    useEffect(() => {
        if (caseContext.case === undefined) return;
        (async () => {
            const data = await Data.GetTasksPractitioner(childId, goalId);
            const goal = await GetChildGoal(childId, goalId);
            let pending = data.filter(task => task.status === SharedTasks.TaskType.Pending).sort((a, b) => parseInt(a.order) - parseInt(b.order));
            let active = data.filter(task => task.status === SharedTasks.TaskType.Approved).sort((a, b) => parseInt(a.order) - parseInt(b.order));
            let rejected = data.filter(task => task.status === SharedTasks.TaskType.Rejected).sort((a, b) => parseInt(a.order) - parseInt(b.order));
            setactiveTasks(active);
            setPendingTasks(pending);
            setRejectedTasks(rejected);
            setGoal(goal);
        })();
    }, [goalId, childId, caseContext.case]);

    useEffect(() => {
        notificationContext.readNotification(ItemArea.Tasks);
    }, [notificationContext]);

    if (goal === undefined) {
        return <LoaderPage />
    }

    if (hasCase === false) {
        return (
            <div className='content'>
                <h1>No Case selected</h1>
                <Link to="/cases">Return to cases</Link>
            </div>
        )
    }

    function Suggested(task, setStatus) {
        const approveTask = () => {
            Data.ChangeTaskStatusPractitioner(childId, goalId, task.id, 2);
            setStatus(task, SharedTasks.TaskType.Approved);
        }
        const denyTask = () => {
            Data.ChangeTaskStatusPractitioner(childId, goalId, task.id, 1);
            setStatus(task, SharedTasks.TaskType.Rejected);
        }
        return (
            <div key={task.id} >
                <div className="task-container">
                    <input type="checkbox" disabled="disabled" />
                    <b>{task.title}</b>
                    <div className='suggest-task'>
                        <Link to={task.id}><span className="links clickable">Edit</span></Link> |
                        <span className="links clickable" onClick={approveTask}>Approve</span> |
                        <span className="links clickable" onClick={denyTask}>Deny</span>
                    </div>
                </div>
            </div>
        )
    }


    function RejectedTasks(task) {
        return (
            <div key={task.id}>
                <b>{task.title}</b>
            </div>
        )
    }

    let activeUpdate = Data.ChangeTaskOrderPractitioner.bind(this, childId, goalId);

    const FetchCard = (props) => {
        let taskcard = TaskCard({
            goalId: goalId,
            ...props
        })
        return taskcard;
    }

    const DnDTasks = () => {
        const data = activeTasks.length === 0 ? <span>There are no active tasks</span> : <DnD array={activeTasks} component={FetchCard} update={activeUpdate} class="list-container"/>;

        return (
            <div className="list">
                <h2>Active tasks</h2>
                <div>
                    {data}
                </div>
            </div>

        )
    }

    function DisplayTasks(props) {
        const pendingData = (props.pending !== undefined && props.pending.length !== 0)
            ? props.pending
            : <div>There are no suggested tasks</div>;

        const pending = (
            <div className="list">
                <h2>Suggested tasks</h2>
                <div className='list-container'>
                    {pendingData}
                </div>
            </div>
        )

        const rejectedData = (props.rejected !== undefined && props.rejected.length !== 0)
            ? props.rejected
            : <div>There are no rejected tasks</div>;

        const rejected = (
            <div className="list">
                <h2>Rejected tasks</h2>
                {rejectedData}
            </div>
        )

        return (
            <div className='list-container'>
                {pending}
                {props.children}
                {rejected}
            </div>
        )
    }

    const refreshStatus = (task, status) => {
        if (status === SharedTasks.TaskType.Approved) {
            setactiveTasks(a => {
                let n = [task, ...a];
                return n;
            });
        }
        else if (status === SharedTasks.TaskType.Rejected) {
            setRejectedTasks(a => {
                let n = [task, ...a];
                return n;
            });
        }
        setPendingTasks(p => {
            let n = p.filter(pp => pp.id !== task.id);
            return n;
        });
    }

    const pendingList = pendingTasks.map((task) => {
        return Suggested(task, refreshStatus);
    })

    const rejectedList = rejectedTasks.map((task) => {
        return RejectedTasks(task);
    });

    const addButton = ([GoalType.Pending, GoalType.Approved].indexOf(goal.status) >= 0) ? <Link to="add"><button className='button-max'>Add a new task</button></Link> : null;

    return (
        <div className='tasks tasks-case'>
            <div className="tasks-list content-case">
                <div>
                    <BackButton to="/child/goals" />
                    <h1>{goal.title}</h1>
                </div>
                <span className='date'>Due: {Format(goal.deadline).dayYear}</span>
                <p>{goal.description}</p>
                <DisplayTasks pending={pendingList} rejected={rejectedList}>
                    <DnDTasks />
                </DisplayTasks>
            </div>
            <div className="buttons">
                {addButton}
                <CompleteGoalButton modalContext={modalContext}/>
            </div>
        </div>
    )
}
export { Tasks, AddPractitionerTask, EditPractitionerTask };