import React, { useContext, useEffect } from 'react';
import { Cases, CaseContext } from "../cases/Cases";
import './homepage.scss';
import { AuthContext } from '../auth/Auth';
import ProfileHeader from '../../shared/ProfileHeader';
import { NickNameGenerator } from '../../shared/layout/Layout';

function Homepage() {
    const auth = useContext(AuthContext);
    const name = auth.user().name;

    let caseContext = useContext(CaseContext);
    useEffect(()=>{ caseContext.clearCase(); },[caseContext])
    
    const profileUrl = "practitioner/profile-picture?t=" + Math.floor(Math.random() * 100);

    return (
        <div className='homepage'>
            <ProfileHeader profileUrl={profileUrl}/>
            <div className="user-name">
                <span>
                    <b>{name.first} {NickNameGenerator(name.nickname)} {name.last}</b>
                </span>
            </div>
            <Cases route="cases/invite"/>
        </div>
    );
}

export default Homepage;