import React, { useEffect, useState, useContext } from 'react';
import { CreateDate } from '../../shared/dates';
import BackButton from '../../shared/navigation';
import AppointmentsForm from './AppointmentsForm';

import * as Data from './Data';
import { CaseContext } from '../cases/Cases';
import { GetCases } from '../cases/Data';

import './Appointments.scss';
import { ModalContext } from '../../shared/Modals';


function AppointmentsAdd(props) {
    const caseContext = useContext(CaseContext);
    const modalContext = useContext(ModalContext);
    const hasCase = caseContext.case != null;

    const [children, setChildren] = useState(undefined);
    const [childIdSelect, setChildIdSelect] = useState("");
    const [childNameSelect, setChildNameSelect] = useState("");
    const [error, setError] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        GetCases().then((data => {
            var object = {};
            data.forEach(element => {
                object[element.childId] = element;
            });
            setChildren(object);
        }));
    }, []);


    const changeChild = (e) => {
        const childId = e.target.value;
        setChildNameSelect(children[childId].childName.first);
        setChildIdSelect(children[childId].childId);
        setError(false);
    }

    let onSubmit = (data) => {
        setShow(true);
        if(error) {
            setShow(false);
            return;
        } 
        const childId = hasCase ? caseContext.case.childId : childIdSelect;
        if (childId === "") {
            setError(true);
            setShow(false);
            return;
        };

        const occurs = CreateDate(data.date, data.time);
        const request = {
            "childId": childId,
            "location": data.location,
            "occurs": occurs
        }

        Data.CreateAppointment(request).then(() => {
            setShow(false);
            modalContext.setShowTextNavigate("Appointment added", "/appointments"); 
        });
    }

    const SelectChildren = (c) => {
        return (
            <option key={c.childId} value={c.childId}>{c.childName.first} {c.childName.last}</option>
        );
    }

    const selectList = [];
    for (const child in children) {
        selectList.push(SelectChildren(children[child]));
    }

    const options = (
        <select onChange={changeChild} defaultValue="children">
            <option value="children" disabled> Select a Child </option>
            {selectList}
        </select>
    );
    
    let title = "Add an appointment";
    if(hasCase){
        title = "Add an appointment for " + caseContext.case.childName.first
    }
    else if(childIdSelect !== ""){
        title = "Add an appointment for " + childNameSelect;
    }

    let selectError = () => {
        if(hasCase) return "";
        if(error) return "error"
        return "";
    }

    let selectMsg = () => {
        return error ?  "A child must be selected" : "";
    }

    let childSelect = null;
    if (hasCase === false) {
        childSelect = (
            <label className={selectError()}>
                Child
                <br />
                {children === undefined ? <div>Loading...</div> : options}
                <br/>
                <span className='error-message'>{selectMsg()}</span>
            </label>
        )
    }

    const style = hasCase ? "content-case" : "content";

    return (
        <div className={style}>
            <div className='appointments-add'>
            <BackButton to="/appointments" />
                <h1>{title}</h1>
                {childSelect}
            </div>
            <AppointmentsForm onSubmit={onSubmit} show={show}/>
        </div>
    );
}

export { AppointmentsAdd };
