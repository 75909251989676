import { useState } from "react";

import Debounce from "./Debounce";
import * as Icon from "./Icons";

const SearchBar = (props) => {
    const [value, setValue] = useState("");

    const setSearchQuery = Debounce((e) => {
        props.setSearch(e.target.value.trim());
    });

    const setSearchValue = (e) => {
        setValue(e.target.value);
    }

    const clearSearchValue = () => {
        setValue("");
        props.setSearch("".trim());
    }

    const clearButton = (
        <span className="clear" onClick={clearSearchValue}>
            <Icon.Close />
        </span>
    )

    const clear = value ? clearButton : <></>;

    return (
        <div className="search-container">
            <input className="search" value={value} placeholder="Search" onKeyUp={setSearchQuery} onChange={setSearchValue} />
            {clear}
        </div>
    )
}

export default SearchBar;