import api from "../../../shared/api";

const idFrag = (childId) => {
    return "child/" + childId + "/mystuff/";
}

function GetMyStuff(childId, group, count, offset) {
    return api.getPaged(idFrag(childId) + group, count, offset);
}

function GetStuffList(childId, ids) {
    return api.post(idFrag(childId) + "stuff/list", ids);
}

function SubmitStuff(childId, stuff){
    return api.post(idFrag(childId) + stuff.Group + "/" + stuff.Type, stuff)
}

const UploadImage = (group, image, childId) => {
    return api.formJson(idFrag(childId) + group + "/upload", image);
}

const DeleteStuff = function(childId, ids) {
    return api.postOnly(idFrag(childId) + "stuff/delete", ids);
}

const ExportStuff = (childId) => {
    return api.postBlob("child/" + childId + "/mystuff/download");
}

export { GetMyStuff, GetStuffList, SubmitStuff, UploadImage, DeleteStuff, ExportStuff };