import { useNavigate } from "react-router-dom";
import * as Icon from "./Icons";

function BackButton(props) {
    let navigate = useNavigate();
    let destination = props.to;
    let target = -1;

    if(destination !== undefined && destination !== ''){
        target = destination;
    }

    return (
        <div className="back-container" onClick={() => navigate(target)}>
            <Icon.BackArrow />
        </div>
    )
}

export default BackButton;