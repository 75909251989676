import { useContext, useState } from "react";
import { Link } from "react-router-dom"
import { CaseContext } from "../../../cases/Cases";
import { EditTaskPractitioner } from "./Data";

function TaskCard(props) {
    let caseContext = useContext(CaseContext);
    const hasCase = caseContext.case != null;
    let childId = hasCase ? caseContext.case.childId : "";

    const [complete, setComplete] = useState(props.card.complete);
    const opacity = props.isDragging ? 0.5 : 1;

    const changeComplete = () => {
        EditTaskPractitioner(childId, props.goalId, props.id, {
            title: props.text,
            complete: !complete
        });
        setComplete(!complete);
    }

    return (
        <div>
            <label ref={props.cardRef} className="task-container" style={{ ...props.styleCard, opacity }} data-handler-id={props.handlerId}>
                <input type="checkbox" checked={complete} onChange={changeComplete}></input>
                <b>{props.text}</b>
                <Link to={props.id}><span className="links clickable">Edit</span></Link>
            </label>
        </div>
    )
}

export default TaskCard;