import { useState, useEffect, useContext, createContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Data from './Data';
import { CaseContext } from '../../cases/Cases';
import { nameFormatter } from '../Child';
import * as GoalsData from '../../../shared/goals/Goals';
import { GoalType } from '../../../shared/goals/Goals';
import './goals.scss'
import GoalCard from './GoalCard';
import DnD from '../../../shared/DnD';
import BackButton from '../../../shared/navigation';
import { Loader, Pager } from '../../../shared/layout/Layout';
import * as Icon from '../../../shared/Icons';
import { NotificationContext } from '../../notifications/Notifications';
import { ItemArea } from '../../../shared/Events';
import { CreateDate, Format } from '../../../shared/dates';
import { ModalContext } from '../../../shared/Modals';

const GoalContext = createContext("");
GoalContext.displayName = "GoalId";
const childGoalsPath = "/child/goals";

const AddPractitionerGoal = (props) => {
    const [show, setShow] = useState(false);
    let caseContext = useContext(CaseContext);
    let modalContext = useContext(ModalContext);
    let hasCase = caseContext.case != null;

    const addGoal = (goal) => {
        setShow(true);
        let childId = caseContext.case.childId;
        let date = CreateDate(goal.deadline);

        const request = {
            "deadline": date,
            "description": goal.description,
            "title": goal.title
        }

        Data.CreateGoalChildId(childId, request).then(() => {
            setShow(false);
            modalContext.setShowTextNavigate("Goal added", childGoalsPath);
        });
    }

    const className = hasCase ? "content-case" : "content";

    if (!hasCase) {
        return (
            <div className='content'>
                <h1>No case selected</h1>
                <Link to="/cases">Return to cases</Link>
            </div>
        )
    }

    return (
        <div className={'goals ' + className}>
            <BackButton to="/child/goals" />
            <div>
                <h1>Add a new goal</h1>
            </div>
            <GoalsData.EnterDetails title="" desc="" deadline="" text="Submit" submit={addGoal} show={show}/>
        </div>
    )
}

const EditPractitionerGoal = () => {
    let caseContext = useContext(CaseContext);
    const hasCase = caseContext.case != null;
    let childId = hasCase ? caseContext.case.childId : "";

    const getGoal = (id) => {
        return Data.GetChildGoal(childId, id);
    }
    const editGoal = (id, update) => {
        return Data.EditGoalChildId(childId, id, update);
    }

    if (!hasCase) return <></>

    return (
        <div>
            <Edit editGoal={editGoal} editButton="Save" getGoal={getGoal} navigate={childGoalsPath} />
        </div>
    )
}

function Edit(props) {
    let [goal, setGoal] = useState(undefined);
    const [show, setShow] = useState(false);
    const params = useParams();
    const id = params.id;
    let caseContext = useContext(CaseContext);
    let modalContext = useContext(ModalContext);
    let childId = caseContext.case.childId;

    useEffect(() => {
        props.getGoal(id)
            .then(data => {
                const date = Format(data.deadline);

                let goal = {...data, deadline: date.input};
                setGoal(goal);
            });
    }, [id, props]);

    const onEdit = (update) => {
        setShow(true);
        props.editGoal(id, update).then((data) => {
            setGoal(data);
            setShow(false);
            modalContext.setShowTextNavigate("Goal updated", props.navigate);
        });
    }

    const onDelete = async () => {
        await Data.DeleteGoalChildId(childId, id);
        modalContext.setShowTextNavigate("Goal deleted", props.navigate);
    }

    if (goal === undefined) return <div></div>;

    return (
        <div className='content goals'>
            <BackButton to="/child/goals" />
            <div>
                <h1>Edit goal</h1>
            </div>
            <div>
                <GoalsData.EnterDetails title={goal.title} desc={goal.description} deadline={goal.deadline} text={props.editButton} submit={onEdit} delete={onDelete} show={show}/>
            </div>
        </div>
    )
}


function Section(props) {
    if (props.loading) {
        return (
            <div className="list">
                <h2>{props.title}</h2>
                <Loader />
            </div>)
    }
    let data = props.goals.length === 0 ? <span>There are no suggested goals</span> : props.goals;
    return (
        <div className="list">
            <h2>{props.title}</h2>
            {data}
        </div>
    );
}

function Paged(props) {
    return (
        <div className="list">
            <h2>{props.title}</h2>
            <Pager {...props} />
        </div>
    );

}

function ActiveGoals(props) {
    if (props.loading) {
        return (
            <div className="list">
                <h2>Active</h2>
                <Loader />
            </div>)
    }

    let data = props.goals.length === 0 ? <span>There are no active goals</span> : <DnD array={props.goals} component={GoalCard} update={props.update} />;
    let dragDescription = props.goals.length === 0 ? <></> : <p>Drag and drop to re-order</p>;

    return (
        <div className="list">
            <h2>Active</h2>
            {dragDescription}
            {data}
        </div>
    )

}

function Suggested(goal, childId, setStatus) {
    const approveGoal = () => {
        goal.status = GoalType.Approved;
        setStatus(goal, GoalType.Approved);
        Data.ChangeGoalStatusChildId(childId, goal.id, GoalType.Approved);
    }
    const denyGoal = () => {
        goal.status = GoalType.Rejected;
        setStatus(goal, GoalType.Rejected);
        Data.ChangeGoalStatusChildId(childId, goal.id, GoalType.Rejected);
    }
    return (
        <div key={goal.id} className="goal-container">
            <div>
                <Link to={goal.id + "/tasks"}><b>{goal.title}</b></Link>
                <Link to={goal.id + "/tasks"}><Icon.FrontArrow alt="go to current goal page" /></Link>
                <br />
                <Link to={goal.id} ><span className="links clickable">Edit</span></Link> |
                <span className="links clickable" onClick={approveGoal}>Approve</span> |
                <span className="links clickable" onClick={denyGoal}>Reject</span>
            </div>
        </div>
    )
}

function Completed(goal) {
    return (
        <li key={goal.id} className="goal-container">
            <div>
                <Link to={goal.id + "/tasks"}><b>{goal.title}</b></Link>
                <Link to={goal.id + "/tasks"}><Icon.FrontArrow alt="go to current goal page" /></Link>
            </div>
        </li>
    )
}

function Rejected(goal) {
    return (
        <li key={goal.id} className="goal-container">
            <div>
                <b>{goal.title}</b>
            </div>
        </li>
    )
}

function CompleteGoalButton(props) {
    let caseContext = useContext(CaseContext);
    let childId = caseContext.case.childId;
    const params = useParams();
    const id = params.id;

    let [goal, setGoal] = useState(undefined)
    useEffect(() => {
        Data.GetChildGoal(childId, id)
            .then(data => {
                setGoal(data)
            });
    }, [childId, id]);

    if (goal === undefined) return <div></div>;

    const completeGoal = async () => {
        let status = goal.status === 3 ? 2 : 3;
        let text = goal.status === 3 ? "Goal set to uncomplete" : "Goal set to complete";

        await Data.ChangeGoalStatusChildId(childId, id, status);
        props.modalContext.setShowTextNavigate(text, "/child/goals");
    }

    let isComplete = goal.status === 3 ? "Uncomplete" : "Complete goal";

    return (
        <button onClick={completeGoal} className="button-tertiary button-max">{isComplete}</button>
    )
}

function Goals() {
    const caseContext = useContext(CaseContext);
    const notificationContext = useContext(NotificationContext);

    const [pendingGoals, setPendingGoals] = useState([]);
    const [activeGoals, setActiveGoals] = useState([]);
    const [loading, setLoading] = useState(true);

    const hasCase = caseContext.case != null;
    let childId = hasCase ? caseContext.case.childId : "";

    useEffect(() => {
        if (hasCase === false) return;
        (async () => {
            const data = await Data.GetGoalsChildId(childId);
            const pending = data.filter(goal => goal.status === GoalsData.GoalType.Pending && goal.deleted === false);
            const active = data.filter(goal => goal.status === GoalsData.GoalType.Approved && goal.deleted === false);

            setPendingGoals(pending);
            setActiveGoals(active);

            setLoading(false);
        })();
    }, [childId, hasCase]);

    useEffect(() => {
        notificationContext.readNotification(ItemArea.Goals);
    }, [notificationContext]);

    const getCompletedMax = () => {
        return Data.GetGoalsMaxByStatus(childId, GoalsData.GoalType.Completed);
    }
    const getRejectedMax = () => {
        return Data.GetGoalsMaxByStatus(childId, GoalsData.GoalType.Rejected);
    }
    const getCompletedPage = (count, offset) => {
        return Data.GetGoalsByStatus(childId, GoalsData.GoalType.Completed, count, offset);
    }
    const getRejectedPage = (count, offset) => {
        return Data.GetGoalsByStatus(childId, GoalsData.GoalType.Rejected, count, offset);
    }

    const refreshStatus = (goal, status) => {
        if (status === GoalType.Approved) {
            setActiveGoals(a => {
                let n = [goal, ...a];
                return n;
            });
        }
        setPendingGoals(p => {
            let n = p.filter(pp => pp.id !== goal.id);
            return n;
        });
    }

    if (hasCase === false) {
        return (
            <div className='content'>
                <h1>No case selected</h1>
                <Link to="/cases">Return to cases</Link>
            </div>
        )
    }

    const activeUpdate = Data.ChangeGoalOrderChildId.bind(this, childId);
    const pendingList = pendingGoals.map(goal => Suggested(goal, childId, refreshStatus));

    return (
        <div className='goals goals-case'>
            <div className="goals-list content-case">
                <BackButton />
                <h1>{nameFormatter(caseContext.case.childName.first)} goals</h1>
                <Section loading={loading} goals={pendingList} title="Suggested" />
                <ActiveGoals loading={loading} goals={activeGoals} update={activeUpdate} />
                <Paged title="Completed" empty={() => <span>There are no completed goals</span>} data={getCompletedPage} count={5} maxFunction={getCompletedMax} mapItems={Completed} />
                <Paged title="Rejected" empty={() => <span>There are no rejected goals</span>} data={getRejectedPage} count={5} maxFunction={getRejectedMax} mapItems={Rejected} />
            </div>
            <div className="buttons">
                <Link to="add"><button type="button" className='button-max'>Add a new goal</button></Link>
            </div>
        </div>
    )
}

export { Goals, GoalContext, AddPractitionerGoal, EditPractitionerGoal, CompleteGoalButton };