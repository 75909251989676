import { Outlet } from "react-router-dom";
import { Cases, CaseEdit } from "./Cases";
import Invite from "./Invite";

const CasesRoutes = {
    path: "cases",
    element: <Outlet />,
    children: [
        {
            path: "",
            element: <Cases route="invite"/>
        },
        {
            path: ":id/edit",
            element: <CaseEdit/>
        },
        {
            path: "invite",
            element: <Invite />
        }
    ]
};

export default CasesRoutes;