import api from "../../../shared/api";

const GetSuperUsers = (count, offset) => {
    return api.getPaged("superadmin/superusers", count, offset);
}

const GetMaxSuperUsers = () => {
    return api.get("superadmin/superusers/max");
}

const SearchSuperUsers = (search) => {
    return api.get("superadmin/superusers/search?name=" + search);
}

const InviteSuperUser = (invite) => {
    return api.post("superadmin/superusers/invite", invite);
}

const GetSuperUser = (id) => {
    return api.get("superadmin/superusers/" + id);
}

const EditSuperUser = (id) => {
    return api.postText("superadmin/superusers/" + id + "/edit");
}

export { GetSuperUsers, GetMaxSuperUsers, SearchSuperUsers, InviteSuperUser, GetSuperUser, EditSuperUser };