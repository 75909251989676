function SetAuthContext(token) {
    sessionStorage.setItem("jwt", token);
    if (navigator.serviceWorker.controller !== null) {
        navigator.serviceWorker.controller.postMessage({
            type: 'STORE-TOKEN',
            token: token
        });
    }
}

export default (SetAuthContext);