import api from "../../../shared/api";

const GetName = function () {
    return api.get("superuser/name");
}

const UpdateName = function (name) {
    return api.post("superuser/name", name);
}

const GetTheme = function () {
    return api.get("superuser/theme").then(data => data.theme);
}

const UpdateTheme = function (theme) {
    return api.post("superuser/theme", { theme: theme });
}

export { GetName, UpdateName, GetTheme, UpdateTheme };