import api from '../../shared/api.js'

const GetName = function () {
    return api.get("child/name");
}

const SetName = function (name) {
    return api.post("child/name", name);
}

const GetContactDetails = function () {
    return api.get("child/contact-details");
}
const UpdateContactDetails = function (details) {
    return api.post("child/contact-details", details);
}

const GetTheme = function () {
    return api.get("child/theme").then(data => data.theme);
}

const UpdateTheme = function (theme) {
    return api.post("child/theme", { theme: theme });
}

const GetBackground = () => {
    return api.get("child/background");
}

const SetBackground = (background) => {
    return api.post("child/background", { background: background });
}

const UploadProfilePicture = function (form) {
    return api.form("child/profile-picture/upload", form);
}

const DeleteProfilePicture = function () {
    return api.delete("child/profile-picture/");
}

const AcceptTerms = function () {
    return api.postOnly("child/accept-terms");
}

export {
    GetName,
    SetName,
    GetContactDetails,
    UpdateContactDetails,
    GetTheme,
    UpdateTheme,
    GetBackground,
    SetBackground,
    UploadProfilePicture,
    DeleteProfilePicture,
    AcceptTerms
};
