import { useContext } from "react"
import { Link, useNavigate } from "react-router-dom"
import { CaseContext, SetContext } from "../practitioner/cases/Cases"
import { TimeAgo } from "./dates"
import * as Icon from "./Icons"
import { AnimationTimeline } from "./layout/Layout"

const EventType = {
    MessageSent: 0,
    GoalSuggested: 1,
    GoalApproved: 2,
    GoalRejected: 3,
    GoalAdded: 4,
    GoalEdited: 5,
    GoalDeleted: 6,
    GoalCompleted: 7,
    TaskSuggested: 8,
    TaskApproved: 9,
    TaskRejected: 10,
    TaskAdded: 11,
    TaskEdited: 12,
    TaskDeleted: 13,
    TaskCompleted: 14,
    WhoIAmStuffAdded: 15,
    WantToBeStuffAdded: 16,
    HowGetThereStuffAdded: 17,
    AppointmentAdded: 18,
    AppointmentEdited: 19,
    AppointmentDeleted: 20,
    AppointmentAttended: 21,
    BackgroundUpdated: 22,
    ProfilePicUpdated: 23,
    CasePractitionerUpdated: 24
}

const ItemLink = (props) => {
    const caseContext = useContext(CaseContext);
    const navigate = useNavigate();

    switch (props.item.itemType) {
        case EventType.MessageSent:
            return (
                <>
                    <div>
                        <div className="event-notif">
                            <Icon.Messages />
                            {props.children}
                        </div>
                        <Link to={"/messages/" + props.item.itemId} className="button">View message</Link>
                    </div>
                    <span className="timestamp text">{TimeAgo(props.item.created)}</span>
                </>
            );
        case EventType.GoalSuggested:
        case EventType.GoalApproved:
        case EventType.GoalAdded:
        case EventType.GoalEdited:
        case EventType.GoalCompleted:
            return (
                <>
                    <div>
                        <div className="event-notif">
                            <Icon.Goals />
                            {props.children}
                        </div>
                        {props.child ? <Link to="/goals" className="button">View goals</Link> : <></>}
                    </div>
                    <span className="timestamp text">{TimeAgo(props.item.created)}</span>
                </>
            );
        case EventType.GoalRejected:
        case EventType.GoalDeleted:
            return (
                <>
                    <div>
                        <div className="event-notif">
                            <Icon.Goals />
                            {props.children}
                        </div>
                    </div>
                    <span className="timestamp text">{TimeAgo(props.item.created)}</span>
                </>
            );
        case EventType.TaskSuggested:
        case EventType.TaskApproved:
        case EventType.TaskAdded:
        case EventType.TaskEdited:
        case EventType.TaskCompleted:
            return (
                <>
                    <div>
                        <div className="event-notif">
                            <Icon.Goals />
                            {props.children}
                        </div>
                        {props.child ? <Link to={"/goals/" + props.item.itemId + "/tasks"} className="button">View tasks</Link> : <></>}
                    </div>
                    <span className="timestamp text">{TimeAgo(props.item.created)}</span>
                </>
            );
        case EventType.TaskRejected:
        case EventType.TaskDeleted:
            return (
                <>
                    <div>
                        <div className="event-notif">
                            <Icon.Goals />
                            {props.children}
                        </div>
                    </div>
                    <span className="timestamp text">{TimeAgo(props.item.created)}</span>
                </>
            );
        case EventType.WhoIAmStuffAdded:
            return (
                <>
                    <div>
                        <div className="event-notif">
                            <Icon.Briefcase />
                            {props.children}
                        </div>
                        {props.child ? <Link to="/mystuff/whoiam" className="button">View my stuff</Link> : <></>}
                    </div>
                    <span className="timestamp text">{TimeAgo(props.item.created)}</span>
                </>
            );
        case EventType.WantToBeStuffAdded:
            return (
                <>
                    <div>
                        <div className="event-notif">
                            <Icon.Briefcase />
                            {props.children}
                        </div>
                        {props.child ? <Link to="/mystuff/whoiwanttobe" className="button">View my stuff</Link> : <></>}
                    </div>
                    <span className="timestamp text">{TimeAgo(props.item.created)}</span>
                </>
            );
        case EventType.HowGetThereStuffAdded:
            return (
                <>
                    <div>
                        <div className="event-notif">
                            <Icon.Briefcase />
                            {props.children}
                        </div>
                        {props.child ? <Link to="/mystuff/howillgetthere" className="button">View my stuff</Link> : <></>}
                    </div>
                    <span className="timestamp text">{TimeAgo(props.item.created)}</span>
                </>
            );
        case EventType.AppointmentAdded:
        case EventType.AppointmentEdited:
            return (
                <>
                    <div>
                        <div className="event-notif">
                            <Icon.Appointments />
                            {props.children}
                        </div>
                        {props.child
                            ? <Link to="/appointments" className="button">View appointments</Link>
                            : <Link to={"/appointments/" + props.item.itemId} className="button">View appointments</Link>}
                    </div>
                    <span className="timestamp text">{TimeAgo(props.item.created)}</span>
                </>
            );
        case EventType.CasePractitionerUpdated:
            const NavigateToChild = () => {
                SetContext(props.item.itemId, caseContext, navigate);
            }
            return (
                <>
                    <div>
                        <div className="event-notif">
                            <Icon.Briefcase />
                            {props.children}
                        </div>
                        {props.child 
                        ? <Link to="/practitioner" className="button">View worker</Link> 
                        : <button onClick={NavigateToChild}>View child</button>}
                    </div>
                    <span className="timestamp text">{TimeAgo(props.item.created)}</span>
                </>
            );
        default:
            break;
    }
}

const ItemAnimation = (item) => {
    switch (item.itemType) {
        case EventType.GoalAdded: return <AnimationTimeline animation="taskGoalAdded" />;
        case EventType.GoalCompleted: return <AnimationTimeline animation="goalCompleted" />;
        case EventType.TaskAdded: return <AnimationTimeline animation="taskGoalAdded" />;
        case EventType.TaskCompleted: return <AnimationTimeline animation="taskCompleted" />;
        default:
            break;
    }
}

const ItemArea = {
    Messages: 0,
    Goals: 1,
    Tasks: 2,
    Stuff: 3,
    Appointments: 4,
    Settings: 5,
    Cases: 6
}

Object.freeze(ItemArea);

export { ItemLink, ItemAnimation, ItemArea };