import BackButton from "../../../shared/navigation";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { LoaderButton, showError } from "../../../shared/layout/Layout";
import { InvitePractitioner, SearchPractitionerUsernames } from "./Data";
import { useContext, useState } from "react";
import { ModalContext } from "../../../shared/Modals";
import Debounce from "../../../shared/Debounce";

function Invite(props) {
    const [errorMessage, setErrorMessage] = useState("");
    const [show, setShow] = useState(false);
    const [usernameExists, setUsernameExists] = useState(false);

    const modalContext = useContext(ModalContext);

    const SearchUsernames = Debounce((e) => {
        SearchPractitionerUsernames(e.target.value.trim()).then(bool => {
            setUsernameExists(bool);
        })
    });

    const usernameLengthCheck = (e) => {
        if (e.target.value.trim() === "") {
            setUsernameExists(false);
        }
        else {
            SearchUsernames(e);
        }
    }

    let usernameErrors = usernameExists
    ? <span className="error-message">Username already exists</span>
    : <span className='error-message'><ErrorMessage name="username" /></span>;
    
    return (
        <div className="content cases-invite">
            <BackButton to="/" />
            <h1>
                Add a practitioner
            </h1>
            <Formik
            initialValues={{
                username: "",
                firstName: "",
                lastName: "",
                nickname: "",
                contact: "",
                email: "",
                mobile: ""
            }}
                validationSchema={Yup.object({
                    username: Yup.string().required('Enter their username'),
                    firstName: Yup.string().required('Enter their first name'),
                    lastName: Yup.string().required('Enter their last name'),
                    contact: Yup.string().required('Choose a contact method'),
                    mobile: Yup.string().when("contact", {
                        is: '0',
                        then: Yup.string().required('Enter their mobile number')
                    }),
                    email: Yup.string().email('Enter a valid email').when("contact", {
                        is: '1',
                        then: Yup.string().required('Enter their email')
                    }),
                })}
                onSubmit={(values) => {
                    if (usernameExists) return;
                    else {
                        values.contact = Number(values.contact);
                        setShow(true);
                        InvitePractitioner(values).then(p => {
                        switch (p) {
                            case 500:
                                setShow(false);
                                setErrorMessage("Could not create practitioner, please try again.");
                                break;
                            case 400:
                                setShow(false);
                                setErrorMessage("Please enter a valid mobile number or email address.");
                                break;
                            default:
                                setShow(false);
                                setErrorMessage("");
                                modalContext.setShowTextNavigate("Practitioner added", "/");
                                break;
                        }
                    });
                    }
                }}
            >
                {(data) => {
                    let error = showError.bind(this, data);
                    return(
                        <Form>
                            <label className={error("username")}>
                                Username
                                <Field name="username" onKeyUp={usernameLengthCheck}/>
                                <br />
                                {usernameErrors}
                            </label>
                            <label className={error("firstName")}>
                                First name
                                <Field name="firstName" />
                                <br />
                                <span className='error-message'><ErrorMessage name="firstName" /></span>
                            </label>
                            <label className={error("lastName")}>
                                Last name
                                <Field name="lastName" />
                                <br />
                                <span className='error-message'><ErrorMessage name="lastName" /></span>
                            </label>
                            <label className={error("nickname")}>
                                Nickname
                                <Field name="nickname" />
                                <br />
                                <span className='error-message'><ErrorMessage name="nickname" /></span>
                            </label>
                            <label className={error("contact")}>
                                Contact method
                            </label>
                            <label className={error("contact") + " no-padding"}>
                                <Field name="contact" type="radio" value="0"checked={data.values.contact.toString() === "0"}/>
                                Mobile phone
                            </label>
                                <br />
                            <label className={error("contact") + " no-padding"}>
                                <Field name="contact" type="radio" value="1" checked={data.values.contact.toString() === "1"}/>
                                Email
                                <br />
                                <span className='error-message'><ErrorMessage name="contact" /></span>
                            </label>
                            <br />
                            <label className={error("mobile")}>
                                Mobile number
                                <Field name="mobile" />
                                <br />
                                <span className='error-message'><ErrorMessage name="mobile" /></span>
                            </label>
                            <label className={error("email")}>
                                Email
                                <Field name="email" />
                                <br />
                                <span className='error-message'><ErrorMessage name="email" /></span>
                            </label>
                            <br />
                            <span className="error-message">{errorMessage}</span>
                            <div className="button-container">
                                <LoaderButton show={show} type="submit" buttonStyle="button-max">Send invite</LoaderButton>
                            </div>
                        </Form>
                    )}
                }
            </Formik>
        </div>
    )
}

export default Invite;