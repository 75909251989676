import config from '../config';

const post = (url, body) => {
    return fetch(config.host + url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    })
}

const postText = (url, body) => {
    return post(url, body).then(response => {
        if (response.ok) {
            return response.text();
        }else{
            return false;
        }
    });
}

const Resendtoken = (username) => {
    return postText("auth/token/resend?username=" + username);
}

export { Resendtoken };