import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Icon from '../Icons';

export function HalfPage(props) {
    return (
        <div className='half-page'>
            <div className={"content " + props.className}>
                {props.children}
            </div>
        </div>
    )
}

export function Loader(props) {
    return (
        <div className="loader loader-section">
            <div className="spinner">&nbsp;</div>
        </div>
    )
}

export function LoaderPage(props) {
    return (
        <div className="loader loader-page">
            <div className="spinner">&nbsp;</div>
        </div>
    )
}

export function LoaderButton(props) {
    const buttonStyle = props.buttonStyle ? props.buttonStyle : "";
    const type = props.type ? props.type : "";
    const loader = props.show ? <Loader /> : <></>;
    const display = props.icon ? <span>{props.icon} {props.children}</span> : <span>{props.children}</span>;
    let style = props.show ? 'loader-button loader-button-nudge ' : 'loader-button '

    const submit = () => {
        if (props.submit) props.submit();
    }

    return (
        <button className={style + buttonStyle} onClick={submit} type={type} disabled={props.show}>
            {display}
            {loader}
        </button>
    )
}

function multiPager(min, max, current, numbers, numClick){
    const maxDisplay = 3;
    const showMin = current > 2;
    const showMax = numbers.length > 4 && current < numbers.length - 1;

    const showMinDot = current > 3;
    const showMaxDot = numbers.length > 4 && current < numbers.length - 2;

    let display = numbers.slice(Math.max(current - 2, 0), current + maxDisplay - 2);
    if(showMinDot === false){
        display = numbers.slice(Math.max(current - 2, 0), current + maxDisplay - 1);
    }
    if(showMaxDot === false){
        display = numbers.slice(Math.max(current - 3, 0), current + maxDisplay - 2);
    }

    const mapDisplay = (num) => {
        const className = (num === current) ? "active" : "clickable";
        return (<span key={num} onClick={numClick} className={className} >{num}</span>);
    }

    return(
        <>
            {showMin && <span onClick={numClick} className='clickable'>{min}</span>}
            {showMinDot && <span>...</span>}
            {display.map(mapDisplay)}
            {showMaxDot && <span>...</span>}
            {showMax && <span onClick={numClick} className='clickable'>{max}</span>}
        </>
    )
}

function singlePager(current, numbers, numClick){
    const mapDisplay = (num) => {
        const className = (num === current) ? "active" : "clickable";
        return (<span key={num} onClick={numClick} className={className} >{num}</span>);
    }

    return (
        <>
        {numbers.map(mapDisplay)}
        </>
    )
}

export function Paging(props) {
    const update = (u) => {
        props.setCurrent(u);
        props.setPage(u);
    }

    let maxPage = 1;
    if(props.maxPage !== undefined){
        maxPage = Math.ceil(Number(props.maxPage));
    }

    let numbers = Array.from(Array(maxPage).keys(), n => n+1);

    const min = 1;
    const max = Math.max(...numbers);

    const hasPrev = props.current > min;
    const hasNext = props.current < max;

    const prevClass = hasPrev ? "prev clickable" : "disabled";
    const nextClass = hasNext ? "next clickable" : "disabled";

    const prevClick = (e) => {if(hasPrev)update(props.current - 1)};
    const nextClick = (e) => {if(hasNext)update(props.current + 1)};
    const numClick = (e) => {update(Number(e.target.innerText))};   //dirty hack

    const pager = maxPage <= 5 ? singlePager(props.current, numbers, numClick) : multiPager(min, max, props.current, numbers, numClick);
    
    return (
        <div className="pager">
            <span className={prevClass} onClick={prevClick}>&lt; Prev</span>
            {pager}
            <span className={nextClass} onClick={nextClick}>Next &gt;</span>
        </div>
    )
}

export function Pager(props){
    const [items, setItems] = useState([]);
    const [max, setMax] = useState(0);
    const [page, setPage] = useState(1);
    const [itemCount, setItemCount] = useState(0);
    const [current, setCurrent] = useState(1);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        props.maxFunction().then(data => {
            setMax(Math.ceil(data/props.count));
            setItemCount(data);
        });
    }, [props]);

    useEffect(() => {
        setPage(1);
        setCurrent(1);
    }, [props.data]);

    useEffect(() => {
        setLoading(true);
        props.data(props.count, (page - 1) * props.count).then(data => {
            setItems(data);
            setLoading(false);
        });
    }, [props, page]);

    const itemList = loading ? <Loader /> : items.length === 0 ? props.empty() : items.map(props.mapItems);

    if (itemCount <= props.count) {
        return (
            <>
                <ul className={props.className}>
                    {itemList}
                </ul>
            </>
        )
    }

    else {
        return (
            <>
                <ul className={props.className}>
                    {itemList}
                </ul>
                <Paging setPage={setPage} maxPage={max} data={props.data} current={current} setCurrent={setCurrent}/>
            </>
        )
    }
}

export function IconButton(props) {
    const className = props.className !== undefined ? "button-square button-square-icon " + props.className : "button-square button-square-icon ";
    return (
        <button className={className} onClick={props.submit}>
            {props.icon}
            {props.children}
        </button>
    )
}

export const showError = ({ errors, touched }, field) => {
    return touched[field] && errors[field] ? 'error' : ''
}

export function AnimationModal(props) {
    const [show, setShow] = useState(props.open);
    const url = "/animations/" + props.animation + ".gif";

    useEffect(() => {
        let timeId = 0
        if (props.open === true) {
            setShow(true); 
            if (props.delay !== undefined){
                timeId = setTimeout(() => setShow(false), props.delay)
            };
        }
        return () => {clearTimeout(timeId);}
    }, [props]);

    const exitButton = (
        <span className="clear clickable" onClick={() => setShow(false)}>
            <Icon.Close />
        </span>
    )

    return (
        <div className="modal clickable" style={show ? { display: "block" } : { display: "none" }} onClick={() => setShow(false)}>
            <div className="modal-main modal-main-animation">
                {exitButton}
                <img className="animation" src={url} alt={"animation"}/>
                <p><b>{props.text}</b></p>
            </div>
        </div>
    )
}

export function AnimationTimeline(props) {
    const url = "/animations/" + props.animation + ".gif";

    return (
        <div className="animation-container">
            <img className="animation" src={url} alt={"animation"}/>
        </div>
    )
}

export const GetPathFragment = () => {
    return useLocation().pathname.substring(1).split('/')[0];
}

export const NickNameGenerator = (nickname) => {
    return !nickname ? null : "'" + nickname + "'";
}