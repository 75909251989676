import { Outlet, useParams } from "react-router-dom";
import { GoalContext } from "../Goals";
import { Tasks, AddPractitionerTask, EditPractitionerTask } from "./Tasks";

function GoalContextComponent() {
    const id = useParams().id;
    return (
        <GoalContext.Provider value={id}><Outlet /></GoalContext.Provider>
    )
}

const TasksRoutes = {
    path: "",
    element: <GoalContextComponent />,
    children: [
        {
            path: ":id/tasks",
            element: <Tasks />
        },
        {
            path: ":id/tasks/add",
            element: <AddPractitionerTask />
        },
        {
            path: ":id/tasks/:taskId",
            element: <EditPractitionerTask />
        }
    ]
};

export default TasksRoutes;
