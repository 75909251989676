import api from '../../../shared/api'

const GetGoalsChildId = (childId) => {
    return api.get("child/" + childId + "/goals");
}

const GetGoalsByStatus = (childId, status, count, offset) => {
    return api.getPaged("child/" + childId + "/goals/status/" + status, count, offset);
}

const GetGoalsMaxByStatus = (childId, status) => {
    return api.get("child/" + childId + "/goals/status/" + status + "/max");
}

const GetChildGoal = (childId, id) => {
    return api.get("child/" + childId + "/goals/" + id);
}

const CreateGoalChildId = (childId, goal) => {
    return api.post("child/" + childId + "/goals", goal);
}

const EditGoalChildId = (childId, id, update) => {
    return api.put("child/" + childId + "/goals/" + id, update)
}

const DeleteGoalChildId = (childId, id) => {
    return api.delete("child/" + childId + "/goals/" + id);
}

const ChangeGoalStatusChildId = (childId, id, status) => {
    return api.put("child/" + childId + "/goals/" + id + "/status?status=" + status);
}

const ChangeGoalOrderChildId = (childId, goalsDict) => {
    return api.put("child/" + childId + "/goals/order", goalsDict);
}

export {
    GetGoalsChildId,
    GetGoalsByStatus,
    GetGoalsMaxByStatus,
    GetChildGoal,
    CreateGoalChildId,
    EditGoalChildId,
    DeleteGoalChildId,
    ChangeGoalStatusChildId,
    ChangeGoalOrderChildId
};