import { useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/src/ReactCrop.scss'
import { Modal } from '../Modals';

function ProfileCrop(props) {
    const [crop, setCrop] = useState(undefined);
    const [disp, setDisp] = useState(undefined);
    
    const imgLoad = (e) => {
        setDisp({width: e.target.width, height: e.target.height });
    }

    const upload = ()=>{
        const imgElement = document.createElement('img');
        imgElement.src = props.src;
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext('2d');
        const ratio = imgElement.naturalWidth / disp.width;
        const imgSize = 128;

        canvas.width = imgSize;
        canvas.height = imgSize;
        
        ctx.drawImage(imgElement, 
            crop.x * ratio,
            crop.y * ratio,
            crop.width * ratio,
            crop.height * ratio, 
            0, 0,
            imgSize,
            imgSize);
        canvas.toBlob((imgData) => {
            const data = new FormData();
            data.append('formFile', imgData);
            props.upload(data);
            setCrop(undefined);
        });
    }
    return (
        <Modal show={props.show} handleClose={props.cancel} submit={upload} confirm="Upload" cancel="Cancel">
            <ReactCrop crop={crop} aspect={1} circularCrop={true} onChange={(c) => setCrop(c)}>
                <img src={props.src} alt="New profile pic" onLoad={imgLoad} className='picture-preview'/>
            </ReactCrop>
            <span>Tap & drag to crop your photo. This will be your profile picture.</span>
        </Modal>
    );
}


function Alerts(props){
    const [notificationStatus, setNotificationStatus] = useState(Notification.permission);
    const requestNotifications = () => {
        Notification.requestPermission().then(p => {
            setNotificationStatus(p);
        })
    };

    let requestButton = null;
    let status = null;
    if(notificationStatus !== 'granted'){
        status = "Alerts are disabled"
        requestButton = (
            <button onClick={requestNotifications}>
            Request permission
        </button>
        );
    }else{
        status = "Alerts are enabled"
        requestButton = <button onClick={requestNotifications}>Update permission</button>
    }

    return (
        <div>
            <h1>Alerts</h1>
            <p>Turn on alerts so you do not miss an update.</p>
            <p><b>{status}</b></p>
            {requestButton}
            <p>
                If nothing happens when you request permission, check your browser has not been set up to deny all alerts.
            </p>
        </div>
    )
}


export { ProfileCrop, Alerts };