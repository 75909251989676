import { useRoutes, useNavigate } from "react-router-dom";
import ANONROUTES from "./auth/routes";
import jwtDecode from "jwt-decode";

import '../shared/layout/components/themes.scss';
import Practitioner from "./Practitioner";
import Superuser from "./superuser/Superuser";

function App() {
  const anonRouter = useRoutes(ANONROUTES);
  const theme = localStorage.getItem("theme");
  const jwt = sessionStorage.getItem("jwt");
  const navigate = useNavigate();

  if (jwt === null) {
    if (theme !== null) {
      document.documentElement.className = theme;
    } else {
      document.documentElement.className = "light";
    }

    return (
      <div className="app">
        {anonRouter}
      </div>
    )
  }

  const token = jwtDecode(jwt);
  if (token["user-type"] === "Practitioner") {
    return <Practitioner />;
  } else if (token["user-type"] === "SuperUser" || token["user-type"] === "SuperAdmin") {
    return <Superuser />;
  } else {
    sessionStorage.removeItem("jwt");
    navigate("/");
  }
}

export default App;
