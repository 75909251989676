import { Outlet } from "react-router-dom";
import { EditProfile } from "./Settings";

const SettingsRoutes = {
    path: "settings",
    element: <Outlet />,
    children: [
        {
            path: "profile",
            element: <EditProfile />
        }
    ]
};

export default SettingsRoutes;