import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { LoaderButton, LoaderPage, NickNameGenerator, showError } from "../../../shared/layout/Layout";
import * as Data from "./Data";
import BackButton from "../../../shared/navigation";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from 'yup';
import './practitioners.scss';
import UsersDisplay from "../../../shared/UsersDisplay";
import { ModalContext } from "../../../shared/Modals";

const MakeRowItem = (practitionerItem) => {
    return (
        <div key={practitionerItem.userId} className="box-row">
            <span className="user">{practitionerItem.username}</span>
            <div onClick={null}>
                <span className="name">
                    {practitionerItem.name.first} {NickNameGenerator(practitionerItem.name.nickname)} {practitionerItem.name.last}
                </span>
            </div>
            <div className="controls">
                <Link to={"/practitioners/" + practitionerItem.userId + "/edit"}>
                    Edit
                </Link>
            </div>
        </div>
    );
}

function ResendButton(props) {
    let practitionerId = props.practitionerId;
    const [sending, setSending] = useState(false);
    const [show, setShow] = useState(false);

    let resend = (e) => {
        if (sending) return;
        setSending(true);
        setShow(true);

        Data.ResendInvite(practitionerId).then((c) => {
            if (c === 500) {
                props.setErrorMessage("Could not resend invite, please try again.")
                setSending(false);
                setShow(false);
            }
            else {
                setSending(false);
                setShow(false);
                props.setErrorMessage("");
                props.modalContext.setShowTextNavigate("Invite sent");
            }
        })
    }

    if (props.disabled) {
        return;
    }

    return (
        <div className="button-container">
            <LoaderButton show={show} submit={resend} buttonStyle="button-max button-tertiary">Resend invite</LoaderButton>
        </div>
    );
}

function PractitionerEdit(props) {
    var practitionerId = useParams().id;
    const [details, setDetails] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState("");
    const [show, setShow] = useState(false);

    const modalContext = useContext(ModalContext);

    useEffect(() => {
        Data.GetPractitioner(practitionerId).then(data => {
            setDetails(data);
        });
    }, [practitionerId]);

    if (details === undefined) return <LoaderPage />;

    return (
        <div className="content">
            <BackButton />
            <h1>Edit {details.practitionerName.first} {details.practitionerName.last}</h1>
            <p>
                <label>
                    <input readOnly checked={details.terms} type='checkbox' />
                    Accepted terms & conditions
                </label>
            </p>
            <Formik
                initialValues={{
                    username: details.username ?? "",
                    firstName: details.practitionerName.first ?? "",
                    lastName: details.practitionerName.last ?? "",
                    nickname: details.practitionerName.nickname ?? "",
                    contact: details.contactMethod.contact + "" ?? "",
                    mobile: details.contactMethod.mobile ?? "",
                    email: details.contactMethod.email ?? ""
                }}
                validationSchema={Yup.object({
                    firstName: Yup.string().required('Enter their first name'),
                    lastName: Yup.string().required('Enter their last name'),
                    contact: Yup.string().required('Choose a contact method'),
                    mobile: Yup.string().when("contact", {
                        is: '0',
                        then: Yup.string().required('Enter their mobile number')
                    }),
                    email: Yup.string().email('Enter a valid email').when("contact", {
                        is: '1',
                        then: Yup.string().required('Enter their email')
                    }),
                })}
                onSubmit={(values) => {
                    values.contact = Number(values.contact);
                    setShow(true);
                    Data.EditPractitioner(practitionerId, values).then(p => {
                        if (p === 400) {
                            setShow(false);
                            setErrorMessage("Enter a valid mobile number or email address.");
                        }
                        else {
                            setShow(false);
                            setErrorMessage("");
                            modalContext.setShowTextNavigate("Practitioner updated", "/");
                        }
                    })
                }}
            >
                {(data) => {
                    let error = showError.bind(this, data);
                    return (
                        <Form>
                            <label className={error("username")}>
                                Username
                                <Field name="username" readOnly />
                                <br />
                                <span className='error-message'><ErrorMessage name="username" /></span>
                            </label>
                            <label className={error("firstName")}>
                                First name
                                <Field name="firstName" />
                                <br />
                                <span className='error-message'><ErrorMessage name="firstName" /></span>
                            </label>
                            <label className={error("lastName")}>
                                Last name
                                <Field name="lastName" />
                                <br />
                                <span className='error-message'><ErrorMessage name="lastName" /></span>
                            </label>
                            <label className={error("nickname")}>
                                Nickname
                                <Field name="nickname" />
                                <br />
                                <span className='error-message'><ErrorMessage name="nickname" /></span>
                            </label>
                            <label className={error("contact")}>
                                Contact method
                            </label>
                            <label className={error("contact") + " no-padding"}>
                                <Field name="contact" type="radio" value="0" checked={data.values.contact.toString() === "0"}/>
                                Mobile phone
                            </label>
                            <br />
                            <label className={error("contact") + " no-padding"}>
                                <Field name="contact" type="radio" value="1" checked={data.values.contact.toString() === "1"}/>
                                Email
                                <span className='error-message'><ErrorMessage name="contact" /></span>
                            </label>
                            <br />
                            <label className={error("mobile")}>
                                Mobile number
                                <Field name="mobile" />
                                <br />
                                <span className='error-message'><ErrorMessage name="mobile" /></span>
                            </label>
                            <label className={error("email")}>
                                Email
                                <Field name="email" />
                                <br />
                                <span className='error-message'><ErrorMessage name="email" /></span>
                            </label>
                            <br />
                            <span className="error-message">{errorMessage}</span>
                            <div className="button-container">
                                <LoaderButton show={show} type="submit" buttonStyle="button-max">Update</LoaderButton>
                            </div>
                        </Form>
                    )
                }
                }
            </Formik>
            <ResendButton practitionerId={practitionerId} disabled={details.terms} modalContext={modalContext} setErrorMessage={setErrorMessage}/>
        </div>
    )
}

function Practitioners(props) {

    const NoPractitioners = () => (
        <div>No Practitioners added.</div>
    )

    return (
        <div className="superuser content">
            <div className="user-name">
                <h1>
                    <b>{props.user.name.first} {props.user.name.last}</b>
                </h1>
            </div>
            <h2>Practitioners</h2>
            <div className="practitioners-list">
                <UsersDisplay searchData={Data.SearchPractitioners} row={MakeRowItem} max={Data.GetMaxPractitioners} data={Data.GetPractitioners} empty={NoPractitioners} route="/practitioners/invite"/>
            </div>
        </div>
    )
}

export { Practitioners, PractitionerEdit };