import { Link } from "react-router-dom"
import { Format } from "../../../shared/dates";
import * as Icon from "../../../shared/Icons";

function GoalCard(props) {
    const opacity = props.isDragging ? 0.5 : 1;
    return (
        <div ref={props.cardRef} className="goal-container" style={{ ...props.styleCard, opacity }} data-handler-id={props.handlerId}>
            <div>
                <div className="info">
                    <Link to={props.id + "/tasks"}><span className="title">{props.text}</span></Link>
                    <span className="date">Due: {Format(props.card.deadline).dayYear}</span>
                </div>
                <br/>
                <Link to={props.id} ><span className="links clickable">Edit</span></Link> 
                <Link to={props.id + "/tasks"}><Icon.FrontArrow alt="go to current goal page"/></Link>
            </div>
        </div>
    )
}

export default GoalCard;