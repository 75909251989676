import { useRoutes } from "react-router-dom";
import { GetTheme } from "./settings/Data";
import { AuthContext, BuildContext } from "../auth/Auth";
import ROUTES from "./routes.js";
import { Navigation } from './navigation/Navigation'
import '../../shared/layout/components/themes.scss';
import ANONROUTES from "../auth/routes";
import { ModalContext, UpdateModal } from "../../shared/Modals";
import { useState } from "react";

function Superuser() {
  const router = useRoutes(ROUTES);
  const anonRouter = useRoutes(ANONROUTES);

  const [text, setText] = useState("");
  const [show, setShow] = useState(false);
  const [navigate, setNavigate] = useState("");

  const theme = localStorage.getItem("theme");
  const jwt = sessionStorage.getItem("jwt");
  const authProvider = BuildContext(jwt);

  if (jwt === null) {
    if (theme !== null) {
      document.documentElement.className = theme;
    } else {
      document.documentElement.className = "light";
    }
    return (
      <div className="app">
        {anonRouter}
      </div>
    )
  }

  if (theme !== null) {
    document.documentElement.className = theme;
  } else {
    GetTheme().then(serverTheme => {
      localStorage.setItem("theme", serverTheme);
      document.documentElement.className = serverTheme;
    });
  }

  const setShowTextNavigate = (text, navigate) => {
    setText(text);
    setShow(true);
    if (navigate) setNavigate(navigate);
  }

  return (
    <div className="app">
      <AuthContext.Provider value={authProvider}>
        <Navigation />
        <ModalContext.Provider value={{
          setShowTextNavigate: (text, navigate) => { setShowTextNavigate(text, navigate) }
        }}>
          <div className="app-container">
            {router}
            <UpdateModal show={show} setShow={setShow} text={text} navigate={navigate} />
          </div>
        </ModalContext.Provider>
      </AuthContext.Provider>
    </div>
  );
}

export default Superuser;
