import api from '../../../shared/api.js'

const GetTimelineChildId = function (childId, count, offset, userId) {
    if (offset === undefined) {
        if (userId === undefined || userId === null) {
            return api.get("child/" + childId + "/timeline");
        }
        return api.get("child/" + childId + "/timeline/?userId=" + userId);
    }
    
    if (userId === undefined || userId === null) {
        return api.getPaged("child/" + childId + "/timeline", count, offset);
    }
    return api.getPaged("child/" + childId + "/timeline/?userId=" + userId, count, offset);
}

const GetMaxTimelineChildId = function (childId, userId) {
    if (userId === undefined || userId === null) {
        return api.get("child/" + childId + "/timeline/max")
    }
    return api.get("child/" + childId + "/timeline/max?userId=" + userId);
}

export { GetTimelineChildId, GetMaxTimelineChildId };