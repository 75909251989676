import config from '../config';

const signalR = require('@microsoft/signalr');

const BuildConnection = (authContext) => {
    const connection = new signalR.HubConnectionBuilder()
        .withUrl(config.host + "hubs/messages", {
            accessTokenFactory: () => authContext.jwt()
        })
        .build();

    return connection;
}

export { BuildConnection };