import { useEffect, useState } from 'react';
import { StuffType, StuffGroup } from './Stuff';
import 'react-image-crop/src/ReactCrop.scss'
import SecureBackgroundImage from '../auth/SecureBackgroundImage';
import SecureImage from '../auth/SecureImage';
import data from '@emoji-mart/data/sets/14/twitter.json'
import { init } from 'emoji-mart'
import { Modal } from '../Modals';

const TextStuff = function (props) {
    return (
        <div className="grid-item text">
            <span>{props.text}</span>
            {props.children}
        </div>
    );
}
const ImageStuff = function (props) {
    const [show, setShow] = useState(false);
    const fullPicture = show ? <SecureImage url={"media/stuff/image/" + props.imageId}/> : null;

    const onClick = (e) => {
        setShow(!show);
    }

    return (
        <SecureBackgroundImage url={"media/stuff/image/" + props.imageId + "/preview"} className={"grid-item image"} onClick={onClick} >
            <p className='title'>{props.text}</p>
            {props.children}
            <Modal show={show} handleClose={onClick} hideButtons={true}>
                {fullPicture}
                <p>{props.text}</p>
            </Modal>
        </SecureBackgroundImage>
    );
}

const ImageStuffControl = function (props){
    return (
        <SecureBackgroundImage url={"media/stuff/image/" + props.imageId + "/preview"} className={"image"}>
            <p className='title'>{props.text}</p>
            {props.children}
        </SecureBackgroundImage>
    );
}

const LinkStuff = function (props) {
    return (
        <a href={props.url} target="_blank" rel='noreferrer' className="grid-item link">
            <p className='title'>{props.text}</p>
            <span>{props.url}</span>
            {props.children}
        </a>
    );
}
const EmojiStuff = function (props) {
    useEffect(() => {
        init({data});
    }, [])

    if(props.text.length <= 3){
        let showEmoji = [];
        for (let y = 0; y < props.text.length; y++) {
            const emoji = props.text[y];
            showEmoji.push(
                <li key={y} className={"emoji emoji-" + props.text.length}>
                    <em-emoji set="twitter" shortcodes={emoji} />
                </li>
            );
        }
    
        return (
            <div className="grid-item emoji">
                <ul className="emoji-list">
                    {showEmoji}
                </ul>
                {props.children}
            </div>
        );

    }

    let first = [];
    let second = [];
    for (let y = 0; y < 3; y++) {
        const emoji = props.text[y];
        first.push(
            <li key={y} className={"emoji emoji-" + props.text.length}>
                <em-emoji set="twitter" shortcodes={emoji} />
            </li>
        );
    }
    for (let y = 3; y < props.text.length; y++) {
        const emoji = props.text[y];
        second.push(
            <li key={y} className={"emoji emoji-" + props.text.length}>
                <em-emoji set="twitter" shortcodes={emoji} />
            </li>
        );
    }

    return (
        <div className="grid-item emoji">
            <ul className="emoji-list">
                {first}
            </ul>
            <ul className="emoji-list">
                {second}
            </ul>
            {props.children}
        </div>
    );
}

const innerControlComponents = (s, controlElement, attr) => {
    switch (s.type) {
        case StuffType.Base:
            return <div>base type: error!</div>
        case StuffType.Link:
            return <LinkStuff key={s.id} text={s.title} {...attr}>{controlElement}</LinkStuff>
        case StuffType.Text:
            return <TextStuff key={s.id} text={s.value} {...attr}>{controlElement}</TextStuff>
        case StuffType.Image:
            return <ImageStuffControl key={s.id} text={s.title} imageId={s.imageId} {...attr}>{controlElement}</ImageStuffControl>
        case StuffType.Emoji:
            return <EmojiStuff key={s.id} text={s.value} {...attr}>{controlElement}</EmojiStuff>
        default:
            return <div>unknown stuff</div>
    }
}

const mapComponents = (s, attr) => {
    switch (s.type) {
        case StuffType.Base:
            return <div>base type: error!</div>
        case StuffType.Link:
            return <LinkStuff key={s.id} text={s.title} url={s.url} {...attr} />
        case StuffType.Text:
            return <TextStuff key={s.id} text={s.value} {...attr} />
        case StuffType.Image:
            return <ImageStuff key={s.id} text={s.title} imageId={s.imageId} {...attr} />
        case StuffType.Emoji:
            return <EmojiStuff key={s.id} text={s.value} {...attr} />
        default:
            return <div>unknown stuff</div>
    }
}

const mapTitle = (g) => {
    switch (g) {
        case StuffGroup.WhoIAm:
            return "Who I am";
        case StuffGroup.WantToBe:
            return "Who I want to be";
        case StuffGroup.GetThere:
            return "How I'll get there";
        default:
            return "";
    }
}

const mapControlComponents = (selected, onClick, s) => {
    let typeClass = "";
    switch (s.type) {
        case StuffType.Base:
            break;
        case StuffType.Link:
            typeClass = "link"
            break;
        case StuffType.Text:
            typeClass = "text"
            break;
        case StuffType.Image:
            typeClass = "image"
            break;
        case StuffType.Emoji:
            typeClass = "emoji"
            break;
        default:
            break;
    }

    if(selected.includes(s.id)) typeClass += " selected";

    let controlElement = (id) => {
        const checked = selected.includes(id);
        return <input defaultChecked={checked} className='controlElement' onClick={onClick.bind(this, id)} type="checkbox" />
    }

    return (
        <label className={'grid-item ' + typeClass} key={s.id} >
            {innerControlComponents(s, controlElement(s.id))}
        </label>
    )
}


export { mapComponents, mapTitle, mapControlComponents };