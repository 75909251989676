import React, { useState } from 'react';
import { Modal } from '../Modals';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { LoaderButton, showError } from '../layout/Layout';
import { Format } from '../dates';

const GoalType = {
    Pending: 0,
    Rejected: 1,
    Approved: 2,
    Completed: 3,
}

function EnterDetails(props) {
    const formattedDate = Format(new Date());
    const [show, setShow] = useState(false);

    const onDelete = () => {
        setShow(false);
        props.delete();
    }

    const deleteButton = (
        <div>
            <Modal show={show} handleClose={() => setShow(false)} submit={onDelete} text="Are you sure you want to delete this goal?" />
            <div className='button-container'>
                <button onClick={() => setShow(true)} className="button-square button-secondary button-delete button-max">Delete</button>
            </div>
        </div>
    )

    return (
        <div className="add-goal">
            <div className="input-section">
                <Formik
                    initialValues={{title: props.title, deadline: props.deadline, description: props.desc}}
                    validationSchema={Yup.object({
                        title: Yup.string().max(30, "Must be fewer than 30 letters").required("Choose a goal"),
                        deadline: Yup.date().required("Date required"),
                        description: Yup.string().required("Enter a reason")
                    })}
                    onSubmit={(values) => {
                        props.submit(values);
                    }}
                >
                    {(data) => {
                        let error = showError.bind(this, data);
                        return (
                            <Form>
                                <label className={error("title") + " title"}>
                                    Goal
                                    <Field name="title" placeholder="Type your goal here" type="text" />
                                    <br />
                                    <span className='error-message'><ErrorMessage name="title" /></span>
                                </label>
                                <label className={error("deadline") + " deadline"}>
                                    Deadline
                                    <Field name="deadline" type="date" min={formattedDate.input} placeholder="DD/MM/YYYY" />
                                    <br />
                                    <span className='error-message'><ErrorMessage name="deadline" /></span>
                                </label>
                                <label className={error("description") + " description"}>
                                    Reason for your goal
                                    <Field as="textarea" name="description" placeholder="Type why you think this will be a good goal" />
                                    <br />
                                    <span className='error-message'><ErrorMessage name="description" /></span>
                                </label>
                                <div className='button-container'>
                                    <LoaderButton show={props.show} type="submit" buttonStyle="button-square button-max">{props.text}</LoaderButton>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
            {props.title !== "" ? deleteButton : <></>}
        </div>
    )
}

export { EnterDetails, GoalType };