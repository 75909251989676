import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Settings } from '../settings/Settings';
import { CaseContext } from '../cases/Cases';
import { AuthContext } from '../auth/Auth';
import { BuildConnection } from '../../shared/notifications/Realtime';
import { GetNotificationTotals } from '../notifications/Notifications';
import './navigation.scss'
import * as Icon from '../../shared/Icons';
import { GetPathFragment } from '../../shared/layout/Layout';
import { Resendtoken } from '../../shared/auth/Data';
import SetAuthContext from '../../shared/auth/Auth';

function Notification(props) {
    let count = props.count;
    let notifClass = "badge";

    if (count > 9) {
        count = "9⁺"
        notifClass = "badge badge-greater"
    };

    let withNotif = (
        <div className="notifContainer">
            {props.children}
            <span className={notifClass}>{count}</span>
        </div>);

    let withoutNotif = (
        <div className="notifContainer">
            {props.children}
        </div>)

    return props.count > 0 ? withNotif : withoutNotif
}

function Cases(props) {
    let caseContext = useContext(CaseContext);

    return (
        <Link to="/cases" onClick={() => caseContext.clearCase()} className="item" aria-label="Cases">
            <Notification count={props.count}>
                <Icon.Briefcase alt="cases button"/>
            </Notification>
            <span className={props.navClass}>Children</span>
        </Link>
    )
}

function Messages(props) {
    return (
        <Link to="messages" className="item" aria-label="Messages">
            <Notification count={props.count}>
                <Icon.Messages />
            </Notification>
            <span className={props.navClass}>Messages</span>
        </Link>
    )
}

function Appointments(props) {
    return (
        <Link to="appointments" className="item" aria-label="Appointments">
            <Notification count={props.count}>
                <Icon.Appointments />
            </Notification>
            <span className={props.navClass}>Appointments</span>
        </Link>
    )
}

function Notifications(props) {
    return (
        <Link to="notifications" className="item" aria-label="Notifications">
            <Notification count={props.count}>
                <Icon.Notification />
            </Notification>
            <span className={props.navClass}>Notifications</span>
        </Link>
    )
}

function Home() {
    return (
        <Link to="/" className="item" aria-label="Home">
            <Icon.Home />
        </Link>
    )
}

function NotificationBell(props) {
    return (
        <Link to="notifications" className="item" aria-label='Notification bell'>
            <Notification count={props.count}>
                <div className={props.navClass}>
                    <Icon.Notification />
                </div>
            </Notification>
        </Link>
    )
}

const GeneralNavMenu = (data) => {
    const pathFragment = GetPathFragment();

    let styles = { messages: "text", appointments: "text", notifications: "text", cases: "text" };

    let caseNotif = 0;
    let msgNotif = 0;
    let appNotif = 0;
    let otherNotif = 0;

    if (data !== undefined) {
        caseNotif = data.Cases;
        msgNotif = data.Messages;
        otherNotif = data.Messages + data.Goals + data.Tasks + data.Stuff + data.Appointments + data.Cases;
        appNotif = data.Appointments;
    }
    
    styles[pathFragment] += " focus"

    let navItems = (
        <>
            <Cases count={caseNotif} navClass={styles.cases} />
            <Messages count={msgNotif} navClass={styles.messages} />
            <Appointments count={appNotif} navClass={styles.appointments} />
            <Notifications count={otherNotif} navClass={styles.notifications} />
        </>
    )

    return (
        <nav className="nav-menu">
            {navItems}
        </nav>
    )
}

const SpecificNavMenu = (caseItem, data) => {
    const pathFragment = GetPathFragment();

    let styles = { messages: "text", appointments: "text", child: "text" };

    let caseNotif = 0
    let msgNotif = 0;
    let appNotif = 0;
    let otherNotif = 0;

    if (data !== undefined) {
        caseNotif = data.Cases;
        msgNotif = data.Messages;
        otherNotif = data.Goals + data.Tasks + data.Stuff;
        appNotif = data.Appointments;
    }

    styles[pathFragment] += " focus"

    return (
        <nav className="nav-menu nav-case">
            <div className='case-banner'>You are managing {caseItem.childName.first} {caseItem.childName.last}</div>
            <Cases count={caseNotif} navClass="text" />
            <Messages count={msgNotif} navClass={styles.messages} />
            <Appointments count={appNotif} navClass={styles.appointments} />
            <Link to="/child" className="item" aria-label="Child case">
                <Notification count={otherNotif}>
                    <Icon.User />
                </Notification>
                <span className={styles.child}>Manage</span>
            </Link>
        </nav>
    )
}

function NavMenu(props) {
    let data = props.data;
    let caseItem = props.caseItem;

    if (caseItem === undefined) {
        return GeneralNavMenu(data);
    }
    else {
        return SpecificNavMenu(caseItem, data);
    }
}

function NavigationHeader(props) {
    const data = props.data;
    const location = useLocation();
    const pathFragment = GetPathFragment();

    let styles = { notifications: "", settings: "" };

    let homeLink = null;
    if(location.pathname !== "/") {
        homeLink = <Home />;
        styles[pathFragment] = "focus";
    }

    let notifications = 0;
    if(data !== undefined){
        notifications = data.Messages + data.Goals + data.Tasks + data.Stuff + data.Appointments + data.Cases;
    }

    return (
        <div className="header">
            <NotificationBell count={notifications} navClass={styles.notifications}/>
            {homeLink}
            <Settings navClass={styles.settings}/>
        </div>
    )
}

const Navigation = function (props) {
    let caseContext = useContext(CaseContext);
    let caseItem = caseContext.case;

    const auth = useContext(AuthContext);
    const [connection] = useState(BuildConnection(auth));

    const [allData, setAllData] = useState(undefined);
    const [data, setData] = useState(undefined);
    const refresh = 60 * 1000;  // x seconds

    useEffect(() => {
        GetNotificationTotals().then(t => setAllData(t));
        GetNotificationTotals(caseItem).then(t => {
            setData(t);
        });
    }, [caseItem, props.refresh]);

    useEffect(() => {
        let id = -1;
        if (connection.state === 'Disconnected'){
            connection.start();
        }

        connection.on("SendNotification", (notification, area) => {
            // alert with the notification somehow
            if (notification.itemType === 24) {
                Resendtoken(auth.user().username).then((r) => {
                    SetAuthContext(r);
                });
            }
            if(caseItem === undefined){
                setData(old => {
                    let update = {...old};
                    update[area] += 1;
                    return update;
                });
            }else if(notification.creatorId === caseItem.childId){
                setData(old => {
                    let update = {...old};
                    update[area] += 1;
                    return update;
                });
            }

            if(navigator.serviceWorker.controller !== null){
                navigator.serviceWorker.controller.postMessage({
                    type: 'NOTIFICATION',
                    notification: notification
                });
            }

            setAllData(old => {
                let update = {...old};
                update[area] += 1;
                return update;
            });
        });

        let update = () => {
            if(connection.state === 'Connected'){
                if(caseItem === undefined){
                    connection.invoke("GetTotals").then(totals => {
                        setData(totals);
                        setAllData(totals);
                        sessionStorage.setItem("notification-data", JSON.stringify(totals));
                    });
                }else{
                    connection.invoke("GetChildTotals", caseItem.childId).then(totals => setData(totals));
                    connection.invoke("GetTotals").then(totals => setAllData(totals));
                }
                id = setTimeout(update, refresh);
            }
        }

        id = setTimeout(update, refresh);

        return () => {
            clearTimeout(id);
            connection.off("SendNotification");
        };
    }, [connection, refresh, caseItem, auth])

    return (
        <>
        <NavigationHeader data={allData} />
        <NavMenu data={data} caseItem={caseItem} />
        </>
    )
}

export { Navigation, NavigationHeader };