import { Outlet } from "react-router-dom";
import { AddPage, MyStuff } from "./MyStuff";
import { AddChildLink, AddChildEmoji, AddChildText, AddChildImage } from "./AddStuff";

const MyStuffRoutes = {
    path: "mystuff",
    element: <Outlet />,
    children: [
        {
            path: "",
            element: <MyStuff/>
        },
        {
            path: "add",
            element: <AddPage/>
        },
        {
            path: "add/link",
            element: <AddChildLink />
        },
        {
            path: "add/emoji",
            element: <AddChildEmoji />
        },
        {
            path: "add/text",
            element: <AddChildText />
        },
        {
            path: "add/image",
            element: <AddChildImage />
        }
    ]
};

export default MyStuffRoutes;