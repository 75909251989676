import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, UpdateModal } from '../../Modals';
import BackButton from '../../navigation';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { LoaderButton, showError } from '../../layout/Layout';

const TaskType = {
    Pending: 0,
    Rejected: 1,
    Approved: 2
}

function EnterTitle(props) {
    const [show, setShow] = useState(false);

    const onDelete = () => {
        setShow(false);
        props.onDelete();
    }

    const deleteButton = (
        <button type="button" onClick={() => setShow(true)} className="button-square button-secondary button-delete button-max">Delete</button>
    )

    return (
        <div>
            <h1>{props.header}</h1>
            <Formik
                initialValues={{ title: props.title }}
                validationSchema={Yup.object({
                    title: Yup.string().max(30, "Must be fewer than 30 letters").required("Choose a task")
                })}
                onSubmit={(values) => {
                    props.submit({
                        title: values.title,
                        complete: props.complete
                    });
                }}
            >
                {(data) => {
                    let error = showError.bind(this, data);
                    return (
                        <Form>
                            <label className={error("title") + " title"}>Title
                                <Field name="title" placeholder="Type the task here" as="textarea" />
                                <br />
                                <span className='error-message'><ErrorMessage name="title" /></span>
                            </label>
                            <div className='button-container'>
                            <LoaderButton show={props.show} type="submit" buttonStyle="button-square button-max">{props.text}</LoaderButton>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
            <div className='button-container'>
                {props.title !== "" ? deleteButton : <></>}
            </div>
            <Modal show={show} handleClose={() => setShow(false)} submit={onDelete} text="Are you sure you want to delete this task?" />
        </div>
    )
}

function Edit(props) {
    const params = useParams();
    const id = params.taskId;
    let [task, setTask] = useState(undefined);
    let [show, setShow] = useState(false);
    const [loaderShow, setLoaderShow] = useState(false);

    useEffect(() => {
        props.getTask(id)
            .then(data =>
                setTask(data));
    }, [props, id]);

    const onEdit = (update) => {
        setLoaderShow(true);
        props.editTask(id, update).then((data) => {
            setTask(data);
            setLoaderShow(false);
            props.modalContext.setShowTextNavigate("Task updated", props.navigate);
        });
    }

    const onDelete = () => {
        props.deleteTask(id);
        setShow(true);
    }

    if (task === undefined) return (<div></div>);

    return (
        <div className='content'>
            <BackButton to={props.navigate} />
            <EnterTitle header="Edit task" title={task.title} complete={task.complete} text="Save" submit={onEdit} onDelete={onDelete} show={loaderShow}/>
            <UpdateModal show={show} setShow={setShow} text="Task deleted" navigate={props.navigate}/>
        </div>
    )
}

function Add(props) {
    const [show, setShow] = useState(false);

    const postTask = (task) => {
        setShow(true);
        props.addTask(task).then(() => {
            setShow(false);
            props.modalContext.setShowTextNavigate(props.text, props.navigate);
        });
    }

    const className = props.hasCase ? "content-case" : "content";

    return (
        <div className={'tasks ' + className}>
            <BackButton to={props.navigate} />
            <EnterTitle header={props.header} title="" complete="false" text="Submit" submit={postTask} show={show}/>
        </div>
    )
}

function NoTasks() {
    return (
        <div>
            <b>No tasks currently</b>
        </div>
    )
}

export { Edit, Add, NoTasks, TaskType };