import { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { Link, Emoji, Image, Text } from "../../../shared/mystuff/Stuff";
import { AddLink, AddEmoji, AddImage, AddText } from "../../../shared/mystuff/AddStuff";
import { SubmitStuff, UploadImage } from "./Data";
import { CaseContext } from '../../cases/Cases';

const returnLink = "/child/mystuff?group=";

function AddChildLink() {
    const caseContext = useContext(CaseContext);
    const [searchParams] = useSearchParams()
    var group = searchParams.get("group");

    const submitLink = (link) => {
        var newLink = new Link("00000000-0000-0000-0000-000000000000", link.group, link.title, link.ref);
        return SubmitStuff(caseContext.case.childId, newLink);
    }

    return <AddLink group={group} return={returnLink + group} submit={submitLink} hasCase={caseContext.case !== undefined} case={caseContext.case}/>
}

function AddChildEmoji() {
    const caseContext = useContext(CaseContext);
    const [searchParams] = useSearchParams()
    var group = searchParams.get("group");

    const submitEmoji = (emoji) => {
        var newEmoji = new Emoji("00000000-0000-0000-0000-000000000000", emoji.group, emoji.value);
        return SubmitStuff(caseContext.case.childId, newEmoji);
    }

    return <AddEmoji group={group} return={returnLink + group} submit={submitEmoji} hasCase={caseContext.case !== undefined} case={caseContext.case}/>
}

function AddChildText() {
    const caseContext = useContext(CaseContext);
    const [searchParams] = useSearchParams()
    var group = searchParams.get("group");

    const submitText = (text) => {
        var newText = new Text("00000000-0000-0000-0000-000000000000", text.group, text.text);
        return SubmitStuff(caseContext.case.childId, newText);
    }

    return <AddText group={group} return={returnLink + group} submit={submitText} hasCase={caseContext.case !== undefined} case={caseContext.case}/>
}

function AddChildImage() {
    const caseContext = useContext(CaseContext);
    const [searchParams] = useSearchParams()
    var group = searchParams.get("group");

    const submitImage = async (image) => {
        const uploadedImage = await UploadImage(image.group, image.value, caseContext.case.childId);
        var newImage = new Image("00000000-0000-0000-0000-000000000000", image.group, image.title, uploadedImage.pictureId);
        return await SubmitStuff(caseContext.case.childId, newImage);
    }

    return <AddImage group={Number(group)} return={returnLink + group} submit={submitImage} hasCase={caseContext.case !== undefined} case={caseContext.case}/>
}


export { AddChildLink, AddChildEmoji, AddChildText, AddChildImage };