import { useContext, useRef, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { CaseContext } from '../../cases/Cases';
import { GetMyStuff, DeleteStuff, ExportStuff } from './Data';
import { mapComponents, mapTitle, mapControlComponents } from "../../../shared/mystuff/MyStuff";
import { AddGroupStuff } from "../../../shared/mystuff/AddStuff";
import { StuffGroup } from "../../../shared/mystuff/Stuff";
import { HalfPage, IconButton } from '../../../shared/layout/Layout';
import './mystuff.scss';
import * as Icon from '../../../shared/Icons';
import { Modal } from "../../../shared/Modals";
import { NotificationContext } from '../../notifications/Notifications';
import { ItemArea } from '../../../shared/Events';
import Scrollbars from 'react-custom-scrollbars-2';
import { nameFormatter } from '../Common';
import BackButton from '../../../shared/navigation';
import { saveAs } from 'file-saver'

function NoCase() {
    return (
        <div className='content'>
            <h1>No Case selected</h1>
            <Link to="/cases">Return to cases</Link>
        </div>
    );
}

function AddPage() {
    const caseContext = useContext(CaseContext);
    const hasCase = caseContext.case != null;
    const [searchParams] = useSearchParams();
    const group = searchParams.get('group');

    const groupTitle = mapTitle(Number(group));

    if (hasCase === false) {
        return <NoCase />
    }

    return (
        <HalfPage className="mystuff-addpage">
            <h2>
                Add new stuff for {caseContext.case.childName.first} in '{groupTitle}'
            </h2>
            <AddGroupStuff group={group} useQuery="true" />
            <Link to=".." className='button button-tertiary'>Cancel</Link>
        </HalfPage>
    )
}

function StuffDataExport(setShow, child) {
    const firstName = child.childName.first.toLowerCase();
    const lastName = child.childName.last.toLowerCase();

    setShow(true);
    ExportStuff(child.childId).then((response) => {
        saveAs(response, lastName + "-" + firstName + "-data-export.pdf");
        setShow(false);
    });
}

function MyStuff() {
    const caseContext = useContext(CaseContext);
    const notificationContext = useContext(NotificationContext);
    const [searchParams] = useSearchParams();

    const queryGroup = searchParams.get('group')
    let groupDefault = StuffGroup.WhoIAm;
    if (queryGroup !== undefined) {
        groupDefault = Number(queryGroup);
    }

    const hasCase = caseContext.case != null;
    const [myStuff, setMyStuff] = useState([]);
    const [deleteMode, setDeleteMode] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteStuff, setDeleteStuff] = useState([]);
    const [group, setGroup] = useState(groupDefault);
    
    const navigate = useNavigate();
    const scrollRef = useRef();
    const pageSize = 6;

    useEffect(() => {
        if (caseContext.case === undefined) return;

        GetMyStuff(caseContext.case.childId, group, 18, 0).then(data => {
            setMyStuff(data);
            if (scrollRef.current !== null) {
                scrollRef.current.scrollToTop();
            }
        });

    }, [caseContext, group]);

    useEffect(() => {
        notificationContext.readNotification(ItemArea.Stuff);
    }, [notificationContext]);

    if (hasCase === false) {
        return <NoCase />
    }

    const childName = nameFormatter(caseContext.case.childName.first);

    function changeNavBar(e) {
        setGroup(e.target.value);
    }

    const submit = () => {
        navigate("add?group=" + group);
    }

    const onScroll = () => {
        if (scrollRef.current === null) return;
        let current = scrollRef.current;

        let bottom = current.getScrollHeight() - current.getClientHeight() - current.getScrollTop();
        if (Math.abs(bottom) <= 1) {
            GetMyStuff(caseContext.case.childId, group, myStuff.length + pageSize, myStuff.length).then(
                r => setMyStuff(s => s.concat(r))
            );
        }
    }

    const controlCheck = (id, e) => {
        if (e.target.checked) {
            setDeleteStuff(s => {
                return s.concat([id]);
            });
        } else {
            setDeleteStuff(s => {
                return s.filter(x => x !== id);
            });
        }
    }

    const cancelDelete = () => {
        setDeleteMode(false);
        setDeleteStuff([]);
    }
    const preDelete = () => {
        if (deleteStuff.length === 0)    //error
            return;
        else
            setShowDeleteModal(true);
    }
    const doDelete = () => {
        DeleteStuff(caseContext.case.childId, deleteStuff).then(() => {
            // remove stuff from the list
            setMyStuff(su => {
                let u = su.filter(s => !deleteStuff.includes(s.id));
                return u;
            })
            setDeleteMode(false);
            setDeleteStuff([]);
            setShowDeleteModal(false);
        });
    }

    const EmptyStuff = () => (
        <p>
            {caseContext.case.childName.first} has not added stuff to '{mapTitle(Number(group))}'.
            <br /><br />
            To add stuff for {caseContext.case.childName.first}, click 'Add'.
        </p>
    )

    const EmptyDeleteStuff = () => (
        <p>
            {caseContext.case.childName.first} has not added stuff to '{mapTitle(Number(group))}'.
            <br /><br />
            To add stuff click 'Cancel' and then 'Add'.
        </p>
    )

    const deleteComponents = myStuff.map(s => mapControlComponents(deleteStuff, controlCheck, s));
    const stuffComponents = myStuff.map(mapComponents);

    const description = myStuff.length === 0 ? deleteMode ? EmptyDeleteStuff() : EmptyStuff() : <></>;

    const deleteButton = deleteMode
        ? (<>
            <IconButton icon={<Icon.Bin />} submit={preDelete} className="button-delete">Delete</IconButton>
            <Modal show={showDeleteModal} handleClose={() => setShowDeleteModal(false)} submit={doDelete} text="Are you sure you want to delete this stuff?" />
            <IconButton icon={<Icon.Delete />} submit={cancelDelete} className="button-cancel">Cancel</IconButton>
        </>)
        : <IconButton icon={<Icon.Bin />} submit={() => setDeleteMode(true)} className="button-delete">Delete</IconButton>;

    const addButton = deleteMode
        ? <></>
        : <IconButton icon={<Icon.Add />} submit={submit} className="button-add">Add</IconButton>;


    return (
        <div className="mystuff content-case">
            <BackButton />
            <h1>{childName} stuff</h1>
            <div className='bar'>
                <select onChange={changeNavBar} defaultValue={group}>
                    <option disabled>All my stuff</option>
                    <option value={StuffGroup.WhoIAm}>Who I am</option>
                    <option value={StuffGroup.WantToBe}>Who I want to be</option>
                    <option value={StuffGroup.GetThere}>How I'll get there</option>
                </select>
                <div className="buttons">
                    {deleteButton}
                    {addButton}
                </div>
            </div>
            {description}
            <Scrollbars className='grid' ref={scrollRef} onScroll={onScroll}>
            <div className="grid-container">
                    {deleteMode ? deleteComponents : stuffComponents}
                </div>
            </Scrollbars>
        </div>
    );
}

export { AddPage, MyStuff, StuffDataExport };