import api from '../../shared/api.js';

const GetName = function (childId) {
    return api.get("details/" + childId + "/name");
}

const GetChildBackground = (childId) => {
    return api.get("details/" + childId + "/background");
}

export { GetName, GetChildBackground }