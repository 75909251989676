import api from '../../shared/api.js';

const GetConversations = function (count, offset) {
    if(offset === undefined)
        return api.get("conversations");
    return api.getPaged("conversations", count, offset);
}

const GetConversationsForChild = function(childId, count, offset){
    if(offset === undefined)
        return api.get("conversations?childId=" + childId);
    
        return api.getPaged("conversations?childId=" + childId, count, offset)
}

const GetConversation = function (id, pageSize, offset) {
    if(offset === undefined)
        return api.get("conversations/" + id);

    return api.getPaged("conversations/" + id, pageSize, offset);
}

const CreateConversation = function (childId, subject, message) {
    return api.post("conversations" , { targetId: childId, subject, message });
}

const AddMessage = function (id, message) {
    return api.post("conversations/" + id, { content: message });
}

export { GetConversations, GetConversationsForChild, GetConversation, CreateConversation, AddMessage }