import { Homepage, NotFound } from "./homepage/Homepage";
import { Logout } from "../auth/Auth";
import SettingsRoutes from "./settings/Routes";
import PractitionersRoutes from "./practitioners/Routes";
import SuperUsersRoutes from "./superusers/Routes";
import CasesRoutes from "./cases/Routes";

const ROUTES = [
    {
        path: "/",
        element: <Homepage />,
        children: []
    },
    SettingsRoutes,
    CasesRoutes,
    PractitionersRoutes,
    SuperUsersRoutes,
    {
        path: "/logout",
        element: <Logout />,
        children: []
    },
    {
        path: "*",
        element: <NotFound/>,
        children:[]
    }
];

export default ROUTES;
