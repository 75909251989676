import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, ModalContext } from '../../../shared/Modals';
import * as Icon from '../../../shared/Icons';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Loader, LoaderButton, showError } from "../../../shared/layout/Layout";
import * as Data from "./Data";
import { Resendtoken } from "../../../shared/auth/Data";
import SetAuthContext from "../../../shared/auth/Auth";
import { AuthContext } from "../../auth/Auth";

export function Settings(props) {
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    
    const openCloseSettings = () => {
        setOpen(!open);
    }

    return (
        <div className="item">
            <div id="settings" className="overlay" style={open ? { width: "100%" } : { width: "0%" }}>
                <span className="closebtn" onClick={openCloseSettings}><Icon.Close /></span>
                <div className="content" >
                    <title>Settings</title>
                    <div onClick={openCloseSettings}>
                        <Link className='content-item' to="/settings/profile"><span>Edit profile</span></Link>
                    </div>
                    <Modal show={show} submit={() => {setShow(false); navigate("/logout");}} handleClose={() => setShow(false)} text="Are you sure you want to log out?"></Modal>
                    <span className='clickable content-item' onClick={() => setShow(true)}>Log out</span>
                </div>
            </div>
            <div className="clickable" onClick={openCloseSettings}>
                <div className={props.navClass}>
                    <Icon.Settings />
                </div>
            </div>
        </div>
    )
}

function Name(props) {
    const [name, setName] = useState(undefined);
    const [show, setShow] = useState(false);

    useEffect(() => {
        Data.GetName().then(name => setName(name));
    },[])

    if (name === undefined) {
        return (
            <div className='names'>
                <Loader />
            </div>
        )
    }

    return (
        <div className="names">
        <Formik
            initialValues={{
                first: name.first  ?? "",
                last: name.last  ?? "",
            }}
            validationSchema={Yup.object({
                first: Yup.string().required("Enter your first name"),
                last: Yup.string().required("Enter your last name")
            })}
            onSubmit={(values) => {
                setShow(true);
                Data.UpdateName(values).then(() => setShow(false));
                Resendtoken(props.username).then((r) => {
                    SetAuthContext(r);
                    props.modalContext.setShowTextNavigate("Name updated");
                })
            }}
        >
            {(data) => {
                let error = showError.bind(this, data);
                return (
                    <Form>
                        <label className={error("first")}>
                            First name
                            <Field name="first" type="text" />
                        <br/>
                        <span className='error-message'><ErrorMessage name="first" /></span>
                        </label>
                        <label className={error("last")}>
                            Last name
                            <Field name="last" type="text" />
                        <br/>
                        <span className='error-message'><ErrorMessage name="last" /></span>
                        </label>
                        <div className="button-container">
                            <LoaderButton show={show} type="submit" buttonStyle="button-max">Save</LoaderButton>
                        </div>
                    </Form>
                )
            }}
        </Formik>
        </div>
    )
}

function Theme() {
    let storedTheme = localStorage.getItem("theme");
    if (storedTheme === null) storedTheme = "light";

    const [theme, setTheme] = useState(storedTheme);

    var themeChange = (e) => {
        setTheme(e.target.value);
        localStorage.setItem("theme", e.target.value);
        document.documentElement.className = e.target.value;
        Data.UpdateTheme(e.target.value);
    }
    const checked = (value) => value === theme;

    return (
        <div className='theme-selection'>
            <h2>Colour mode</h2>
            <label><input name="theme" onChange={themeChange} checked={checked("light")} type="radio" value="light" />Light</label>
            <br />
            <label><input name="theme" onChange={themeChange} checked={checked("dark")} type="radio" value="dark" />Dark</label>
        </div>
    );
}

export function EditProfile() {
    const authContext = useContext(AuthContext);
    const username = authContext.user().username;

    const modalContext = useContext(ModalContext);

    return (
        <div className="profile content">
            <h1>Edit profile</h1>
            <Name modalContext={modalContext} username={username}/>
            <Theme />
        </div>
    )
}
