import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import * as Data from './Data';
import SecureImage from '../../shared/auth/SecureImage';
import { ProfileCrop, Alerts } from '../../shared/settings/Settings';
import './Settings.scss';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Loader, LoaderButton, showError } from '../../shared/layout/Layout';
import { Modal, ModalContext } from '../../shared/Modals';
import * as Icon from '../../shared/Icons';
import { Resendtoken } from '../../shared/auth/Data';
import SetAuthContext from '../../shared/auth/Auth';
import { AuthContext } from '../auth/Auth';
import BackButton from '../../shared/navigation';
import { CaseContext } from '../cases/Cases';


function Name(props) {
    const [name, setName] = useState(undefined);
    const [show, setShow] = useState(false);

    useEffect(() => {
        Data.GetName().then(name => setName(name));
    },[])

    if (name === undefined) {
        return (
            <div className='names'>
                <Loader></Loader>
            </div>
        )
    }

    return (
        <div className="names">
        <Formik
            initialValues={{
                first: name.first  ?? "",
                last: name.last  ?? "",
                nickname: name.nickname ?? ""
            }}
            validationSchema={Yup.object({
                first: Yup.string().required("Enter your first name"),
                last: Yup.string().required("Enter your last name"),
                nickname: Yup.string()
            })}
            onSubmit={(values) => {
                setShow(true);
                Data.SetName(values).then(() => setShow(false));
                Resendtoken(props.username).then((r) => {
                    SetAuthContext(r);
                    props.modalContext.setShowTextNavigate("Updated name");
                })
            }}
        >
            {(data) => {
                let error = showError.bind(this, data);
                return (
                    <Form>
                        <label className={error("nickname")}>
                            Nickname
                            <Field name="nickname" type="text" />
                        <br/>
                        <span className='error-message'><ErrorMessage name="nickname" /></span>
                        </label>
                        <label className={error("first")}>
                            First name
                            <Field name="first" type="text" />
                        <br/>
                        <span className='error-message'><ErrorMessage name="first" /></span>
                        </label>
                        <label className={error("last")}>
                            Last name
                            <Field name="last" type="text" />
                        <br/>
                        <span className='error-message'><ErrorMessage name="last" /></span>
                        </label>
                        <div className='button-container'>
                            <LoaderButton show={show} type="submit" buttonStyle="button-max">Save</LoaderButton>
                        </div>
                    </Form>
                )
            }}
        </Formik>
        </div>
    )
}

function Theme() {
    let storedTheme = localStorage.getItem("theme");
    if (storedTheme === null) storedTheme = "light";

    const [theme, setTheme] = useState(storedTheme);

    var themeChange = (e) => {
        setTheme(e.target.value);
        localStorage.setItem("theme", e.target.value);
        document.documentElement.className = e.target.value;
        Data.UpdateTheme(e.target.value);
    }
    const checked = (value) => value === theme;

    return (
        <div className='theme-selection'>
            <h2>Colour mode</h2>
            <label><input name="theme" onChange={themeChange} checked={checked("light")} type="radio" value="light" />Light</label>
            <br />
            <label><input name="theme" onChange={themeChange} checked={checked("dark")} type="radio" value="dark" />Dark</label>
        </div>
    );
}


function Picture(props) {
    const [img, setImg] = useState(Math.floor(Math.random() * 100));
    const src = "practitioner/profile-picture?t=" + img;
    const [imgSrc, setImgSrc] = useState('');
    const [showCrop, setShowCrop] = useState(false);
    const [show, setShow] = useState(false);

    const stageUpload = (e) => {
        if (e.target.files.length === 0) {
            return;
        }

        const reader = new FileReader();
        reader.addEventListener('load', () => {
            setImgSrc(reader.result.toString());
            setShowCrop(true);
        });
        reader.readAsDataURL(e.target.files[0]);
    }

    const upload = (data) => {
        Data.UploadProfilePicture(data).then(() =>{
            setImg(Math.floor(Math.random() * 100));
            setShowCrop(false);
            props.modalContext.setShowTextNavigate("Profile picture updated");
        });
    }
    const cancelCrop = () => {
        setShowCrop(false);
    }

    const deletePicture = (bool) => {
        if (bool) {
            Data.DeleteProfilePicture().then(() => setImg(Math.floor(Math.random() * 100)));
            props.modalContext.setShowTextNavigate("Profile picture deleted");
        }
    }

    const deletePictureModal = () => {
        setShow(true);
    }

    return (
        <div className='picture'>
            <h2>Profile picture</h2>
            <div className='picture-container'>
                <SecureImage className='img' url={src} alt="profile picture" />
                <div className='picture-file'>
                    <label className='button button-small'>
                        Upload new image
                        <input type="file" accept="image/*" onChange={stageUpload} />
                    </label>
                    <span className='delete' onClick={deletePictureModal}>Delete</span>
                </div>
            </div>
            <ProfileCrop src={imgSrc} show={showCrop} upload={upload} cancel={cancelCrop}/>
            <br />
            <Modal show={show} submit={() => {deletePicture(true); setShow(false);}} handleClose={() => setShow(false)} text="Are you sure you want to delete your profile picture?" />
        </div>
    );
}

function Bio(props) {
    const [text, setText] = useState("")
    const [show, setShow] = useState(false);

    useEffect(() => {
        Data.GetBio().then(response => setText(response.info));
    }, [])

    const updateProfile = (e) => {
        setText(e.target.value);
    }
    const saveProfile = (e) => {
        setShow(true);
        Data.UpdateBio({ info: text }).then(() => {
            setShow(false);
            props.modalContext.setShowTextNavigate("Bio updated");
        });
    }

    return (
        <div className='bio'>
            <h2>Bio</h2>
                <p>This will be visible to the children you manage.</p>
            <label>
                <textarea rows={5} onChange={updateProfile} id="contact-info" typeof='textarea' placeholder='Enter some details about yourself' value={text}></textarea><br />
            </label>
            <div className='button-container'>
                <LoaderButton show={show} submit={saveProfile} buttonStyle="button-max">Save</LoaderButton>
            </div>
        </div>
    );
}

function ContactDetails(props){
    const [details, setDetails] = useState(undefined);
    const [show, setShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(()=>{
        Data.GetContactDetails().then(d => setDetails(d));
    }, [])

    if (details === undefined) {
        return (
            <div>
                <h2>Contact details</h2>
                <Loader></Loader>
            </div>
        )
    }

    return (
        <div>
            <h2>Contact details</h2>
            <Formik
                initialValues={{
                    contact: details.contact + ""  ?? "",
                    mobile: details.mobile  ?? "",
                    email: details.email ?? ""
                }}
                validationSchema={Yup.object({
                    contact: Yup.string().required('Choose a contact method'),
                    mobile: Yup.string().when("contact", {
                        is: '0',
                        then: Yup.string().required('Enter your mobile number')
                    }),
                    email: Yup.string().email('Enter a valid email').when("contact", {
                        is: '1',
                        then: Yup.string().required('Enter your email')
                    }),
                })}
                onSubmit={(values) => {
                    values.contact = Number(values.contact);
                    setShow(true);
                    Data.UpdateContactDetails(values).then(u => {
                        setShow(false);
                        if (u === 400) {
                            setErrorMessage("Enter a valid mobile number or email address.")
                        }
                        else {
                            setErrorMessage("");
                            props.modalContext.setShowTextNavigate("Contact details updated");
                        }
                    });
                    values.contact = values.contact + "";
                    setShow(true);
                }}
            >
            {(data) => {
                    let error = showError.bind(this, data);
                    return(
                        <Form>
                            <label className={error("contact")}>
                                Contact method
                            </label>
                            <label className={error("contact") + " no-padding"}>
                                <Field name="contact" type="radio" value="0" checked={data.values.contact.toString() === "0"}/>
                                Mobile phone
                            </label>
                                <br />
                            <label className={error("contact") + " no-padding"}>
                                <Field name="contact" type="radio" value="1" checked={data.values.contact.toString() === "1"}/>
                                Email
                                <br />
                                <span className='error-message'><ErrorMessage name="contact" /></span>
                            </label>
                            <br />
                            <label className={error("mobile")}>
                                Mobile number
                                <Field name="mobile" />
                                <span className='error-message'><ErrorMessage name="mobile" /></span>
                            </label>
                            <label className={error("email")}>
                                Email
                                <Field name="email" />
                                <span className='error-message'><ErrorMessage name="email" /></span>
                            </label>
                            <br />
                            <span className='error-message'>{errorMessage}</span>
                            <div className='button-container'>
                                <LoaderButton show={show} type="submit" buttonStyle="button-max">Save</LoaderButton>
                            </div>
                        </Form>
                        )}
                }
            </Formik>
        </div>

    );
}

function EditProfile(props) {
    const authContext = useContext(AuthContext);
    var caseContext = useContext(CaseContext);
    const username = authContext.user().username;
    const modalContext = useContext(ModalContext);

    useEffect(()=>{ caseContext.clearCase(); }, [caseContext])

    return (
        <div className='profile content'>
            <BackButton />
            <h1>Edit profile</h1>
            <Name modalContext={modalContext} username={username}/>
            <Theme />
            <Picture modalContext={modalContext} />
            <Bio modalContext={modalContext} />
            <ContactDetails modalContext={modalContext} />
        </div>
    );
}

function AlertPage(props) {
    var caseContext = useContext(CaseContext);

    useEffect(()=>{ caseContext.clearCase(); }, [caseContext])
    
    return (
        <div className='content'>
            <BackButton />
            <Alerts />
        </div>
    )
}

function Settings(props) {
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [overflow, setOverflow] = useState("hidden");
    const navigate = useNavigate();
    
    const openCloseSettings = () => {
        setOpen(!open);
        document.body.style.overflowY = overflow;
        overflow === "hidden" ? setOverflow("scroll") : setOverflow("hidden");
    }

    return (
        <div key="item" className='item'>
            <div id="settings" className="overlay" style={open ? { width: "100%" } : { width: "0%" }}>
                <div className="closebtn" onClick={openCloseSettings}><Icon.Close/></div>
                <div className="content" >
                    <title>Settings</title>
                    <div onClick={openCloseSettings}>
                        <Link className='content-item' to="/settings/profile"><span>Edit profile</span></Link>
                        <Link className='content-item' to="/settings/alerts">Alerts</Link>
                        <Link className='content-item' to="/settings/about">About the app</Link>
                        <Link className='content-item' to="/settings/termsofuse">Terms of use</Link>
                        <Link className='content-item' to="/settings/privacy">Privacy & cookies</Link>
                        <Link className='content-item' to="/settings/accessibility">Accessibility</Link>
                    </div>
                    <Modal show={show} submit={() => {setShow(false); navigate("/logout");}} handleClose={() => setShow(false)} text="Are you sure you want to log out?"></Modal>
                    <span className='clickable content-item' onClick={() => setShow(true)}>Log out</span>
                </div>
            </div>
            <div className="clickable" onClick={openCloseSettings}>
                <div className={props.navClass}>
                    <Icon.Settings />
                </div>
            </div>
        </div>
    )
}

export { Settings, EditProfile, AlertPage, Theme };