import { Outlet } from "react-router-dom";
import { Child } from "./Child";
import { MyStuff } from "./mystuff/MyStuff";
import GoalsRoutes from "./goals/Routes"
import MyStuffRoutes from "./mystuff/Routes";
import TimelineRoutes from "./timeline/Routes";

const ChildRoutes = {
    path: "child",
    element: <Outlet />,
    children: [
        {
            path: "",
            element: <Child />
        },
        {
            path: "mystuff",
            element: <MyStuff/>
        },
        GoalsRoutes,
        MyStuffRoutes,
        TimelineRoutes
    ]
};

export default ChildRoutes;