class ServiceWorker {
    register(){
        if('serviceWorker' in navigator){
            navigator.serviceWorker.register("/sw.js");
        }else{
            alert("Service Workers are not supported");
        }
    }
    unregister(){
        if('serviceWorker' in navigator){
            navigator.serviceWorker.getRegistrations()
                .then(registrations => {
                    for(let registration of registrations){
                        registration.unregister();
                    }
                })

        }else{
            // nothing to unregister
        }
    }
}
 
export default ServiceWorker;