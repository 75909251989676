import { useEffect, useState, createContext, useContext } from "react";
import * as Data from "./Data";
import './notifications.scss';
import { ItemLink, ItemArea } from "../../shared/Events";
import { CaseContext } from "../cases/Cases";
import { IconButton, Loader } from "../../shared/layout/Layout";
import * as Icon from "../../shared/Icons";
import BackButton from "../../shared/navigation";

const NotificationItem = function (props){
    const n = props.item;

    return (
        <li>
            <ItemLink item={n} child={false}>
                <p>{n.content}</p>
            </ItemLink>
        </li>
    )
}

const Notifications = function (props) {
    let caseContext = useContext(CaseContext);
    let caseItem = caseContext.case;
    const noteContext = useContext(NotificationContext);

    const [notifications, setNotifications] = useState([]);
    const [childNotifications, setChildNotifications] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (caseItem === undefined) {
            Data.GetNotifications().then(n => {
                setNotifications(n);
                setLoading(false);
            })
        }
        else {
            Data.GetChildNotifications(caseItem.childId).then(c => {
                setChildNotifications(c);
                setLoading(false);
            })
        }
    }, [caseItem]);

    const mapNotification = (n) => {
        return  <NotificationItem key={n.id} item={n}/>
    }

    const notes = caseItem === undefined ? notifications.length === 0 ? <p>You have no new notifications.</p> : notifications.map(mapNotification) : childNotifications.map(mapNotification);

    const clearNotifications = () => {
        Data.ReadAllNotifications().then(()=> noteContext.refresh());
        setNotifications([]);
    }

    const clearButton = (notifications === undefined || notifications.length === 0)
    ? <></> 
    : <IconButton icon={<Icon.ClearNotifications />} submit={clearNotifications} className="button-secondary">Clear notifications</IconButton>;

    return (
        <div className="content notifications">
            <BackButton />
            <h1>Notifications</h1>
            <div className="button-container">
                {clearButton}
            </div>
            <ul className="events-list">
                {loading ? <Loader /> : notes}
            </ul>
        </div>
    )
}

const NotificationContext = createContext({
    refresh: () => {},
    readNotification: (itemArea, itemId) => {
        // refresh navigation
        // send message
    }
});


const notificationData = "notification-data";

const ReadNotificationItem = (itemArea, itemId) => {
    var data = sessionStorage.getItem(notificationData);

    if (data === undefined || data === "") {
        return Data.ReadNotification(itemArea, itemId);
    }

    const current = JSON.parse(data);
    var send = false;
    switch (itemArea){
        case ItemArea.Messages:
            send = current.Messages !== 0;
            break;
        case ItemArea.Goals:
            send = current.Goals !== 0;
            break;
        case ItemArea.Tasks:
            send = current.Tasks !== 0;
            break;
        case ItemArea.Stuff:
            send = current.Stuff !== 0;
            break;
        case ItemArea.Appointments:
            send = current.Appointments !== 0;
            break;
        case ItemArea.Settings:
            send = current.Settings !== 0;
            break;
        case ItemArea.Cases:
            send = current.Cases !== 0;
            break;
        default:
            break;
    }

    if(send){
        return Data.ReadNotification(itemArea, itemId);
    }

    return new Promise(() => { return [] }, () => { });

}

const GetNotificationTotals = (caseItem) => {
    if(caseItem === undefined){
        return Data.GetTotals().then(t => {
            sessionStorage.setItem(notificationData, JSON.stringify(t));
            return t;
        });
    }
    return Data.GetChildTotals(caseItem.childId).then(t => {
        return t;
    });
}

export { Notifications, NotificationItem, GetNotificationTotals, ReadNotificationItem, NotificationContext }