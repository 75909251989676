export const BackArrow = (props) => {
    return (
        <i className="icon" role="button" alt="go back">
            <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                <path d="M 24 4 C 12.972066 4 4 12.972074 4 24 C 4 35.027926 12.972066 44 24 44 C 35.027934 44 44 35.027926 44 24 C 44 12.972074 35.027934 4 24 4 z M 24 7 C 33.406615 7 41 14.593391 41 24 C 41 33.406609 33.406615 41 24 41 C 14.593385 41 7 33.406609 7 24 C 7 14.593391 14.593385 7 24 7 z M 26.470703 13.986328 A 1.50015 1.50015 0 0 0 25.439453 14.439453 L 17.439453 22.439453 A 1.50015 1.50015 0 0 0 17.439453 24.560547 L 25.439453 32.560547 A 1.50015 1.50015 0 1 0 27.560547 30.439453 L 20.621094 23.5 L 27.560547 16.560547 A 1.50015 1.50015 0 0 0 26.470703 13.986328 z" />
            </svg>
        </i>
    )
}

export const FrontArrow = (props) => {
    return (
        <i className="icon" role="button" alt={props.alt}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <g data-name="Circle kanan">
                    <path d="M12 22a10 10 0 1 1 10-10 10.011 10.011 0 0 1-10 10zm0-18a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8z" />
                    <path d="M11 16a1 1 0 0 1-.707-1.707L12.586 12l-2.293-2.293a1 1 0 0 1 1.414-1.414l3 3a1 1 0 0 1 0 1.414l-3 3A1 1 0 0 1 11 16z" />
                </g>
            </svg>
        </i>
    )
}

export const Messages = (props) => {
    return (
        <i className="icon" role="button" alt="messages button">
            <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="1 1 45 45">
                <path d="M 24 3.9980469 C 12.972292 3.9980469 4 12.970339 4 23.998047 C 4 27.273363 4.8627078 30.334853 6.2617188 33.064453 L 4.09375 40.826172 C 3.5887973 42.629575 5.3719261 44.41261 7.1757812 43.908203 L 14.943359 41.740234 C 17.670736 43.136312 20.727751 43.998047 24 43.998047 C 35.027708 43.998047 44 35.025755 44 23.998047 C 44 12.970339 35.027708 3.9980469 24 3.9980469 z M 24 6.9980469 C 33.406292 6.9980469 41 14.591755 41 23.998047 C 41 33.404339 33.406292 40.998047 24 40.998047 C 20.998416 40.998047 18.190601 40.217527 15.742188 38.853516 A 1.50015 1.50015 0 0 0 14.609375 38.71875 L 7.2226562 40.779297 L 9.2851562 33.396484 A 1.50015 1.50015 0 0 0 9.1503906 32.261719 C 7.7836522 29.811523 7 27.002565 7 23.998047 C 7 14.591755 14.593708 6.9980469 24 6.9980469 z M 17 22 C 15.895 22 15 22.895 15 24 C 15 25.105 15.895 26 17 26 C 18.105 26 19 25.105 19 24 C 19 22.895 18.105 22 17 22 z M 24 22 C 22.895 22 22 22.895 22 24 C 22 25.105 22.895 26 24 26 C 25.105 26 26 25.105 26 24 C 26 22.895 25.105 22 24 22 z M 31 22 C 29.895 22 29 22.895 29 24 C 29 25.105 29.895 26 31 26 C 32.105 26 33 25.105 33 24 C 33 22.895 32.105 22 31 22 z" />
            </svg>
        </i>
    )
}

export const Appointments = (props) => {
    return (
        <i className="icon" role="button" alt="appointments button">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="-3 -10 114 114">
                <path d="m30,0C18.46,0,9,9.46,9,21s9.46,21,21,21,21-9.46,21-21S41.54,0,30,0Zm48,0c-11.54,0-21,9.46-21,21s9.46,21,21,21,21-9.46,21-21S89.54,0,78,0ZM30,9c6.68,0,12,5.32,12,12s-5.32,12-12,12-12-5.32-12-12,5.32-12,12-12Zm48,0c6.68,0,12,5.32,12,12s-5.32,12-12,12-12-5.32-12-12,5.32-12,12-12ZM10.5,48c-5.79,0-10.5,4.71-10.5,10.5v7.5c0,16.54,13.46,30,30,30,7.63,0,14.58-2.88,19.88-7.59-1.91-2.39-3.52-5.03-4.78-7.86-3.82,3.97-9.17,6.45-15.1,6.45-11.58,0-21-9.42-21-21v-7.5c0-.83.67-1.5,1.5-1.5h31.58c.31-3.4,1.64-6.51,3.7-9H10.5Zm48,0c-5.74,0-10.5,4.76-10.5,10.5v7.5c0,16.51,13.49,30,30,30s30-13.49,30-30v-7.5c0-5.74-4.76-10.5-10.5-10.5h-39Zm0,9h39c.89,0,1.5.61,1.5,1.5v7.5c0,11.65-9.35,21-21,21s-21-9.35-21-21v-7.5c0-.89.61-1.5,1.5-1.5Z" />
            </svg>
        </i>
    )
}

export const Briefcase = (props) => {
    return (
        <i className="icon" role="button" alt={props.alt}>
            <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="2 3 43 43">
                <path d="M 18.5 6 C 16.585045 6 15 7.5850452 15 9.5 L 15 12 L 7.5 12 C 5.5850452 12 4 13.585045 4 15.5 L 4 39.5 C 4 41.414955 5.5850452 43 7.5 43 L 40.5 43 C 42.414955 43 44 41.414955 44 39.5 L 44 15.5 C 44 13.585045 42.414955 12 40.5 12 L 33 12 L 33 9.5 C 33 7.5850452 31.414955 6 29.5 6 L 18.5 6 z M 18.5 9 L 29.5 9 C 29.795045 9 30 9.2049548 30 9.5 L 30 12 L 18 12 L 18 9.5 C 18 9.2049548 18.204955 9 18.5 9 z M 7.5 15 L 40.5 15 C 40.795045 15 41 15.204955 41 15.5 L 41 29 L 27 29 L 27 28.5 C 27 27.672 26.328 27 25.5 27 L 22.5 27 C 21.672 27 21 27.672 21 28.5 L 21 29 L 7 29 L 7 15.5 C 7 15.204955 7.2049548 15 7.5 15 z M 7 32 L 21 32 L 21 32.5 C 21 33.328 21.672 34 22.5 34 L 25.5 34 C 26.328 34 27 33.328 27 32.5 L 27 32 L 41 32 L 41 39.5 C 41 39.795045 40.795045 40 40.5 40 L 7.5 40 C 7.2049548 40 7 39.795045 7 39.5 L 7 32 z" />
            </svg>
        </i>
    )
}

export const Goals = (props) => {
    return (
        <i className="icon" role="button" alt="my goals button">
            <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                <path d="M 7.5 5 C 6.122 5 5 6.122 5 7.5 L 5 13.5 C 5 14.878 6.122 16 7.5 16 L 13.5 16 C 14.878 16 16 14.878 16 13.5 L 16 7.5 C 16 6.122 14.878 5 13.5 5 L 7.5 5 z M 20.5 9 A 1.50015 1.50015 0 1 0 20.5 12 L 41.5 12 A 1.50015 1.50015 0 1 0 41.5 9 L 20.5 9 z M 7.5 18.5 C 6.122 18.5 5 19.622 5 21 L 5 27 C 5 28.378 6.122 29.5 7.5 29.5 L 13.5 29.5 C 14.878 29.5 16 28.378 16 27 L 16 21 C 16 19.622 14.878 18.5 13.5 18.5 L 7.5 18.5 z M 20.5 22.5 A 1.50015 1.50015 0 1 0 20.5 25.5 L 41.5 25.5 A 1.50015 1.50015 0 1 0 41.5 22.5 L 20.5 22.5 z M 7.5 32 C 6.1364058 32 5 33.136406 5 34.5 L 5 40.5 C 5 41.863594 6.1364058 43 7.5 43 L 13.5 43 C 14.863594 43 16 41.863594 16 40.5 L 16 34.5 C 16 33.136406 14.863594 32 13.5 32 L 7.5 32 z M 8 35 L 13 35 L 13 40 L 8 40 L 8 35 z M 20.5 36 A 1.50015 1.50015 0 1 0 20.5 39 L 41.5 39 A 1.50015 1.50015 0 1 0 41.5 36 L 20.5 36 z" />
            </svg>
        </i>
    )
}

export const Home = (props) => {
    return (
        <i className="icon" role="button" alt="home button">
            <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                <path d="M 23.951172 4 A 1.50015 1.50015 0 0 0 23.072266 4.3222656 L 8.859375 15.519531 C 7.0554772 16.941163 6 19.113506 6 21.410156 L 6 40.5 C 6 41.863594 7.1364058 43 8.5 43 L 18.5 43 C 19.863594 43 21 41.863594 21 40.5 L 21 30.5 C 21 30.204955 21.204955 30 21.5 30 L 26.5 30 C 26.795045 30 27 30.204955 27 30.5 L 27 40.5 C 27 41.863594 28.136406 43 29.5 43 L 39.5 43 C 40.863594 43 42 41.863594 42 40.5 L 42 21.410156 C 42 19.113506 40.944523 16.941163 39.140625 15.519531 L 24.927734 4.3222656 A 1.50015 1.50015 0 0 0 23.951172 4 z M 24 7.4101562 L 37.285156 17.876953 C 38.369258 18.731322 39 20.030807 39 21.410156 L 39 40 L 30 40 L 30 30.5 C 30 28.585045 28.414955 27 26.5 27 L 21.5 27 C 19.585045 27 18 28.585045 18 30.5 L 18 40 L 9 40 L 9 21.410156 C 9 20.030807 9.6307412 18.731322 10.714844 17.876953 L 24 7.4101562 z" />
            </svg>
        </i>
    )
}

export const Notification = (props) => {
    return (
        <i className="icon" role="button" alt="notification button">
            <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="2 1 45 45">
                <path d="M 23.277344 4.0175781 C 15.193866 4.3983176 9 11.343391 9 19.380859 L 9 26.648438 L 6.3496094 31.980469 A 1.50015 1.50015 0 0 0 6.3359375 32.009766 C 5.2696804 34.277268 6.9957076 37 9.5019531 37 L 18 37 C 18 40.295865 20.704135 43 24 43 C 27.295865 43 30 40.295865 30 37 L 38.496094 37 C 41.002339 37 42.730582 34.277829 41.664062 32.009766 A 1.50015 1.50015 0 0 0 41.650391 31.980469 L 39 26.648438 L 39 19 C 39 10.493798 31.863289 3.6133643 23.277344 4.0175781 z M 23.417969 7.0136719 C 30.338024 6.6878857 36 12.162202 36 19 L 36 27 A 1.50015 1.50015 0 0 0 36.15625 27.667969 L 38.949219 33.289062 C 39.128826 33.674017 38.921017 34 38.496094 34 L 9.5019531 34 C 9.077027 34 8.8709034 33.674574 9.0507812 33.289062 C 9.0507812 33.289062 9.0507812 33.287109 9.0507812 33.287109 L 11.84375 27.667969 A 1.50015 1.50015 0 0 0 12 27 L 12 19.380859 C 12 12.880328 16.979446 7.3169324 23.417969 7.0136719 z M 21 37 L 27 37 C 27 38.674135 25.674135 40 24 40 C 22.325865 40 21 38.674135 21 37 z" />
            </svg>
        </i>
    )
}

export const Settings = (props) => {
    return (
        <i className="icon" role="button" alt="settings button">
            <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="-8 -6 130 130">
                <path d="M57.62,0c-4.73,0-9.29,.6-13.65,1.61-1.86,.43-3.24,2-3.45,3.9l-.96,8.71c-.31,2.85-1.96,5.38-4.45,6.81-2.48,1.43-5.49,1.59-8.12,.44h0l-8-3.52c-1.75-.77-3.79-.35-5.1,1.04C7.68,25.62,2.9,33.65,.19,42.6c-.55,1.83,.11,3.8,1.65,4.93l7.1,5.2c2.32,1.7,3.68,4.39,3.68,7.26s-1.36,5.56-3.68,7.26l-7.1,5.2c-1.54,1.13-2.2,3.11-1.65,4.93,2.71,8.96,7.48,16.99,13.7,23.62,1.31,1.39,3.35,1.81,5.1,1.04l8-3.52c2.63-1.16,5.64-.99,8.13,.45,2.49,1.44,4.13,3.96,4.45,6.81l.96,8.71c.21,1.9,1.59,3.45,3.45,3.89,4.36,1.02,8.92,1.62,13.65,1.62s9.29-.6,13.65-1.61c1.86-.43,3.24-2,3.45-3.9l.96-8.71c.31-2.85,1.96-5.38,4.45-6.81,2.48-1.43,5.49-1.6,8.12-.45l8,3.52c1.75,.77,3.79,.36,5.1-1.04,6.22-6.63,10.99-14.66,13.7-23.62,.55-1.83-.11-3.8-1.65-4.93l-7.1-5.2c-2.32-1.7-3.68-4.39-3.68-7.26s1.36-5.56,3.68-7.26l7.1-5.2c1.54-1.13,2.2-3.11,1.65-4.93-2.71-8.96-7.48-16.99-13.7-23.62-1.31-1.39-3.35-1.81-5.1-1.04l-8,3.52c-2.63,1.15-5.64,.99-8.12-.45-2.49-1.44-4.13-3.96-4.45-6.81l-.96-8.71c-.21-1.9-1.59-3.45-3.45-3.89-4.36-1.02-8.92-1.62-13.65-1.62Zm0,9c2.92,0,5.73,.52,8.54,1.02l.56,5.17c.62,5.7,3.94,10.76,8.89,13.63,4.96,2.87,11,3.2,16.25,.89l4.76-2.09c3.65,4.39,6.55,9.34,8.57,14.77l-4.22,3.09c-4.62,3.39-7.35,8.78-7.35,14.51s2.73,11.13,7.35,14.51l4.22,3.09c-2.02,5.43-4.91,10.38-8.57,14.77l-4.76-2.09c-5.24-2.31-11.29-1.98-16.25,.89-4.96,2.87-8.27,7.93-8.89,13.63l-.56,5.17c-2.81,.49-5.62,1.02-8.54,1.02s-5.73-.52-8.54-1.02l-.56-5.17c-.62-5.7-3.94-10.76-8.89-13.63-4.96-2.87-11-3.2-16.25-.89l-4.76,2.09c-3.65-4.39-6.55-9.34-8.57-14.77l4.22-3.09c4.62-3.39,7.35-8.78,7.35-14.51s-2.74-11.13-7.35-14.52l-4.22-3.09c2.02-5.43,4.92-10.38,8.57-14.77l4.75,2.09c5.24,2.31,11.29,1.98,16.25-.88,4.96-2.87,8.27-7.93,8.89-13.63l.56-5.17c2.81-.49,5.62-1.02,8.54-1.02Zm0,27c-13.2,0-24,10.8-24,24s10.8,24,24,24,24-10.8,24-24-10.8-24-24-24Zm0,9c8.34,0,15,6.66,15,15s-6.66,15-15,15-15-6.66-15-15,6.66-15,15-15Z" />
            </svg>
        </i>
    )
}

export const Add = (props) => {
    return (
        <i className="icon icon-smaller" role="button" alt="add stuff">
            <svg fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="2 2 20 20">
                <path d="M12,2C6.477,2,2,6.477,2,12s4.477,10,10,10s10-4.477,10-10S17.523,2,12,2z M16,13h-3v3c0,0.552-0.448,1-1,1h0 c-0.552,0-1-0.448-1-1v-3H8c-0.552,0-1-0.448-1-1v0c0-0.552,0.448-1,1-1h3V8c0-0.552,0.448-1,1-1h0c0.552,0,1,0.448,1,1v3h3 c0.552,0,1,0.448,1,1v0C17,12.552,16.552,13,16,13z"/>
            </svg>
        </i>
    )
}

export const User = (props) => {
    return (
        <i className="icon" role="button" alt="user case button">
            <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="5 12 110 110" width="512px" height="512px">
                <path d="M 64 27 C 51.3 27 41 37.3 41 50 C 41 62.7 51.3 73 64 73 C 76.7 73 87 62.7 87 50 C 87 37.3 76.7 27 64 27 z M 64 33 C 73.4 33 81 40.6 81 50 C 81 59.4 73.4 67 64 67 C 54.6 67 47 59.4 47 50 C 47 40.6 54.6 33 64 33 z M 64 81 C 47.6 81 32.400781 89.899609 24.300781 104.09961 C 23.500781 105.49961 24 107.39922 25.5 108.19922 C 26 108.49922 26.5 108.59961 27 108.59961 C 28 108.59961 29.099609 108.09961 29.599609 107.09961 C 36.599609 94.699609 49.8 87 64 87 C 78.2 87 91.4 94.699609 98.5 107.09961 C 99.3 108.49961 101.19961 108.99922 102.59961 108.19922 C 103.99961 107.39922 104.49922 105.49961 103.69922 104.09961 C 95.599219 89.899609 80.4 81 64 81 z" />
            </svg>
        </i>
    )
}

export const WhoIAm = (props) => {
    return (
        <i className="icon-stuff" role="button" alt="who I am button">
            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 133 127.33">
                <g id="Layer_1-2" data-name="Layer 1">
                    <g>
                        <circle cx="66.03" cy="83.38" r="6.08" />
                        <path className="cls-4" d="m66.62,6.46C32.71,6.46,5.21,29.39,5.21,57.67c0,16.58,9.45,31.3,24.1,40.66-1.39,6.79-4.21,19.98-5.84,23.18-2.23,4.36,14.99-1.67,21.23-6.66,2.17-1.74,4.64-4.57,6.95-7.52,4.79,1,9.81,1.54,14.97,1.54,33.91,0,61.41-22.93,61.41-51.21S100.53,6.46,66.62,6.46Z" />
                        <g>
                            <path className="cls-1" d="m21.12,126.36l-5.96-.1,3.45-4.81c3.26-4.61,5.59-9.48,6.93-14.47.68-2.52.75-3.55.77-4.15C10.2,91.86.97,75.54.97,57.99.97,26.55,30.37.97,66.5.97s65.53,25.58,65.53,57.02-29.4,57.02-65.53,57.02c-4.92,0-10.14-.62-15.96-1.9-2.94,3.39-12.93,13.24-29.25,13.24h-.18ZM66.5,7.24C33.82,7.24,7.24,30,7.24,57.99c0,16.25,8.77,31.12,24.06,40.81l1.46.94v1.68c0,.12-.04,3-1.17,7.21-.73,2.71-2.29,6.3-4.53,10.4,12.2-2.42,19.94-11.01,20.02-11.1l1.23-1.54,1.88.42c5.3,1.29,10.78,1.94,16.29,1.94,32.68,0,59.26-22.77,59.26-50.76S99.18,7.24,66.5,7.24Z" />
                            <path className="cls-2" d="m66.5,1.45c35.68,0,65.05,25.14,65.05,56.55s-29.37,56.55-65.05,56.55c-5.57,0-10.96-.8-16.13-1.95-1.58,1.86-11.66,13.29-29.07,13.29-.06,0-.12,0-.18,0l-5.05-.09,2.92-4.08c4.07-5.76,6.02-10.96,7-14.62.8-2.97.78-3.91.8-4.52C11.55,92.29,1.45,76.22,1.45,57.99,1.45,26.58,30.8,1.45,66.5,1.45M26.17,119.68c13.07-2.19,21.27-11.43,21.27-11.43l1.06-1.33,1.6.35c5.2,1.26,10.71,1.95,16.4,1.95,33.23,0,59.74-23.12,59.74-51.23S99.73,6.76,66.5,6.76,6.76,29.88,6.76,57.99c0,16.85,9.46,31.82,24.28,41.21l1.24.8v1.42s-.02,2.89-1.15,7.09c-.81,3-2.6,6.94-4.96,11.17M66.5.5C30.11.5.5,26.29.5,57.99c0,17.63,9.22,34.03,25.33,45.07-.04.62-.17,1.65-.75,3.79-1.33,4.93-3.64,9.75-6.86,14.32l-2.92,4.07-1.05,1.46,1.8.03,5.05.09h.19c16.18,0,26.22-9.59,29.42-13.2,5.74,1.25,10.91,1.85,15.79,1.85,36.39,0,66-25.79,66-57.49S102.89.5,66.5.5h0ZM27.97,118.36c1.99-3.76,3.4-7.05,4.08-9.6,1.15-4.27,1.18-7.21,1.18-7.33v-1.43s0-.52,0-.52l-.43-.28-1.24-.8c-15.16-9.61-23.85-24.34-23.85-40.42C7.71,30.27,34.08,7.71,66.5,7.71s58.79,22.56,58.79,50.28-26.37,50.28-58.79,50.28c-5.48,0-10.92-.65-16.17-1.92l-1.61-.36-.58-.13-.37.46-1.05,1.31c-.36.4-7.54,8.14-18.76,10.72h0Z" />
                        </g>
                        <path d="m66.58,27.9c-12.72,0-19.93,7.13-20.13,17.44v.52h11.23v-.52c.25-4.56,3.4-7.49,8.16-7.49s7.87,2.73,7.87,6.58-1.62,5.84-6.96,9.03c-5.72,3.36-8,7.08-7.46,13.71l.07,1.77h11v-1.68c0-3.98,1.53-6.01,7.04-9.2,5.84-3.44,8.86-7.79,8.86-14,0-9.57-7.83-16.16-19.67-16.16Zm-1.49,48.74c-4.21,0-6.84,2.45-6.84,6.43s2.63,6.39,6.84,6.39,6.81-2.44,6.81-6.39-2.59-6.43-6.81-6.43Z" />
                    </g>
                </g>
            </svg>
        </i>
    )
}

export const WantToBe = (props) => {
    return (
        <i className="icon-stuff" role="button" alt="who I want to be button">
            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.86 144.97">
                <g id="Layer_1-2" data-name="Layer 1">
                    <g>
                        <g>
                            <circle className="cls-4" cx="19.6" cy="131.59" r="8.78" />
                            <circle className="cls-4" cx="53.75" cy="122.13" r="10.15" />
                            <path className="cls-4" d="M134.97,57.39c0-11.38-6.36-21.28-15.71-26.33-3.52-12.6-15.08-21.84-28.8-21.84-6.16,0-11.88,1.86-16.63,5.05-5.43-5.59-13.03-9.06-21.44-9.06-16.52,0-29.91,13.39-29.91,29.91,0,.46,.01,.92,.03,1.38-10.09,5.38-16.97,16.01-16.97,28.25,0,17.68,14.33,32.01,32.01,32.01,8.14,0,15.57-3.04,21.22-8.05,5.35,8.1,14.53,13.45,24.97,13.45,11.18,0,20.93-6.14,26.06-15.23,14.27-2.27,25.17-14.63,25.17-29.53Z" />
                        </g>
                        <path className="cls-2 cls-3" d="M50.73,1c-16.68,0-30.02,13.34-30.72,29.85C8.73,37.26,1,49.26,1,63.16c0,20.56,16.74,37.3,37.3,37.3,6.99,0,13.4-2.19,19-5.54,1.96,2.13,3.95,4.24,6.37,5.84,5.65,3.74,12.43,5.92,19.7,5.92,13.68,0,25.44-7.81,31.45-19.14,14.6-1.77,26.05-13.97,26.05-29.04,0-12.63-8.07-23.24-19.24-27.44-2.08-15.14-14.8-26.95-30.49-26.95-7.02,0-13.31,2.64-18.52,6.59-5.64-5.76-13.2-9.7-21.88-9.7Zm0,6.22c7.8,0,14.72,3.57,19.28,9.17l2.07,2.54,2.43-2.2c4.4-3.98,10.21-6.4,16.63-6.4,13.18,0,23.9,10.18,24.8,23.1l.15,2.13,2.05,.63c9.53,2.9,16.51,11.77,16.51,22.32,0,12.46-9.7,22.58-21.95,23.28l-1.84,.1-.79,1.67c-4.73,9.99-14.89,16.91-26.7,16.91-6.03,0-11.61-1.8-16.27-4.89-2.6-1.72-4.91-3.84-6.84-6.28l-1.82-2.28-2.39,1.67c-5.03,3.51-11.14,5.56-17.75,5.56-17.2,0-31.08-13.88-31.08-31.08,0-12.12,6.92-22.58,16.96-27.71l1.74-.89-.04-1.95c-.29-14.02,10.91-25.4,24.86-25.4Zm3.11,99.46c-8.55,0-15.54,6.99-15.54,15.54s6.99,15.54,15.54,15.54,15.54-6.99,15.54-15.54-6.99-15.54-15.54-15.54Zm0,6.22c5.19,0,9.32,4.14,9.32,9.32s-4.14,9.32-9.32,9.32-9.32-4.14-9.32-9.32,4.14-9.32,9.32-9.32Zm-34.19,6.22c-6.83,0-12.43,5.6-12.43,12.43s5.6,12.43,12.43,12.43,12.43-5.6,12.43-12.43-5.6-12.43-12.43-12.43Zm0,6.22c3.47,0,6.22,2.75,6.22,6.22s-2.75,6.22-6.22,6.22-6.22-2.75-6.22-6.22,2.75-6.22,6.22-6.22Z" />
                    </g>
                </g>
            </svg>
        </i>
    )
}

export const HowGetThere = (props) => {
    return (
        <i className="icon-stuff" role="button" alt="how I get there button">
            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.89 111.29">
                <g id="Layer_1-2" data-name="Layer 1">
                    <g>
                        <polygon className="cls-4" points="51.93 3.17 65.45 26.51 59.96 41.8 74.39 45.44 89.39 70.55 126.8 4.49 51.93 3.17" />
                        <path className="cls-2" d="M48.08,1.76c-.55,.99-.55,2.32,0,3.31l13.13,22.39-6.18,15.22c-.44,.99-.33,1.99,.22,2.87s1.43,1.43,2.43,1.65l16.32,2.1,13.13,22.39c.55,.99,1.65,1.65,2.87,1.65h0c1.21,0,2.32-.66,2.87-1.65L130.48,4.96c.55-.99,.55-2.32,0-3.31s-1.65-1.65-2.87-1.65h0L50.95,.11c-1.1,0-2.21,.66-2.87,1.65ZM121.99,6.62l-31.99,56.8-11.03-18.86c-.55-.88-1.43-1.43-2.43-1.65l-10.37-1.32,35.3-21.73h0c1.32-.77,1.99-2.32,1.54-3.75-.55-1.76-2.32-2.76-4.08-2.21l-32.43,9.49L56.8,6.84l65.19-.22ZM10.03,71.69c.66,1.1,1.76,1.65,2.87,1.65,.55,0,1.1-.11,1.65-.44l32.43-18.86c1.54-.88,2.1-2.98,1.21-4.52-.88-1.54-2.98-2.1-4.52-1.21L11.24,67.17c-1.54,.99-2.1,2.98-1.21,4.52Zm50.19-9.04l-4.19,3.97c-12.13,11.58-22.06,24.93-29.56,39.93-.77,1.65-.11,3.64,1.43,4.41,.44,.22,.99,.33,1.43,.33,1.21,0,2.43-.66,2.98-1.88,7.17-14.34,16.66-27.13,28.24-38.16l4.19-3.97c1.32-1.21,1.43-3.31,.11-4.63-1.21-1.21-3.31-1.21-4.63,0Zm-15.77-39.49l-20.41,6.07c-1.76,.44-2.76,2.32-2.32,4.08,.44,1.43,1.76,2.43,3.2,2.43,.33,0,.55,0,.88-.11l20.41-6.07c1.76-.44,2.76-2.32,2.32-4.08-.55-1.76-2.32-2.76-4.08-2.32ZM4.19,41.69l7.39-2.1c1.76-.44,2.76-2.32,2.32-4.08-.44-1.76-2.32-2.76-4.08-2.32l-7.39,2.1c-1.76,.44-2.76,2.32-2.32,4.08,.55,1.76,2.32,2.76,4.08,2.32Z" />
                    </g>
                </g>
            </svg>
        </i>
    )
}

export const AddMoreStuff = (props) => {
    return (
        <i className="icon-stuff" role="button" alt="add more stuff button">
            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
                <g id="Layer_1-2" data-name="Layer 1">
                    <g>
                        <circle className="cls-4" cx="60" cy="60" r="55.77" />
                        <path d="M60,0C26.92,0,0,26.92,0,60s26.92,60,60,60,60-26.92,60-60S93.08,0,60,0Zm0,9c28.22,0,51,22.78,51,51s-22.78,51-51,51S9,88.22,9,60,31.78,9,60,9Zm-.07,20.94c-2.48,.04-4.47,2.08-4.43,4.56v21h-21c-2.49-.04-4.53,1.95-4.56,4.44-.04,2.49,1.95,4.53,4.44,4.56,.04,0,.08,0,.13,0h21v21c-.04,2.49,1.95,4.53,4.44,4.56,2.49,.04,4.53-1.95,4.56-4.44,0-.04,0-.08,0-.13v-21h21c2.49,.04,4.53-1.95,4.56-4.44,.04-2.49-1.95-4.53-4.44-4.56-.04,0-.08,0-.13,0h-21v-21c.04-2.49-1.95-4.53-4.44-4.56-.04,0-.09,0-.13,0Z" />
                    </g>
                </g>
            </svg>
        </i>
    )
}

export const Close = (props) => {
    return (
        <i className="icon icon-close" role="button" alt="close button">
            <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                <path d="M 7.9785156 5.9804688 A 2.0002 2.0002 0 0 0 6.5859375 9.4140625 L 12.171875 15 L 6.5859375 20.585938 A 2.0002 2.0002 0 1 0 9.4140625 23.414062 L 15 17.828125 L 20.585938 23.414062 A 2.0002 2.0002 0 1 0 23.414062 20.585938 L 17.828125 15 L 23.414062 9.4140625 A 2.0002 2.0002 0 0 0 21.960938 5.9804688 A 2.0002 2.0002 0 0 0 20.585938 6.5859375 L 15 12.171875 L 9.4140625 6.5859375 A 2.0002 2.0002 0 0 0 7.9785156 5.9804688 z" />
            </svg>
        </i>
    )
}

export const Delete = (props) => {
    return (
        <i className="icon icon-smaller" role="button" alt="delete button">
            <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="10 10 45 45">
                <path d="M32,10c12.15,0,22,9.85,22,22s-9.85,22-22,22s-22-9.85-22-22S19.85,10,32,10z M36.95,39.778	c0.781,0.781,2.047,0.781,2.828,0c0.781-0.781,0.781-2.047,0-2.828c-0.175-0.175-2.767-2.767-4.95-4.95	c2.183-2.183,4.774-4.774,4.95-4.95c0.781-0.781,0.781-2.047,0-2.828c-0.781-0.781-2.047-0.781-2.828,0	c-0.175,0.175-2.767,2.767-4.95,4.95c-2.183-2.183-4.775-4.775-4.95-4.95c-0.781-0.781-2.047-0.781-2.828,0	c-0.781,0.781-0.781,2.047,0,2.828c0.175,0.175,2.767,2.767,4.95,4.95c-2.183,2.183-4.774,4.774-4.95,4.95	c-0.781,0.781-0.781,2.047,0,2.828c0.781,0.781,2.047,0.781,2.828,0c0.175-0.175,2.767-2.767,4.95-4.95	C34.183,37.011,36.775,39.603,36.95,39.778z" />
            </svg>
        </i>
    )
}

export const ClearNotifications = (props) => {
    return (
        <i className="icon" role="button" alt="clear notifications button">
            <svg fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="240px" height="240px">
                <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16.414,15 c0,0,3.139,3.139,3.293,3.293c0.391,0.391,0.391,1.024,0,1.414c-0.391,0.391-1.024,0.391-1.414,0C18.139,19.554,15,16.414,15,16.414 s-3.139,3.139-3.293,3.293c-0.391,0.391-1.024,0.391-1.414,0c-0.391-0.391-0.391-1.024,0-1.414C10.446,18.139,13.586,15,13.586,15 s-3.139-3.139-3.293-3.293c-0.391-0.391-0.391-1.024,0-1.414c0.391-0.391,1.024-0.391,1.414,0C11.861,10.446,15,13.586,15,13.586 s3.139-3.139,3.293-3.293c0.391-0.391,1.024-0.391,1.414,0c0.391,0.391,0.391,1.024,0,1.414C19.554,11.861,16.414,15,16.414,15z"/>
            </svg>
        </i>
    )
}

export const Bin = (props) => {
    return (
        <i className="icon icon-smaller" role="button" alt="delete button">
            <svg fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="2 2 20 20" width="192px" height="192px">
                <path d="M 10 2 L 9 3 L 5 3 C 4.448 3 4 3.448 4 4 C 4 4.552 4.448 5 5 5 L 7 5 L 17 5 L 19 5 C 19.552 5 20 4.552 20 4 C 20 3.448 19.552 3 19 3 L 15 3 L 14 2 L 10 2 z M 5 7 L 5 20 C 5 21.105 5.895 22 7 22 L 17 22 C 18.105 22 19 21.105 19 20 L 19 7 L 5 7 z"/>
            </svg>
        </i>
    )
}

export const CopyToClipboard = (props) => {
    return (
        <i className="icon icon-black" role="button" alt="copy to clipboard button">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 115.77 122.88" width="100px" height="100px">
                <path className="st0" d="M89.62,13.96v7.73h12.19h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02v0.02 v73.27v0.01h-0.02c-0.01,3.84-1.57,7.33-4.1,9.86c-2.51,2.5-5.98,4.06-9.82,4.07v0.02h-0.02h-61.7H40.1v-0.02 c-3.84-0.01-7.34-1.57-9.86-4.1c-2.5-2.51-4.06-5.98-4.07-9.82h-0.02v-0.02V92.51H13.96h-0.01v-0.02c-3.84-0.01-7.34-1.57-9.86-4.1 c-2.5-2.51-4.06-5.98-4.07-9.82H0v-0.02V13.96v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07V0h0.02h61.7 h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02V13.96L89.62,13.96z M79.04,21.69v-7.73v-0.02h0.02 c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v64.59v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h12.19V35.65 v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07v-0.02h0.02H79.04L79.04,21.69z M105.18,108.92V35.65v-0.02 h0.02c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v73.27v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h61.7h0.02 v0.02c0.91,0,1.75-0.39,2.37-1.01c0.61-0.61,1-1.46,1-2.37h-0.02V108.92L105.18,108.92z" />
            </svg>
        </i>
    )
}

export const Padlock = (props) => {
    return (
        <i className="icon" role="button" alt="locked conversation">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M18 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-10 0v-4c0-2.206 1.794-4 4-4s4 1.794 4 4v4h-8z"/>
            </svg>
        </i>
    )
}