import config from './config';

const auth = (method) => {
    const jwt = sessionStorage.getItem("jwt");
    if(navigator.serviceWorker.controller === null){
        return {
            method: method,
            withCredentials: true,
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + jwt
            }
        }
    }else{
        navigator.serviceWorker.controller.postMessage({
            type: 'STORE-TOKEN',
            token: jwt
        });
        return {
            method: method,
            withCredentials: true,
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            }
        }
    }
}


const page = (request, count, offset) => {
    request.headers["X-Count"] = count;
    request.headers["X-Offset"] = offset;

    return request;
}

const pdf = (request) => {
    request.headers["Content-Type"] = "multipart/form-data";
    request.responseType = "arraybuffer";

    return request;
}

const err = (response) => {
    if(response.status === 204) {
        return false;
    }

    if(response.ok){
        return true;
    }
    
    if(response.status === 401){
        sessionStorage.removeItem("jwt");
        if(navigator.serviceWorker.controller !== null){
            navigator.serviceWorker.controller.postMessage({
                type: 'CLEAR-TOKEN'
            });
        }
    }

    return false;
}

const data = (method, request) => {
    const info = auth(method);
    info.body = JSON.stringify(request);

    return info;
}

const api = {
    get: function (url) {
        return fetch(config.host + url, auth("GET"))
            .then(response => {
                if (err(response)) {
                    return response.json();
                }
            })
    },
    getPaged: function(url, count, offset){
        return fetch(config.host + url, page(auth("GET"), count, offset))
        .then(response => {
            if (err(response)) {
                return response.json();
            }
        })
    },
    put: function (url, request) {
        return fetch(config.host + url, data("PUT", request))
            .then(response => {
                if (err(response)) {
                    return response.json();
                }
                else {
                    return response.status;
                }
            })
    },
    putOnly: function (url, request) {
        return fetch(config.host + url, data("PUT", request));
    },
    post: function (url, request) {
        return fetch(config.host + url, data("POST", request))
            .then(response => {
                if (err(response)) {
                    return response.json();
                }
                else {
                    return response.status;
                }
            })
    },
    postOnly: function (url, request){
        return fetch(config.host + url, data("POST", request));
    },
    postText: function (url, request) {
        return fetch(config.host + url, data("POST", request))
            .then(response => {
                if (response.ok) {
                    return response.text();
                }else{
                    return false;
                }
            })
    },
    postJson: function (url, request) {
        return fetch(config.host + url, data("POST", request))
            .then(response => {
                if (response.ok) {
                    return response.json();
                }else{
                    return false;
                }
            })
    },
    postBlob: function (url) {
        return fetch(config.host + url, pdf(auth("POST")))
        .then(response => {
            if (err(response)) {
                return response.blob();
            }
        });
    },
    delete: function (url) {
        return fetch(config.host + url, data("DELETE"))
            .then(response => {
                if (err(response)) {
                }
            })
    },
    form: function (url, form) {
        const info = auth("POST");
        delete(info.headers["Content-Type"]);
        info.body = form;

        return fetch(config.host + url, info);
    },
    formJson: function (url, form) {
        const info = auth("POST");
        delete(info.headers["Content-Type"]);
        info.body = form;

        return fetch(config.host + url, info)
            .then(response => {
                if (err(response)) {
                    return response.json();
                }
            });
    },
    image: function (src) {
        const info = auth("GET");

        return fetch(src, info)
            .then(response => {
                if (err(response)) {
                    return response.blob();
                }
            })
            .then(blob => new Promise(callback => {
                const reader = new FileReader();
                reader.onload = (a) => {
                    callback(a.currentTarget.result)};
                reader.readAsDataURL(blob);
            }));
    }
}

export default api;