import config from '../../shared/config';

// Separated from api so it does not require
// the auth context to send the jwt
// or refresh on 401s

const post = (url, body) => {
    return fetch(config.host + url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    })
}

const postJson = (url, body) => {
    return post(url, body).then(response => {
        if (response.ok) {
            return response.json();
        }else{
            return false;
        }
    });
}

const postText = (url, body) => {
    return post(url, body).then(response => {
        if (response.ok) {
            return response.text();
        }else{
            return false;
        }
    });
}

const Logon = (username, password, targetApp) => {
    const url = "auth/logon";
    return postText(url, {username, password, targetApp});
}

const VerifySmsToken = (username, token) => {
    return postJson("auth/invite/verify", { username, token });
}

const RegisterToken = (username, token, password) => {
    return postText("auth/invite/password", { username, token, password });
}

const ResendUsername = (contactDetails) => {
    return post("auth/resend?contactDetails=" + contactDetails);
}

const SendPasswordToken = (username) => {
    return post("auth/reset?userName=" + username);
}

const VerifyPasswordToken = (token) => {
    return postJson("auth/reset/verify?token=" + token);
}

const SetNewPassword = (token, password) => {
    return postJson("auth/reset/password", {token, password});
}

export { Logon, VerifySmsToken, RegisterToken, ResendUsername, SendPasswordToken, VerifyPasswordToken, SetNewPassword };