const Debounce = (func) => {
    let timeout;
    const wait = 500; // 0.5 seconds

    return (...args) => {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
  
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
};

export default Debounce;