import { useRoutes } from "react-router-dom";
import { CaseContext } from './cases/Cases';
import { GetTheme } from "./settings/Data";
import { AuthContext, BuildContext } from "./auth/Auth";
import ROUTES from "./routes.js";
import { NotificationContext, ReadNotificationItem } from './notifications/Notifications';
import { Navigation } from './navigation/Navigation'
import { useEffect, useState } from "react";
import { GetCase } from "./cases/Data";
import Terms from "./settings/Terms";
import { ModalContext, UpdateModal } from "../shared/Modals";

const Practitioner = function (props) {
    const [caseState, setCaseState] = useState(undefined);
    const [refresh, setRefresh] = useState(0);
    const [terms, setTerms] = useState(true);

    const [text, setText] = useState("");
    const [show, setShow] = useState(false);
    const [navigate, setNavigate] = useState("");

    const router = useRoutes(ROUTES);

    const theme = localStorage.getItem("theme");
    const jwt = sessionStorage.getItem("jwt");
    let authProvider = undefined;

    useEffect(()=>{
      if(authProvider !== undefined && authProvider.user().accepted === false){
        setTerms(false);
    }
    },[authProvider]);

    useEffect(() => {
        const caseStored = localStorage.getItem("caseId");
        if (jwt !== null && caseStored !== null && caseStored !== undefined) {
            GetCase(caseStored).then(item => {
                setCase(item);
            });
        }
    }, [jwt]);

    authProvider = BuildContext(jwt);

    if (theme !== null) {
        document.documentElement.className = theme;
    } else {
        GetTheme().then(serverTheme => {
            localStorage.setItem("theme", serverTheme);
            document.documentElement.className = serverTheme;
        });
    }

    const notificationProvider = {
        readNotification: (itemArea, itemId) => {
            ReadNotificationItem(itemArea, itemId).then((r) => {
                if (r.length !== 0) setRefresh(refresh + 1);
            });
        },
        refresh:() => {setRefresh(refresh + 1)}
    }

    const setCase = (item) => {
        setCaseState(item);
        if (item !== undefined) {
            localStorage.setItem("caseId", item.caseId);
        } else {
            localStorage.removeItem("caseId");
        }
    }

    const setShowTextNavigate = (text, navigate) => {
        setText(text);
        setShow(true);
        if (navigate) setNavigate(navigate);
    }

    if(terms === false){
        return (
        <div className="app">
            <div className="app-container">
            <Terms setTerms={setTerms} />
            </div>
        </div>
        );
    }

    return (
        <div className="app">
            <AuthContext.Provider value={authProvider}>
                <CaseContext.Provider value={{
                    case: caseState,
                    setCase: (item) => setCase(item),
                    clearCase: () => setCase(undefined),
                }}>
                    <NotificationContext.Provider value={notificationProvider}>
                        <Navigation refresh={refresh} />
                        <ModalContext.Provider value={{
                            setShowTextNavigate: (text, navigate) => { setShowTextNavigate(text, navigate) }
                        }}>
                            <div className="app-container">
                                {router}
                                <UpdateModal show={show} setShow={setShow} text={text} navigate={navigate} />
                            </div>
                        </ModalContext.Provider>
                    </NotificationContext.Provider>
                </CaseContext.Provider>
            </AuthContext.Provider>
        </div>
    );
}

export default Practitioner;